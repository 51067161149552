import { User as Auth0User, IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

interface User {
  userName: string;
  userId: string;
  email: string;
  isLoading: boolean;
  isDriverAiUser: boolean | undefined;
  user?: Auth0User;
}

export const isDriverEmail = (email: string): boolean => {
  const driverAiDomains = ['@driverai.com', '@driver.ai'];
  return driverAiDomains.some((domain) => email.includes(domain));
};

export const useUser = (): User => {
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | null>(null);
  const isDriverAiUser = isDriverEmail(user?.email ?? '');

  useEffect(() => {
    const fetchIdTokenClaims = async () => {
      try {
        const claims = (await getIdTokenClaims()) || null;
        setIdTokenClaims(claims);
      } catch (error) {
        console.error('Error fetching ID token claims:', error);
      }
    };

    fetchIdTokenClaims();
  }, [getIdTokenClaims]);
  const userName: string = idTokenClaims?.name || 'Loading...';
  const email: string = idTokenClaims?.email || '';
  const userId: string = idTokenClaims?.sub || '';

  return { userName, userId, email, isLoading, isDriverAiUser, user };
};
