import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import NavbarCode from '@/components/CodeView/Navbar';
import SourceCode from '@/components/SourceCode';
import { useOrg } from '@/contexts/OrgContext';

const codeViewSchema = z.object({
  versionId: z.string().optional(),
});

export const Route = createFileRoute('/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$')({
  component: CodeViewPage,
  validateSearch: zodValidator(codeViewSchema),
});

function CodeViewPage() {
  const { organizationId } = useOrg();

  return (
    <div className="flex h-screen w-full flex-col">
      <NavbarCode organizationId={organizationId} />
      <div className="flex-grow overflow-auto dark:bg-neutral-950/90">
        <SourceCode />
      </div>
    </div>
  );
}
