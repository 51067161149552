import { useAuth0 } from '@auth0/auth0-react';
import { MoreVertical, Trash, UserCog } from 'lucide-react';
import React, { useState } from 'react';

import { RoleName, User } from '@/api/types/organization';
import { DeleteDialog } from '@/components/DeleteDialog';
import { UserAvatar } from '@/components/Settings/UserAvatar';
import { Button } from '@/components/ui/button';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { ChangeRoleDialog } from './ChangeRoleDialog';

interface UserListItemProps {
  member: User;
  onChangeRole: (id: string, role: RoleName) => void;
  onRemoveUser: (id: string) => void;
  isUpdatingUserRole: boolean;
}

const UserListItem: React.FC<UserListItemProps> = ({ member, onChangeRole, onRemoveUser, isUpdatingUserRole }) => {
  const userRole = member?.roles?.[0]?.name;
  const { user } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <li className="flex items-center justify-between rounded-lg bg-background p-1">
      <div className="flex items-center space-x-4">
        <UserAvatar user={member} />
        <div>
          <p className="font-medium">{member.name}</p>
          <p className="text-sm text-muted-foreground">{member.email}</p>
        </div>
      </div>

      <div className="text-md flex items-center space-x-2 text-muted-foreground">
        <div className="text-md mr-4 flex text-muted-foreground">{userRole}</div>
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost">
              <MoreVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
              <DialogTrigger asChild>
                <DropdownMenuItem onSelect={(e: any) => e.preventDefault()} disabled={user?.sub === member.user_id}>
                  <UserCog className="mr-2 h-4 w-4" />
                  Change role
                </DropdownMenuItem>
              </DialogTrigger>
              <ChangeRoleDialog
                member={member}
                userRole={userRole}
                isUpdatingUserRole={isUpdatingUserRole}
                onChangeRole={onChangeRole}
                onClose={() => {
                  setDialogOpen(false);
                  setDropdownOpen(false);
                }}
              />
            </Dialog>
            <DropdownMenuItem
              onClick={() => setDeleteDialogOpen(true)}
              disabled={user?.sub === member.user_id}
              className="text-destructive focus:text-destructive"
            >
              <Trash className="mr-2 h-4 w-4" />
              Remove user
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        itemType="user"
        itemName={member.name || member.email}
        contentTypeName="user"
        message="will be removed from the organization."
        onCancel={() => setDeleteDialogOpen(false)}
        onDelete={() => {
          onRemoveUser(member.user_id);
          setDeleteDialogOpen(false);
        }}
      />
    </li>
  );
};

export default UserListItem;
