import { type Cell, type Header, type HeaderGroup, type Row, Table, flexRender } from '@tanstack/react-table';
import * as React from 'react';

import { TableBody, TableCell, TableHead, TableHeader, TableRow, Table as UITable } from '@/components/ui/table';

import { Pagination } from './Pagination';
import SkeletonLoader from './SkeletonLoader';

export interface BaseTableProps<TData> {
  table: Table<TData>;
  isLoading: boolean;
  data: TData[];
  toolbarContent?: (table: Table<TData>, data: TData[]) => React.ReactNode;
  extraContent?: (data: TData[]) => React.ReactNode;
}

export function BaseTable<TData>({ table, isLoading, data, toolbarContent, extraContent }: BaseTableProps<TData>) {
  const renderedToolbar = toolbarContent?.(table, data);
  const renderedExtraContent = extraContent?.(data);
  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div className="space-y-4">
      {renderedToolbar}
      <div className="rounded-md border">
        <div className="overflow-x-auto">
          <UITable>
            <TableHeader>
              <TableRow>
                {table.getHeaderGroups().map((headerGroup: HeaderGroup<TData>) => (
                  <React.Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((header: Header<TData, unknown>) => (
                      <TableHead
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: `${header.column.getSize()}px`,
                          minWidth: `${header.column.getSize()}px`,
                        }}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    ))}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row: Row<TData>) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                    {row.getVisibleCells().map((cell: Cell<TData, unknown>) => (
                      <TableCell
                        key={cell.id}
                        style={{
                          width: `${cell.column.getSize()}px`,
                          minWidth: `${cell.column.getSize()}px`,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </UITable>
        </div>
      </div>
      <Pagination table={table} />
      {renderedExtraContent}
    </div>
  );
}
