/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LogoutImport } from './routes/logout'
import { Route as R404Import } from './routes/404'
import { Route as IndexImport } from './routes/index'
import { Route as OrganizationNameIndexImport } from './routes/$organizationName/index'
import { Route as OrganizationNameLibraryImport } from './routes/$organizationName/library'
import { Route as OrganizationNameDocumentsImport } from './routes/$organizationName/documents'
import { Route as OrganizationNameTemplateContentIdImport } from './routes/$organizationName/template/$contentId'
import { Route as OrganizationNameSettingsWorkspaceImport } from './routes/$organizationName/settings/workspace'
import { Route as OrganizationNameSettingsUsersImport } from './routes/$organizationName/settings/users'
import { Route as OrganizationNameSettingsUsageImport } from './routes/$organizationName/settings/usage'
import { Route as OrganizationNameSettingsIntegrationsImport } from './routes/$organizationName/settings/integrations'
import { Route as OrganizationNameSettingsAccountImport } from './routes/$organizationName/settings/account'
import { Route as OrganizationNamePdfContentIdImport } from './routes/$organizationName/pdf/$contentId'
import { Route as OrganizationNamePagePageIdImport } from './routes/$organizationName/page/$pageId'
import { Route as OrganizationNameNewPdfImport } from './routes/$organizationName/new/pdf'
import { Route as OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatImport } from './routes/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
import { Route as OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatImport } from './routes/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'

// Create/Update Routes

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const R404Route = R404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationNameIndexRoute = OrganizationNameIndexImport.update({
  id: '/$organizationName/',
  path: '/$organizationName/',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationNameLibraryRoute = OrganizationNameLibraryImport.update({
  id: '/$organizationName/library',
  path: '/$organizationName/library',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationNameDocumentsRoute = OrganizationNameDocumentsImport.update({
  id: '/$organizationName/documents',
  path: '/$organizationName/documents',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationNameTemplateContentIdRoute =
  OrganizationNameTemplateContentIdImport.update({
    id: '/$organizationName/template/$contentId',
    path: '/$organizationName/template/$contentId',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameSettingsWorkspaceRoute =
  OrganizationNameSettingsWorkspaceImport.update({
    id: '/$organizationName/settings/workspace',
    path: '/$organizationName/settings/workspace',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameSettingsUsersRoute =
  OrganizationNameSettingsUsersImport.update({
    id: '/$organizationName/settings/users',
    path: '/$organizationName/settings/users',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameSettingsUsageRoute =
  OrganizationNameSettingsUsageImport.update({
    id: '/$organizationName/settings/usage',
    path: '/$organizationName/settings/usage',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameSettingsIntegrationsRoute =
  OrganizationNameSettingsIntegrationsImport.update({
    id: '/$organizationName/settings/integrations',
    path: '/$organizationName/settings/integrations',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameSettingsAccountRoute =
  OrganizationNameSettingsAccountImport.update({
    id: '/$organizationName/settings/account',
    path: '/$organizationName/settings/account',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNamePdfContentIdRoute =
  OrganizationNamePdfContentIdImport.update({
    id: '/$organizationName/pdf/$contentId',
    path: '/$organizationName/pdf/$contentId',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNamePagePageIdRoute = OrganizationNamePagePageIdImport.update(
  {
    id: '/$organizationName/page/$pageId',
    path: '/$organizationName/page/$pageId',
    getParentRoute: () => rootRoute,
  } as any,
)

const OrganizationNameNewPdfRoute = OrganizationNameNewPdfImport.update({
  id: '/$organizationName/new/pdf',
  path: '/$organizationName/new/pdf',
  getParentRoute: () => rootRoute,
} as any)

const OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute =
  OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatImport.update({
    id: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$',
    path: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$',
    getParentRoute: () => rootRoute,
  } as any)

const OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute =
  OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatImport.update({
    id: '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$',
    path: '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/404': {
      id: '/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof R404Import
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/documents': {
      id: '/$organizationName/documents'
      path: '/$organizationName/documents'
      fullPath: '/$organizationName/documents'
      preLoaderRoute: typeof OrganizationNameDocumentsImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/library': {
      id: '/$organizationName/library'
      path: '/$organizationName/library'
      fullPath: '/$organizationName/library'
      preLoaderRoute: typeof OrganizationNameLibraryImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/': {
      id: '/$organizationName/'
      path: '/$organizationName'
      fullPath: '/$organizationName'
      preLoaderRoute: typeof OrganizationNameIndexImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/new/pdf': {
      id: '/$organizationName/new/pdf'
      path: '/$organizationName/new/pdf'
      fullPath: '/$organizationName/new/pdf'
      preLoaderRoute: typeof OrganizationNameNewPdfImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/page/$pageId': {
      id: '/$organizationName/page/$pageId'
      path: '/$organizationName/page/$pageId'
      fullPath: '/$organizationName/page/$pageId'
      preLoaderRoute: typeof OrganizationNamePagePageIdImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/pdf/$contentId': {
      id: '/$organizationName/pdf/$contentId'
      path: '/$organizationName/pdf/$contentId'
      fullPath: '/$organizationName/pdf/$contentId'
      preLoaderRoute: typeof OrganizationNamePdfContentIdImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/settings/account': {
      id: '/$organizationName/settings/account'
      path: '/$organizationName/settings/account'
      fullPath: '/$organizationName/settings/account'
      preLoaderRoute: typeof OrganizationNameSettingsAccountImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/settings/integrations': {
      id: '/$organizationName/settings/integrations'
      path: '/$organizationName/settings/integrations'
      fullPath: '/$organizationName/settings/integrations'
      preLoaderRoute: typeof OrganizationNameSettingsIntegrationsImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/settings/usage': {
      id: '/$organizationName/settings/usage'
      path: '/$organizationName/settings/usage'
      fullPath: '/$organizationName/settings/usage'
      preLoaderRoute: typeof OrganizationNameSettingsUsageImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/settings/users': {
      id: '/$organizationName/settings/users'
      path: '/$organizationName/settings/users'
      fullPath: '/$organizationName/settings/users'
      preLoaderRoute: typeof OrganizationNameSettingsUsersImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/settings/workspace': {
      id: '/$organizationName/settings/workspace'
      path: '/$organizationName/settings/workspace'
      fullPath: '/$organizationName/settings/workspace'
      preLoaderRoute: typeof OrganizationNameSettingsWorkspaceImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/template/$contentId': {
      id: '/$organizationName/template/$contentId'
      path: '/$organizationName/template/$contentId'
      fullPath: '/$organizationName/template/$contentId'
      preLoaderRoute: typeof OrganizationNameTemplateContentIdImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$': {
      id: '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
      path: '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
      fullPath: '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
      preLoaderRoute: typeof OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatImport
      parentRoute: typeof rootRoute
    }
    '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$': {
      id: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
      path: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
      fullPath: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
      preLoaderRoute: typeof OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/logout': typeof LogoutRoute
  '/$organizationName/documents': typeof OrganizationNameDocumentsRoute
  '/$organizationName/library': typeof OrganizationNameLibraryRoute
  '/$organizationName': typeof OrganizationNameIndexRoute
  '/$organizationName/new/pdf': typeof OrganizationNameNewPdfRoute
  '/$organizationName/page/$pageId': typeof OrganizationNamePagePageIdRoute
  '/$organizationName/pdf/$contentId': typeof OrganizationNamePdfContentIdRoute
  '/$organizationName/settings/account': typeof OrganizationNameSettingsAccountRoute
  '/$organizationName/settings/integrations': typeof OrganizationNameSettingsIntegrationsRoute
  '/$organizationName/settings/usage': typeof OrganizationNameSettingsUsageRoute
  '/$organizationName/settings/users': typeof OrganizationNameSettingsUsersRoute
  '/$organizationName/settings/workspace': typeof OrganizationNameSettingsWorkspaceRoute
  '/$organizationName/template/$contentId': typeof OrganizationNameTemplateContentIdRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/logout': typeof LogoutRoute
  '/$organizationName/documents': typeof OrganizationNameDocumentsRoute
  '/$organizationName/library': typeof OrganizationNameLibraryRoute
  '/$organizationName': typeof OrganizationNameIndexRoute
  '/$organizationName/new/pdf': typeof OrganizationNameNewPdfRoute
  '/$organizationName/page/$pageId': typeof OrganizationNamePagePageIdRoute
  '/$organizationName/pdf/$contentId': typeof OrganizationNamePdfContentIdRoute
  '/$organizationName/settings/account': typeof OrganizationNameSettingsAccountRoute
  '/$organizationName/settings/integrations': typeof OrganizationNameSettingsIntegrationsRoute
  '/$organizationName/settings/usage': typeof OrganizationNameSettingsUsageRoute
  '/$organizationName/settings/users': typeof OrganizationNameSettingsUsersRoute
  '/$organizationName/settings/workspace': typeof OrganizationNameSettingsWorkspaceRoute
  '/$organizationName/template/$contentId': typeof OrganizationNameTemplateContentIdRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/logout': typeof LogoutRoute
  '/$organizationName/documents': typeof OrganizationNameDocumentsRoute
  '/$organizationName/library': typeof OrganizationNameLibraryRoute
  '/$organizationName/': typeof OrganizationNameIndexRoute
  '/$organizationName/new/pdf': typeof OrganizationNameNewPdfRoute
  '/$organizationName/page/$pageId': typeof OrganizationNamePagePageIdRoute
  '/$organizationName/pdf/$contentId': typeof OrganizationNamePdfContentIdRoute
  '/$organizationName/settings/account': typeof OrganizationNameSettingsAccountRoute
  '/$organizationName/settings/integrations': typeof OrganizationNameSettingsIntegrationsRoute
  '/$organizationName/settings/usage': typeof OrganizationNameSettingsUsageRoute
  '/$organizationName/settings/users': typeof OrganizationNameSettingsUsersRoute
  '/$organizationName/settings/workspace': typeof OrganizationNameSettingsWorkspaceRoute
  '/$organizationName/template/$contentId': typeof OrganizationNameTemplateContentIdRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute
  '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$': typeof OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/404'
    | '/logout'
    | '/$organizationName/documents'
    | '/$organizationName/library'
    | '/$organizationName'
    | '/$organizationName/new/pdf'
    | '/$organizationName/page/$pageId'
    | '/$organizationName/pdf/$contentId'
    | '/$organizationName/settings/account'
    | '/$organizationName/settings/integrations'
    | '/$organizationName/settings/usage'
    | '/$organizationName/settings/users'
    | '/$organizationName/settings/workspace'
    | '/$organizationName/template/$contentId'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/404'
    | '/logout'
    | '/$organizationName/documents'
    | '/$organizationName/library'
    | '/$organizationName'
    | '/$organizationName/new/pdf'
    | '/$organizationName/page/$pageId'
    | '/$organizationName/pdf/$contentId'
    | '/$organizationName/settings/account'
    | '/$organizationName/settings/integrations'
    | '/$organizationName/settings/usage'
    | '/$organizationName/settings/users'
    | '/$organizationName/settings/workspace'
    | '/$organizationName/template/$contentId'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
  id:
    | '__root__'
    | '/'
    | '/404'
    | '/logout'
    | '/$organizationName/documents'
    | '/$organizationName/library'
    | '/$organizationName/'
    | '/$organizationName/new/pdf'
    | '/$organizationName/page/$pageId'
    | '/$organizationName/pdf/$contentId'
    | '/$organizationName/settings/account'
    | '/$organizationName/settings/integrations'
    | '/$organizationName/settings/usage'
    | '/$organizationName/settings/users'
    | '/$organizationName/settings/workspace'
    | '/$organizationName/template/$contentId'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$'
    | '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  R404Route: typeof R404Route
  LogoutRoute: typeof LogoutRoute
  OrganizationNameDocumentsRoute: typeof OrganizationNameDocumentsRoute
  OrganizationNameLibraryRoute: typeof OrganizationNameLibraryRoute
  OrganizationNameIndexRoute: typeof OrganizationNameIndexRoute
  OrganizationNameNewPdfRoute: typeof OrganizationNameNewPdfRoute
  OrganizationNamePagePageIdRoute: typeof OrganizationNamePagePageIdRoute
  OrganizationNamePdfContentIdRoute: typeof OrganizationNamePdfContentIdRoute
  OrganizationNameSettingsAccountRoute: typeof OrganizationNameSettingsAccountRoute
  OrganizationNameSettingsIntegrationsRoute: typeof OrganizationNameSettingsIntegrationsRoute
  OrganizationNameSettingsUsageRoute: typeof OrganizationNameSettingsUsageRoute
  OrganizationNameSettingsUsersRoute: typeof OrganizationNameSettingsUsersRoute
  OrganizationNameSettingsWorkspaceRoute: typeof OrganizationNameSettingsWorkspaceRoute
  OrganizationNameTemplateContentIdRoute: typeof OrganizationNameTemplateContentIdRoute
  OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute: typeof OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute
  OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute: typeof OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  R404Route: R404Route,
  LogoutRoute: LogoutRoute,
  OrganizationNameDocumentsRoute: OrganizationNameDocumentsRoute,
  OrganizationNameLibraryRoute: OrganizationNameLibraryRoute,
  OrganizationNameIndexRoute: OrganizationNameIndexRoute,
  OrganizationNameNewPdfRoute: OrganizationNameNewPdfRoute,
  OrganizationNamePagePageIdRoute: OrganizationNamePagePageIdRoute,
  OrganizationNamePdfContentIdRoute: OrganizationNamePdfContentIdRoute,
  OrganizationNameSettingsAccountRoute: OrganizationNameSettingsAccountRoute,
  OrganizationNameSettingsIntegrationsRoute:
    OrganizationNameSettingsIntegrationsRoute,
  OrganizationNameSettingsUsageRoute: OrganizationNameSettingsUsageRoute,
  OrganizationNameSettingsUsersRoute: OrganizationNameSettingsUsersRoute,
  OrganizationNameSettingsWorkspaceRoute:
    OrganizationNameSettingsWorkspaceRoute,
  OrganizationNameTemplateContentIdRoute:
    OrganizationNameTemplateContentIdRoute,
  OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute:
    OrganizationNameWsWorkspaceIdCbCodebaseIdCodeSplatRoute,
  OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute:
    OrganizationNameWsWorkspaceIdCbCodebaseIdTreeSplatRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/404",
        "/logout",
        "/$organizationName/documents",
        "/$organizationName/library",
        "/$organizationName/",
        "/$organizationName/new/pdf",
        "/$organizationName/page/$pageId",
        "/$organizationName/pdf/$contentId",
        "/$organizationName/settings/account",
        "/$organizationName/settings/integrations",
        "/$organizationName/settings/usage",
        "/$organizationName/settings/users",
        "/$organizationName/settings/workspace",
        "/$organizationName/template/$contentId",
        "/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$",
        "/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/404": {
      "filePath": "404.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/$organizationName/documents": {
      "filePath": "$organizationName/documents.tsx"
    },
    "/$organizationName/library": {
      "filePath": "$organizationName/library.tsx"
    },
    "/$organizationName/": {
      "filePath": "$organizationName/index.tsx"
    },
    "/$organizationName/new/pdf": {
      "filePath": "$organizationName/new/pdf.tsx"
    },
    "/$organizationName/page/$pageId": {
      "filePath": "$organizationName/page/$pageId.tsx"
    },
    "/$organizationName/pdf/$contentId": {
      "filePath": "$organizationName/pdf/$contentId.tsx"
    },
    "/$organizationName/settings/account": {
      "filePath": "$organizationName/settings/account.tsx"
    },
    "/$organizationName/settings/integrations": {
      "filePath": "$organizationName/settings/integrations.tsx"
    },
    "/$organizationName/settings/usage": {
      "filePath": "$organizationName/settings/usage.tsx"
    },
    "/$organizationName/settings/users": {
      "filePath": "$organizationName/settings/users.tsx"
    },
    "/$organizationName/settings/workspace": {
      "filePath": "$organizationName/settings/workspace.tsx"
    },
    "/$organizationName/template/$contentId": {
      "filePath": "$organizationName/template/$contentId.tsx"
    },
    "/$organizationName/ws/$workspaceId/cb/$codebaseId/code/$": {
      "filePath": "$organizationName/ws/$workspaceId/cb/$codebaseId/code/$.tsx"
    },
    "/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$": {
      "filePath": "$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
