import { Combobox, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from '@tanstack/react-router';
import { decode } from 'he';
import { useEffect } from 'react';

import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useOrgUrlParams } from '@/hooks/useOrgUrlParams';

import BlockEditorPreview from '../BlockEditor/BlockEditorPreview';
import SkeletonLoader from './SkeletonLoader';

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

const CONTAINER_HEIGHT = '31rem';

export default function Pages() {
  const { organizationName } = useOrgUrlParams();
  const navigate = useNavigate();

  const recentDocumentsParams = {
    text: '',
    limit: 8,
    offset: 0,
    kind: [PrimaryAsset.PAGE],
    sort_direction: 'DESC' as const,
    sort_by: 'updated_at',
    tag_ids: [],
  };

  const {
    data: recentDocumentsData,
    isLoading: isLoadingRecentDocuments,
    isFetching: _isFetchingRecentDocuments,
    isError: isErrorRecentDocuments,
    refetch: refetchContent,
  } = useGetPrimaryAssetQuery(recentDocumentsParams);

  useEffect(() => {
    refetchContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingRecentDocuments) return <SkeletonLoader />;
  if (isErrorRecentDocuments) {
    return (
      <div>
        <p className="text-red-500">An error occurred while loading the data. Please try again later.</p>
        <SkeletonLoader />
      </div>
    );
  }

  const contents = recentDocumentsData?.results ?? [];

  return (
    <div className="w-full">
      <div className="w-full transform divide-y divide-border overflow-hidden rounded-lg ring-1 ring-border transition-all">
        <Combobox<PrimaryAssetRecord>
          onChange={(content: PrimaryAssetRecord) => {
            if (content) {
              navigate({
                to: `/$organizationName/page/$pageId`,
                params: {
                  organizationName: organizationName,
                  pageId: content.most_recent_version?.root_node?.id ?? '',
                },
              });
            }
          }}
        >
          {({ activeOption }: { activeOption: PrimaryAssetRecord | null }) => (
            <>
              <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-border">
                <div
                  className={classNames(
                    `max-h-[${CONTAINER_HEIGHT}] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4`,
                    !!activeOption && `sm:h-[${CONTAINER_HEIGHT}]`
                  )}
                >
                  <div className="-mx-2 text-sm text-foreground">
                    {contents.map((content) => (
                      <ComboboxOption
                        as="div"
                        key={content.id}
                        value={content}
                        className={({ focus }: { focus: boolean }) =>
                          classNames(
                            'flex cursor-default select-none items-center rounded-md px-2 py-5',
                            focus && 'bg-accent-emphasis text-foreground'
                          )
                        }
                      >
                        {({ focus }: { focus: boolean }) => (
                          <>
                            <span className="ml-3 flex-auto truncate">{decode(content.display_name)}</span>
                            {focus && (
                              <ChevronRightIcon
                                className="ml-3 h-5 w-5 flex-none text-muted-foreground"
                                aria-hidden="true"
                              />
                            )}
                          </>
                        )}
                      </ComboboxOption>
                    ))}
                  </div>
                </div>

                {activeOption && (
                  <div className="hidden h-[31.3rem] w-3/5 flex-none flex-col gap-2 p-4 md:flex">
                    <div className="relative flex h-full flex-auto flex-col overflow-y-auto p-4 pt-0 text-sm text-foreground">
                      <div className="relative">
                        <BlockEditorPreview
                          key={activeOption.id}
                          nodeId={activeOption.most_recent_version?.root_node?.id}
                        />
                      </div>
                    </div>
                    <div className="flex-none rounded-lg border border-border bg-muted/80 p-6">
                      <h2 className="truncate text-lg font-medium text-foreground">
                        {decode(activeOption.display_name)}
                      </h2>
                      <p className="mt-1 text-xs text-muted-foreground">
                        Last updated: {new Date(activeOption.updated_at).toLocaleDateString()}
                      </p>
                      <div className="mt-2.5 flex flex-wrap gap-2">
                        {activeOption.tags && activeOption.tags.length > 0 ? (
                          activeOption.tags.map((tag) => (
                            <Badge key={tag.name} variant="outline" className="px-1.5">
                              <span
                                className="mr-2 inline-block h-2 w-2 rounded-full"
                                style={{ backgroundColor: tag.hex_color }}
                              />
                              {tag.name}
                            </Badge>
                          ))
                        ) : (
                          <span className="text-sm text-muted-foreground"></span>
                        )}
                      </div>
                      <Button
                        className="mt-4 w-full"
                        to="/$organizationName/page/$pageId"
                        params={{
                          organizationName: organizationName,
                          pageId: activeOption.most_recent_version?.root_node?.id,
                        }}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                )}
              </ComboboxOptions>

              {contents.length === 0 && (
                <div className="p-6 text-center text-foreground">
                  <p>No content found.</p>
                </div>
              )}
            </>
          )}
        </Combobox>
      </div>
    </div>
  );
}
