import { Resizable } from 're-resizable';
import React, { ReactNode } from 'react';

interface RightPanelProps {
  className?: string;
  content?: ReactNode;
  header?: ReactNode;
  showOnlyHeaders: boolean;
  layout: {
    rightPanelWidth: number;
    isRightPanelOpen: boolean;
  };
  updateLayout: (update: Partial<{ rightPanelWidth: number }>) => void;
  rightPanelContentClassName?: string;
  showRightPanelHeaderDivider?: boolean;
  headerClassName?: string;
}

export const RightPanel: React.FC<RightPanelProps> = ({
  className,
  content,
  header,
  showOnlyHeaders,
  layout,
  updateLayout,
  rightPanelContentClassName,
  showRightPanelHeaderDivider,
  headerClassName,
}) => {
  if (!content && !header) return null;

  const panelContent = (
    <div
      className={`${
        showOnlyHeaders || !content ? 'h-fit' : 'h-full'
      } flex flex-col overflow-hidden bg-white dark:bg-neutral-950`}
    >
      {header && content && (
        <>
          <div className={`h-[56px] flex-shrink-0 border-l border-border px-3 ${headerClassName || ''}`}>{header}</div>
          {showRightPanelHeaderDivider && <hr className="border-border" />}
        </>
      )}
      {!showOnlyHeaders && content && (
        <div className={`flex-grow overflow-auto border-l border-border ${rightPanelContentClassName || ''}`}>
          {content}
        </div>
      )}
    </div>
  );

  if (showOnlyHeaders || !content) {
    return (
      <div
        className={`absolute right-0 top-0 z-50 flex h-full max-w-[300px] flex-col overflow-hidden truncate text-ellipsis${
          layout.isRightPanelOpen ? '' : ''
        }`}
      >
        {panelContent}
      </div>
    );
  }

  return (
    <Resizable
      size={{
        width: layout.isRightPanelOpen ? `${layout.rightPanelWidth}px` : 0,
        height: `100%`,
      }}
      minWidth={240}
      maxWidth={800}
      enable={{ left: true }}
      onResize={(e, direction, ref) => {
        const newWidth = parseFloat(ref.style.width);
        updateLayout({ rightPanelWidth: newWidth });
        // Force Safari to repaint
        ref.style.transform = 'scale(1)';
        setTimeout(() => {
          ref.style.transform = '';
        }, 0);
      }}
      className={`overflow-hidden bg-white dark:bg-neutral-950 ${layout.isRightPanelOpen ? 'block' : 'hidden'} ${className}`}
    >
      {panelContent}
    </Resizable>
  );
};
