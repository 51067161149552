import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import { CaptionLayout, DayPicker, useNavigation } from 'react-day-picker';

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  captionLayout?: CaptionLayout;
  fromYear?: number;
  toYear?: number;
  fromMonth?: Date;
  toMonth?: Date;
  defaultMonth?: Date;
};

function CustomNavigation() {
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation();

  return (
    <div className="flex items-center justify-between px-1">
      <button
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className={cn(
          buttonVariants({ variant: 'outline' }),
          'mr-2 h-4 w-4 bg-transparent p-0 opacity-100 hover:bg-accent hover:text-accent-foreground'
        )}
      >
        <ChevronLeftIcon className="h-4 w-4" />
      </button>
      <div className="flex justify-center gap-1">
        <select
          className="dropdown dropdown_month w-[90px] border-none bg-transparent py-0.5 text-[0.8rem] focus:outline-none focus:ring-1 focus:ring-accent"
          value={`${currentMonth.getFullYear()}-${currentMonth.getMonth()}`}
          onChange={(e) => {
            const [year, month] = e.target.value.split('-');
            goToMonth(new Date(parseInt(year), parseInt(month)));
          }}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={`${currentMonth.getFullYear()}-${i}`}>
              {new Date(2000, i).toLocaleString('default', { month: 'short' })}
            </option>
          ))}
        </select>
        <select
          className="dropdown dropdown_year w-[90px] border-none bg-transparent py-0.5 text-[0.8rem] focus:outline-none focus:ring-1 focus:ring-accent"
          value={currentMonth.getFullYear()}
          onChange={(e) => {
            const year = parseInt(e.target.value);
            goToMonth(new Date(year, currentMonth.getMonth()));
          }}
        >
          {Array.from({ length: 20 }, (_, i) => {
            const year = currentMonth.getFullYear() - 10 + i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
      <button
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className={cn(
          buttonVariants({ variant: 'outline' }),
          'ml-2 h-4 w-4 bg-transparent p-0 opacity-100 hover:bg-accent hover:text-accent-foreground'
        )}
      >
        <ChevronRightIcon className="h-4 w-4" />
      </button>
    </div>
  );
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  captionLayout = 'buttons',
  fromYear = 2000,
  toYear = new Date().getFullYear() + 10,
  fromMonth = new Date(2000, 0),
  toMonth = new Date(toYear, 11),
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      {...props}
      showOutsideDays={showOutsideDays}
      fromYear={fromYear}
      fromMonth={fromMonth}
      toDate={new Date()}
      captionLayout="buttons"
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'relative flex items-center justify-center pt-1',
        caption_label: 'text-sm font-medium',
        caption_dropdowns: 'flex justify-center gap-1',
        dropdown: 'focus:outline-none focus:ring-0 border-none bg-transparent py-0 font-small appearance-none',
        dropdown_month: 'border-none bg-transparent appearance-none',
        dropdown_year: 'border-none bg-transparent appearance-none',
        vhidden: 'hidden',
        nav: 'space-x-1 flex absolute left-1 right-1 justify-between',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-100 hover:bg-accent hover:text-accent-foreground'
        ),
        nav_button_previous: '',
        nav_button_next: '',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex w-full',
        head_cell: 'text-muted-foreground rounded-md w-full font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn(
          'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 w-full [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-8 w-full p-0 font-normal aria-selected:opacity-100 flex items-center justify-center'
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside:
          'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        Caption: () => <CustomNavigation />,
      }}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
