import { Table, VisibilityState } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { DEFAULT_PDF_COLUMN_VISIBILITY, TableType } from '@/components/Filters/filter-constants';

import { BaseTable } from '../BaseTable/BaseTable';
import { pdfColumns } from './columns';
import { useSourcesTable } from './useSourcesTable';

interface PDFTableProps {
  toolbarContent?: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => ReactNode;
  defaultColumnVisibility?: VisibilityState;
}

export function PDFTable({ toolbarContent, defaultColumnVisibility = DEFAULT_PDF_COLUMN_VISIBILITY }: PDFTableProps) {
  const {
    table,
    isLoading: internalLoading,
    data,
  } = useSourcesTable({
    columns: pdfColumns,
    defaultFilters: [
      {
        id: 'kind',
        value: [PrimaryAsset.FILE],
      },
    ],
    tableType: TableType.PDF,
    defaultColumnVisibility,
  });

  return <BaseTable table={table} isLoading={internalLoading} data={data} toolbarContent={toolbarContent} />;
}
