import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Outlet, createRootRoute } from '@tanstack/react-router';
import { UserbackProvider, useUserback } from '@userback/react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { FC } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import { apiStore } from '@/api/api';
import security from '@/api/security';
import Auth0Apollo from '@/components/Auth0Apollo';
import Racers from '@/components/Racers/Racers';
import { Toaster } from '@/components/ui/toaster';
import { OrgProvider, useOrg } from '@/contexts/OrgContext';
import { useErrorLogging, useLogUser } from '@/hooks/useErrorLogging';
import { PostHogProvider } from '@/providers/posthog-provider';
import { ThemeProvider } from '@/providers/theme-provider';
import '@/styles/driver-check-tree.css';
import '@/styles/globals.css';

import { NotFound404 } from './404';

if (import.meta.env.VITE_GOOGLE_ANALYTICS) {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_GOOGLE_ANALYTICS,
  });
}

export const Route = createRootRoute({
  component: App,
  notFoundComponent: () => <NotFound404 />,
});

const userbackToken = import.meta.env.VITE_USERBACK_TOKEN as string;
const apiAudience = import.meta.env.VITE_API_AUDIENCE;
const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

const UserBackIdentifier: FC = () => {
  const { user } = useAuth0();
  const Userback = useUserback();
  const { organizationName } = useOrg();
  if (user && organizationName) {
    Userback.identify(user?.sub ?? 'no-sub', {
      name: user.name,
      email: user.email,
      // @TODO: get the plan from the user
      plan: 'enterprise',
      account_id: organizationName,
    });
  }
  return <></>;
};

// Make the various auth0 functions available via the security singleton where needed
const ApiSecurityProvider: FC = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, user, logout } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLoginWithRedirect(loginWithRedirect);
  security.setUser(user);
  security.setLogout(logout);
  // if the user is authenticated but doesn't have the ai.driver.roles role, redirect to the login page
  // so they can fetch a new token with the role
  const roles = user?.['ai.driver.roles'] || [];
  const missingRoles = roles.length === 0;
  if (!isLoading && isAuthenticated && missingRoles) {
    loginWithRedirect();
  }
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }
  return <></>;
};

const ErrorLoggingUserProvider: FC = () => {
  useLogUser();
  return <></>;
};

function App() {
  useErrorLogging();

  return (
    <div>
      <Racers />
      <div className="font-sans">
        <Auth0Provider
          domain={auth0Domain!}
          clientId={clientId!}
          cacheLocation="localstorage"
          authorizationParams={{
            audience: apiAudience,
            scope: 'openid profile email offline_access read:current_user',
            redirect_uri: import.meta.env.VITE_AUTH0_BASE_URL,
          }}
        >
          <ApiSecurityProvider />
          <Provider store={apiStore}>
            <Toaster />
            <UserbackProvider token={userbackToken}>
              <OrgProvider>
                <PostHogProvider>
                  <ErrorLoggingUserProvider />
                  <UserBackIdentifier />
                  <Auth0Apollo>
                    <ThemeProvider attribute="class" defaultTheme="light">
                      <Outlet />
                    </ThemeProvider>
                  </Auth0Apollo>
                </PostHogProvider>
              </OrgProvider>
            </UserbackProvider>
            <Analytics />
            <SpeedInsights />
          </Provider>
        </Auth0Provider>
      </div>
    </div>
  );
}
