import { useQuery } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { useGetCodebaseVersionsQuery } from '@/api/api';
import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import RightHeader from '@/components/CodebasePanels/RightHeader';
import BreadcrumbsCodebase from '@/components/CodebaseView/BreadcrumbsCodebase';
import { CodeContent } from '@/components/CodebaseView/CodeContent/CodeContent';
import { DocumentSet } from '@/components/CodebaseView/DocumentSet/DocumentSet';
import { SpecialPathContent } from '@/components/CodebaseView/DocumentSet/SpecialPathContent';
import { VersionBanner } from '@/components/CodebaseView/DocumentSet/VersionBanner';
import SidebarCodebase from '@/components/CodebaseView/SidebarCodebase';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import FloatingTableOfContents from '@/components/TableOfContents/FloatingTableOfContents';
import { FlatNode } from '@/components/UserDefinedScope/Tree/TreeUtils';
import { GET_TREE } from '@/graphql/queries';
import { useSyncSearchParams } from '@/hooks/useSyncSearchParams';
import { useUrlParams } from '@/hooks/useUrlParams';

const treeSearchSchema = z.object({
  versionId: z.string().optional(),
});

export const Route = createFileRoute('/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$')({
  component: CodebasePage,
  validateSearch: zodValidator(treeSearchSchema),
});

const toTitleCase = (str: string) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

function CodebasePage() {
  const { organizationName, codebaseId, _splat: path } = Route.useParams();

  const { pathArray } = useUrlParams();
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const params = useSyncSearchParams();
  const versionId = params?.get('versionId');
  const { data: versionsData } = useGetCodebaseVersionsQuery(
    {
      codebaseId: codebaseId as string, // this is actually a primary asset id
    },
    { skip: !codebaseId }
  );
  const hasVersions = (versionsData?.versions?.length ?? 0) > 0;

  const { data } = useQuery(GET_TREE, {
    variables: {
      codebaseId,
      versionId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !codebaseId,
  });
  const relativePath = Array.isArray(path) ? path.join('/') : path;
  const metaData = data?.tree.find((node: FlatNode) => node.path === relativePath);
  const lastPathSegment = pathArray.length > 0 ? pathArray[pathArray.length - 1] : '';

  const isFile = metaData?.kind === 'file';

  const isSpecialPath =
    Array.isArray(path) && (path[path.length - 1] === 'executive-summary' || path[path.length - 1] === 'entry-point');

  let displayPath: string | string[] | undefined = path;
  if (isSpecialPath && typeof path[path.length - 1] === 'string') {
    displayPath = [...path];
    displayPath[displayPath.length - 1] = toTitleCase(path[path.length - 1]);
  }

  const pageTitle = `${pathArray[pathArray.length - 1]}`;
  const tocPositionClass = '-ms-11 lg:pe-2.5';

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <div className="flex flex-col">
        <VersionBanner isLargeScreen={isLargeScreen} />
        <DefaultLayout noContentPadding navbarTitle={pathArray[0]}>
          <div className="lg:hidden">
            <SidebarCodebase organizationName={organizationName as string} codebaseId={codebaseId as string} />
          </div>
          <FlexiblePanelLayout
            pageType="codebase"
            headerClassList={hasVersions ? ['h-[88px]'] : []}
            leftPanelHeader={<LeftHeader name={pathArray[0]} />}
            leftPanelContent={
              <div>
                <SidebarCodebase organizationName={organizationName as string} codebaseId={codebaseId as string} />
              </div>
            }
            leftPanelContentClassName="px-0"
            leftPanelWidth={0}
            hideLeftPanel={!isLargeScreen}
            mainPanelContent={
              <div className="mx-auto flex w-full max-w-3xl flex-col gap-y-6 px-8 pb-4 pt-4">
                <BreadcrumbsCodebase path={Array.isArray(path) ? path : []} />
                <div className="">{isSpecialPath ? <SpecialPathContent /> : <DocumentSet />}</div>
                <FloatingTableOfContents tocPositionClass={tocPositionClass} />
              </div>
            }
            rightPanelHeader={<RightHeader name={lastPathSegment} />}
            rightPanelContent={isFile && <CodeContent />}
            rightPanelContentClassName="py-0 pt-0 pe-0"
            showRightPanelHeaderDivider={false}
            rightPanelWidth={450}
            rightPanelHeaderClassName="h-[48px]"
          />
        </DefaultLayout>
      </div>
    </>
  );
}
