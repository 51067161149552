import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { useEffect } from 'react';
import { z } from 'zod';

import { DocumentsTable } from '@/components/DocumentsTable/DocumentsTable';
import { DocumentType } from '@/components/DocumentsTable/constants';
import DefaultLayout from '@/components/LayoutDefault';
import { RecentlyModified } from '@/components/RecentlyModified/RecentlyModified';
import { useOrg } from '@/contexts/OrgContext';

const DEFAULT_PAGE_SIZE = 30;

const documentsSearchSchema = z.object({
  pageSize: fallback(z.number(), DEFAULT_PAGE_SIZE).default(DEFAULT_PAGE_SIZE),
  pageIndex: fallback(z.number(), 0).default(0),
  type: fallback(z.enum([DocumentType.PAGE, DocumentType.PAGE_TEMPLATE]), DocumentType.PAGE).default(DocumentType.PAGE),
  search: fallback(z.string(), '').default(''),
  selectedColumns: fallback(z.array(z.string()), []).default([]),
  columnFilters: fallback(
    z.array(
      z.object({
        id: z.string(),
        value: z.unknown().refine((x) => x !== undefined, 'Required'),
      })
    ),
    []
  ).default([]),
  sorting: fallback(
    z.array(
      z.object({
        id: z.string(),
        desc: z.boolean(),
      })
    ),
    [{ id: 'updated_at', desc: true }]
  ).default([{ id: 'updated_at', desc: true }]),
});

export const DocumentsPage = () => {
  const { organizationDisplayName } = useOrg();
  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Documents`;
  }, [organizationDisplayName]);

  return (
    <>
      <DefaultLayout navbarTitle="Library">
        <div>
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium">My Documents</div>
          </div>
          <div className="mb-8 mt-8">
            <RecentlyModified />
          </div>
          <DocumentsTable />
        </div>
      </DefaultLayout>
    </>
  );
};

export const Route = createFileRoute('/$organizationName/documents')({
  component: DocumentsPage,
  validateSearch: zodValidator(documentsSearchSchema),
});
