import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/')({
  component: Home,
});

function Home() {
  const navigate = useNavigate();
  const { organizationName } = useOrg();
  const { isLoading, error } = useAuth0();

  useEffect(() => {
    if (organizationName) {
      navigate({ to: `/$organizationName`, params: { organizationName } });
    }
  }, [organizationName, navigate]);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    console.error(error);
    return <p className="text-red-500">An error has occurred, please try again later.</p>;
  }

  return null;
}
