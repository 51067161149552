import { Link, useSearch } from '@tanstack/react-router';
import { decode } from 'he';

import { PrimaryAsset, PrimaryAssetRecord, VersionStatus } from '@/api/types/node';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useOrg } from '@/contexts/OrgContext';
import { cn } from '@/lib/utils';

interface CellContentAssetProps {
  row: {
    original: PrimaryAssetRecord;
  };
}
export const CellContentName: React.FC<CellContentAssetProps> = ({ row }) => {
  const query = useSearch({
    strict: false,
  });
  const { organizationName } = useOrg();
  const rowData = row.original;
  const contentName = rowData.display_name;
  const type = rowData.kind;
  const id = rowData.id;
  const nodeId = rowData.most_recent_version?.root_node?.id;

  // FUTURE: update useGetAssetLink hook to be reusable for this case.
  let to = '';
  let search: any;
  if (type === PrimaryAsset.FILE) {
    to = `/${organizationName}/pdf/${nodeId}`;
  } else if (type === PrimaryAsset.CODEBASE) {
    if (rowData.browsable) {
      to = `/${organizationName}/ws/${id}/cb/${id}/tree/${contentName}`;
    } else {
      if (rowData.most_recent_version?.status === VersionStatus.CONNECTED) {
        to = '.';
        search = { ...query, generateRowIds: [id] };
      }
    }
  } else if (type === PrimaryAsset.PAGE) {
    to = `/${organizationName}/page/${nodeId}`;
  }

  const ContentWrapper = () => (
    <div className="flex h-full items-center">
      <p className="truncate">{decode(contentName)}</p>
    </div>
  );

  const wrapperClass = 'block h-9 max-w-[150px] sm:max-w-[150px] md:max-w-[150px] lg:max-w-[200px]';

  const content = to ? (
    <Link to={to} search={search} className={wrapperClass}>
      <ContentWrapper />
    </Link>
  ) : (
    <div className={cn(wrapperClass, 'cursor-not-allowed')}>
      <ContentWrapper />
    </div>
  );

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{content}</TooltipTrigger>
        <TooltipContent>
          <p>{decode(contentName)}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
