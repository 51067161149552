import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Column, Table } from '@tanstack/react-table';
import { useState } from 'react';

import { PrimaryAssetRecord, VersionStatus, VersionStatusDisplayName, VersionStatusType } from '@/api/types/node';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';

import { filterExcludedStatuses } from '../BaseTable/utils/table-utils';

interface StatusFilterProps {
  column?: Column<PrimaryAssetRecord, unknown>;
  table: Table<PrimaryAssetRecord>;
  title?: string;
}

export function StatusFilter({ column, title }: StatusFilterProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  if (!column) return null;

  const filterValue = column.getFilterValue() as VersionStatusType[] | undefined;

  const handleStatusSelect = (status: VersionStatusType) => {
    const currentValue = filterValue || [];
    const newValue = currentValue.includes(status)
      ? currentValue.filter((s) => s !== status)
      : [...currentValue, status];
    column.setFilterValue(newValue.length > 0 ? newValue : undefined);
    setSearch('');
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setSearch('');
    }
  };

  const filteredStatuses = filterExcludedStatuses(
    Object.values(VersionStatus).filter((status) =>
      VersionStatusDisplayName[status].toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className={cn('h-8 gap-2 px-3', open && 'bg-accent text-accent-foreground')}>
          {title}
          {filterValue && filterValue.length > 0 && (
            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
              {filterValue.length}
            </span>
          )}
          {open ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput
            className="border-none ps-0 focus:ring-0"
            placeholder="Search statuses..."
            value={search}
            onValueChange={setSearch}
            closeable={search.length > 0}
          />
          <CommandList>
            <CommandEmpty>No statuses found.</CommandEmpty>
            <CommandGroup>
              {filteredStatuses.map((status) => (
                <CommandItem key={status} onSelect={() => handleStatusSelect(status)} className="w-full">
                  <div
                    className={cn(
                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                      filterValue?.includes(status)
                        ? 'bg-primary text-primary-foreground'
                        : 'opacity-50 [&_svg]:invisible'
                    )}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </div>
                  <span className="flex-1 truncate text-left">{VersionStatusDisplayName[status]}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          {filterValue && filterValue.length > 0 && (
            <CommandItem
              onSelect={() => {
                column.setFilterValue(undefined);
                setOpen(false);
                setSearch('');
              }}
              className="justify-center border-t text-center"
            >
              Clear filter
            </CommandItem>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
