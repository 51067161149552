import Blockquote from '@tiptap/extension-blockquote';
import CharacterCount from '@tiptap/extension-character-count';
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import ListKeymap from '@tiptap/extension-list-keymap';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { lowlight } from 'lowlight';
import React, { useEffect } from 'react';

import { useGetContentQuery } from '@/api/api';
import { Table, TableCell, TableHeader, TableRow } from '@/components/BlockEditor/extensions/Table';
import { transformLibraryContent } from '@/utils/formatEditorContentUtil';

import createSmartInstructionPreviewNode from './extensions/Instructions/InstructionPromptPreviewNode';

interface ContentEditorProps {
  nodeId?: string | undefined;
  content?: string;
}

export const BlockEditorPreview = ({ nodeId, content }: ContentEditorProps) => {
  const { data, isLoading: _isLoading } = useGetContentQuery({ node_id: nodeId }, { skip: !!content || !nodeId });
  const contentData = content || data?.results[0];

  const editor = useEditor({
    editable: false,
    extensions: [
      // support the old smart instruction node
      createSmartInstructionPreviewNode('instructionPrompt'),
      // support the new smart instruction node nam
      createSmartInstructionPreviewNode('smartInstruction'),
      StarterKit.configure({
        codeBlock: false,
        blockquote: false,
      }),
      Underline.configure({
        HTMLAttributes: {
          class: 'editor-underline',
        },
      }),
      Link.extend({
        inclusive: false,
      }).configure({
        HTMLAttributes: {
          class: 'editor-link',
        },
      }),
      Typography.configure({
        copyright: false,
        registeredTrademark: false,
        trademark: false,
        servicemark: false,
        oneHalf: false,
        oneQuarter: false,
        threeQuarters: false,
        multiplication: false,
      }),
      Blockquote,
      CodeBlockLowlight.configure({ lowlight }),
      TaskList,
      TaskItem,
      ListKeymap,
      CharacterCount,
      Table,
      TableRow,
      TableHeader,
      TableCell,
      Image,
    ],
    editorProps: {
      attributes: {
        class:
          'prose prose-base prose-zinc max-w-none focus:outline-none prose-inline-code:bg-neutral-600/5 prose-inline-code:py-0.5 prose-inline-code:px-1 prose-inline-code:mx-0.5 prose-inline-code:rounded prose-inline-code:text-neutral-700 prose-inline-code:border prose-inline-code:border-neutral-500/10 prose-inline-code:text-smaller prose-inline-code:font-normal prose-inline-code:before:hidden prose-inline-code:after:hidden prose-ul:mb-8 prose-ol:mb-8 ',
      },
    },
  });

  useEffect(() => {
    if (!contentData) return;

    // Add a small delay to ensure React is not in the middle of rendering
    const timer = setTimeout(() => {
      if (typeof contentData === 'string') {
        const recordContent = transformLibraryContent({
          content: contentData,
          content_name: '',
          node: null,
        });
        editor?.commands.setContent(recordContent.content);
      } else {
        const recordContent = transformLibraryContent(contentData);
        editor?.commands.setContent(recordContent.content);
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [contentData]);

  return (
    <div id="parent preview">
      <EditorContent editor={editor} />
    </div>
  );
};

export default BlockEditorPreview;
