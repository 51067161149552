import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Column, Row, Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { UserAvatar } from '@/components/Settings/UserAvatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { getGravatarUrl } from '@/utils/avatar';

interface Creator {
  full_name: string;
  email: string;
}

interface CreatorFilterProps {
  column?: Column<PrimaryAssetRecord, unknown>;
  table: Table<PrimaryAssetRecord>;
  title?: string;
}

export function CreatorFilter({ column, table, title }: CreatorFilterProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [creators, setCreators] = useState<Creator[]>([]);
  const selectedCreatorNames = (column?.getFilterValue() as string[]) || [];
  const selectedValues = new Map<string, Creator>(
    creators
      .filter((creator) => selectedCreatorNames.includes(creator.full_name))
      .map((creator) => [creator.full_name, creator])
  );

  useEffect(() => {
    // Get unique creators from the table data
    const tableCreators = table
      .getCoreRowModel()
      .rows.map((row: Row<PrimaryAssetRecord>) => {
        const creator = row.original.most_recent_version?.creator;
        return creator ? { full_name: creator.full_name, email: creator.email } : null;
      })
      .filter((creator): creator is Creator => !!creator);

    // Remove duplicates and sort by name
    const uniqueCreators = Array.from(new Map(tableCreators.map((creator) => [creator.email, creator])).values()).sort(
      (a, b) => a.full_name.localeCompare(b.full_name)
    );

    setCreators(uniqueCreators);
  }, [table]);

  if (!column) return null;

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setSearch('');
    }
  };

  const filteredCreators = creators.filter((creator) => {
    const matchesSearch = creator.full_name.toLowerCase().includes(search.toLowerCase());
    return !search || matchesSearch;
  });

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className={cn('h-8 gap-2 px-3', open && 'bg-accent text-accent-foreground')}>
          {title}
          {selectedValues.size > 0 && (
            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
              {selectedValues.size}
            </span>
          )}
          {open ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-max min-w-[300px] max-w-[400px] p-0" align="start">
        <Command>
          <CommandInput
            className="border-none ps-0 focus:ring-0"
            placeholder="Search creators..."
            value={search}
            onValueChange={setSearch}
            closeable={search.length > 0}
          />
          <CommandList>
            <CommandEmpty>No creators found.</CommandEmpty>
            <CommandGroup>
              {Array.from(selectedValues.values()).map((creator) => {
                return (
                  <CommandItem
                    key={creator.email}
                    onSelect={() => {
                      selectedValues.delete(creator.full_name);
                      const filterValues = Array.from(selectedValues.values()).map((value) => value.full_name);
                      column?.setFilterValue(filterValues.length ? filterValues : undefined);
                    }}
                    className="w-full"
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        'bg-primary text-primary-foreground'
                      )}
                    >
                      <CheckIcon className="h-4 w-4" />
                    </div>
                    <div className="flex items-center gap-2">
                      <UserAvatar
                        user={{
                          email: creator.email,
                          name: creator.full_name,
                          picture: getGravatarUrl(creator.email, 32),
                        }}
                        size={32}
                      />
                      <span className="flex-1 truncate">{creator.full_name}</span>
                    </div>
                  </CommandItem>
                );
              })}
              {selectedValues.size > 0 &&
                filteredCreators.some((creator) => !selectedValues.has(creator.full_name)) && (
                  <CommandSeparator className="my-1" />
                )}
              {filteredCreators
                .filter((creator) => !selectedValues.has(creator.full_name))
                .map((creator) => {
                  return (
                    <CommandItem
                      key={creator.email}
                      onSelect={() => {
                        selectedValues.set(creator.full_name, creator);
                        const filterValues = Array.from(selectedValues.values()).map((value) => value.full_name);
                        column?.setFilterValue(filterValues.length ? filterValues : undefined);
                      }}
                      className="w-full"
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className="h-4 w-4" />
                      </div>
                      <div className="flex items-center gap-2">
                        <UserAvatar
                          user={{
                            email: creator.email,
                            name: creator.full_name,
                            picture: getGravatarUrl(creator.email, 32),
                          }}
                          size={32}
                        />
                        <span className="flex-1 truncate">{creator.full_name}</span>
                      </div>
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          </CommandList>
          {selectedValues.size > 0 && (
            <CommandItem
              onSelect={() => column?.setFilterValue(undefined)}
              className="justify-center border-t text-center"
            >
              Clear filter
            </CommandItem>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
