// utils/analytics.ts
import posthog from 'posthog-js';

export const TAG_ACTIONS = {
  TAG_ADDED: 'tag_added',
  TAG_REMOVED: 'tag_removed',
};

export interface PageData {
  userId: string;
  userName: string;
  orgId: string;
  pageId: string;
  title: string;
  timestamp: string; // ISO timestamp format
  tags: string[];
  pageHash: string;
}
export interface PageTagData {
  userId: string;
  userName: string;
  orgId: string;
  pageId: string;
  title?: string;
  url: string;
  timestamp: string; // ISO timestamp format
  action: string;
  tagName: string;
  tagId: string;
}
export const trackPageCreated = (data: PageData): void => {
  try {
    posthog.capture('page_created', {
      page_id: data.pageId,
      title: data.title,
      timestamp: data.timestamp,
      tags: data.tags,
      page_hash: data.pageHash,
      user_id: data.userId,
      username: data.userName,
      org_id: data.orgId,
    });
  } catch (e) {
    console.error('Error tracking page created', e);
  }
};

export const trackPageSaved = (data: PageData): void => {
  try {
    posthog.capture('page_saved', {
      page_id: data.pageId,
      title: data.title,
      timestamp: data.timestamp,
      tags: data.tags,
      page_hash: data.pageHash,
      user_id: data.userId,
      username: data.userName,
      org_id: data.orgId,
    });
  } catch (e) {
    console.error('Error tracking page saved', e);
  }
};

export const trackPageTagEvent = (data: PageTagData): void => {
  try {
    posthog.capture('page_tag_event', {
      page_id: data.pageId,
      timestamp: data.timestamp,
      user_id: data.userId,
      username: data.userName,
      org_id: data.orgId,
      action: data.action,
      tag_name: data.tagName,
      tag_id: data.tagId,
      url: data.url,
      title: data.title,
    });
  } catch (e) {
    console.error('Error tracking page tag event', e);
  }
};
