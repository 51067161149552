import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { useEffect } from 'react';
import { z } from 'zod';

import { DEFAULT_SORTING, TableType } from '@/components/Filters/filter-constants';
import DefaultLayout from '@/components/LayoutDefault';
import { SourcesTable } from '@/components/LibraryTable/SourcesTable';
import { useOrg } from '@/contexts/OrgContext';

const DEFAULT_PAGE_SIZE = 30;

const librarySearchSchema = z.object({
  pageSize: fallback(z.number(), DEFAULT_PAGE_SIZE).default(DEFAULT_PAGE_SIZE),
  pageIndex: fallback(z.number(), 0).default(0),
  generateRowIds: fallback(z.array(z.string()), []).default([]),
  // TODO: Address this as a follow up: https://linear.app/driver-ai/issue/PE-2182/persist-pdf-or-codebase-table-in-local-storage
  type: fallback(z.enum([TableType.CODEBASE, TableType.PDF, 'PAGE', 'PAGE_TEMPLATE']), TableType.CODEBASE).default(
    TableType.CODEBASE
  ),
  search: fallback(z.string(), '').default(''),
  selectedColumns: fallback(z.array(z.string()), []).default([]),
  columnFilters: fallback(
    z.array(
      z.object({
        id: z.string(),
        value: z.unknown().refine((x) => x !== undefined, 'Required'),
      })
    ),
    []
  ).default([]),
  sorting: fallback(
    z
      .array(
        z
          .object({
            id: z.string(),
            desc: z.boolean(),
          })
          .required()
      )
      .min(1),
    DEFAULT_SORTING
  ).default(DEFAULT_SORTING),
});

export const Route = createFileRoute('/$organizationName/library')({
  component: Library,
  validateSearch: zodValidator(librarySearchSchema),
});

function Library() {
  const { organizationDisplayName } = useOrg();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Library`;
  }, [organizationDisplayName]);

  return (
    <>
      <DefaultLayout navbarTitle="Library">
        <div className="space-y-4 pb-12">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium">My Sources</div>
          </div>
          <SourcesTable />
        </div>
      </DefaultLayout>
    </>
  );
}

export default Library;
