import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

/**
 * Log api errors to Sentry
 */
export const ErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const metaArg = (action.meta?.arg as { endpointName?: string; originalArgs?: any }) || {};
    const args = JSON.stringify(metaArg.originalArgs)?.slice(0, 100);
    const error = {
      type: action.type,
      payload: action.payload,
      endpointName: metaArg.endpointName,
      args,
      error: action.error,
    };
    Sentry.captureException(error);
  }
  return next(action);
};
