// This `security` singleton is needed because Auth0 can only be accessed inside React components.
// It's a known limitation and the Auth0 team doesn't seem to care how hacky this is.
// https://github.com/auth0/auth0-react/issues/339
// https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
//
import { Auth0ContextInterface, GetTokenSilentlyOptions } from '@auth0/auth0-react';

let loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
let logout: Auth0ContextInterface['logout'];
let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;
let user: Auth0ContextInterface['user'];

// In the ApiSecurityProvider in __root.tsx, we use the `set` functions to save a bunch of functions from useAuth0.
const security = {
  getLoginWithRedirect: () => loginWithRedirect,
  setLoginWithRedirect: (func: Auth0ContextInterface['loginWithRedirect']) => (loginWithRedirect = func),
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) =>
    (getAccessTokenSilently = func),
  getUser: () => user,
  setUser: (func: Auth0ContextInterface['user']) => (user = func),
  getLogout: () => logout,
  setLogout: (func: Auth0ContextInterface['logout']) => (logout = func),
};

export default security;
