import { Combobox, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { useGetContentByNodeIdQuery, useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import RenderMarkdown from '@/components/StringFormatting/RenderMarkdown';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { useOrgUrlParams } from '@/hooks/useOrgUrlParams';

import SkeletonLoader from './SkeletonLoader';

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Codebases() {
  const { organizationName } = useOrgUrlParams();
  const [activeOption, setActiveOption] = useState<PrimaryAssetRecord | undefined>(undefined);
  const nodeId = activeOption?.most_recent_version?.root_node?.id ?? '';
  const { data, isLoading } = useGetContentByNodeIdQuery(
    {
      node_id: nodeId,
      content_kind: 'TOP_LEVEL_TERSE_SENTENCE',
    },
    { skip: !nodeId }
  );
  const description = data?.results?.[0]?.content;

  const navigate = useNavigate();

  const numberToFetch = 15;

  const primaryAssetParams = {
    text: '',
    limit: numberToFetch,
    offset: 0,
    kind: [PrimaryAsset.CODEBASE],
    sort_direction: 'DESC' as const,
    sort_by: 'updated_at',
    tag_ids: [],
  };

  const {
    data: codebasesData,
    isLoading: isLoadingCodebases,
    error: codebasesError,
  } = useGetPrimaryAssetQuery(primaryAssetParams);

  const isLoadingDescriptions = false;
  const descriptionsError = false;

  if (isLoadingCodebases || isLoadingDescriptions) return <SkeletonLoader />;
  if (codebasesError || descriptionsError) {
    return (
      <div>
        <p className="text-red-500">An error occurred while loading the data. Please try again later.</p>
        <SkeletonLoader />
      </div>
    );
  }

  const primaryAssets: PrimaryAssetRecord[] = codebasesData?.results || [];

  return (
    <div className="w-full">
      <div className="w-full transform divide-y divide-border overflow-hidden rounded-lg ring-1 ring-border transition-all">
        <Combobox<PrimaryAssetRecord>
          value={activeOption}
          onChange={(codebase: PrimaryAssetRecord | null) => {
            setActiveOption(codebase ?? undefined);
            if (codebase && codebase.browsable) {
              navigate({
                to: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$',
                params: {
                  organizationName,
                  workspaceId: codebase.id,
                  codebaseId: codebase.id,
                  _splat: codebase.display_name,
                },
              });
            }
          }}
        >
          {({ value: activeValue }) => {
            return (
              <>
                <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-border">
                  <div
                    className={classNames(
                      'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                      !!activeValue && 'sm:h-96'
                    )}
                  >
                    <div className="-mx-2 text-sm text-foreground">
                      {primaryAssets.map((codebase) => (
                        <ComboboxOption
                          as="div"
                          key={codebase.id}
                          value={codebase}
                          className={({ focus }: { focus: boolean }) =>
                            classNames(
                              'flex cursor-default select-none items-center rounded-md p-2',
                              focus && 'bg-accent-emphasis text-foreground'
                            )
                          }
                        >
                          {({ focus }: { focus: boolean }) => (
                            <>
                              <span className="ml-3 flex-auto truncate">{codebase.display_name}</span>
                              {focus && (
                                <ChevronRightIcon
                                  className="ml-3 h-5 w-5 flex-none text-muted-foreground"
                                  aria-hidden="true"
                                />
                              )}
                            </>
                          )}
                        </ComboboxOption>
                      ))}
                    </div>
                  </div>

                  {activeValue && (
                    <div className="hidden h-96 w-3/5 flex-none flex-col divide-y divide-border overflow-y-auto sm:flex">
                      <div className="flex-none p-6">
                        <h2 className="mt-3 truncate text-lg font-medium text-foreground">
                          {activeValue.display_name}
                        </h2>
                        <div className="mt-2 flex flex-wrap gap-2">
                          {activeValue.tags && activeValue.tags.length > 0 ? (
                            activeValue.tags.map((tag: TagResult) => (
                              <Badge key={tag.name} variant="outline" className="px-1.5">
                                <span
                                  className="mr-2 inline-block h-2 w-2 rounded-full"
                                  style={{ backgroundColor: tag.hex_color }}
                                />
                                {tag.name}
                              </Badge>
                            ))
                          ) : (
                            <span className="text-sm text-muted-foreground"></span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-auto flex-col justify-between p-6 pb-4 text-sm text-foreground">
                        {!description && !isLoading && (
                          <Badge variant="outline" className="w-fit">
                            Generating documentation
                          </Badge>
                        )}
                        {description && !isLoading && (
                          <div>
                            <RenderMarkdown content={description} />
                          </div>
                        )}
                        {isLoading && (
                          <div className="flex flex-col gap-2">
                            <Skeleton className="h-7 w-[256px]" />
                          </div>
                        )}
                        {activeOption?.browsable && (
                          <Button
                            onClick={() => {
                              navigate({
                                to: '/$organizationName/ws/$workspaceId/cb/$codebaseId/tree/$',
                                params: {
                                  organizationName,
                                  workspaceId: activeValue.id,
                                  codebaseId: activeValue.id,
                                  _splat: activeValue.display_name,
                                },
                                search: { versionId: activeValue.most_recent_version?.id },
                              });
                            }}
                            className="mt-4 w-full"
                            variant="default"
                          >
                            Go to codebase
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </ComboboxOptions>

                {primaryAssets.length === 0 && (
                  <div className="p-6 text-center text-foreground">
                    <p>No codebases found.</p>
                  </div>
                )}
              </>
            );
          }}
        </Combobox>
      </div>
    </div>
  );
}
