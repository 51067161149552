import { useLocation, useParams, useSearch } from '@tanstack/react-router';
import { ColumnFilter } from '@tanstack/react-table';
import { Home, Layers, LucideIcon, NotebookPen, Tag } from 'lucide-react';

import { useGetTagsQuery } from '@/api/api';
import { HelpMenu } from '@/components/Navbar/HelpMenu';
import { UserMenu } from '@/components/Navbar/UserMenu';

type Submenu = {
  href: string;
  label: string;
  active: boolean;
  hex_color?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: LucideIcon;
  submenus: Submenu[];
};

type CustomComponent = React.ComponentType<any>;

type MenuItem = {
  type: 'link' | 'component';
  href?: string;
  label?: string;
  active?: boolean;
  icon?: LucideIcon;
  submenus?: Submenu[];
  component?: CustomComponent;
};

type Group = {
  groupLabel: string;
  items: MenuItem[];
};

export function useMenuList(): Group[] {
  const location = useLocation();
  // TODO: fix typings.
  const query: any = useSearch({
    strict: false,
  });

  const { organizationName: organizationNameParam } = useParams({ strict: false });

  const organizationName = query.organizationName || organizationNameParam;

  const isActive = (path: string) => location.pathname.includes(path);

  const { data: tagsData } = useGetTagsQuery({ type: 'tag', limit: 1000, offset: 0 });

  // Safely access tags, ensuring it's an array
  const tags = Array.isArray(tagsData) ? tagsData : tagsData && Array.isArray(tagsData.results) ? tagsData.results : [];

  const isTagActive = (tagId: string) => {
    const columnFilters = (query.columnFilters as ColumnFilter[]) || undefined;
    if (columnFilters) {
      try {
        return columnFilters.some(
          (filter) => filter.id === 'tags' && Array.isArray(filter.value) && filter.value.includes(tagId)
        );
      } catch (error) {
        console.error('Error parsing columnFilters:', error);
        return false;
      }
    }
    return false;
  };

  // Update the tags mapping to include color
  const tagSubmenus = tags
    .map((tag: { id: string; name: string; hex_color?: string }) => ({
      href: `/${organizationName}/library?columnFilters=${encodeURIComponent(
        JSON.stringify([
          {
            id: 'tags',
            value: [tag.id],
          },
        ])
      )}&pageIndex=0&pageSize=30`,
      label: tag.name,
      active: isTagActive(tag.id),
      hex_color: tag.hex_color,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groups: Group[] = [
    {
      groupLabel: 'Main',
      items: [
        {
          type: 'link',
          href: `/${organizationName}/`,
          label: 'Home',
          active: location.pathname === `/${organizationName}` || location.pathname === `/${organizationName}/`,
          icon: Home,
          submenus: [],
        },
        {
          type: 'link',
          href: `/${organizationName}/library`,
          label: 'My Sources',
          active: isActive('/library'),
          icon: Layers,
          submenus: [],
        },
        {
          type: 'link',
          href: `/${organizationName}/documents`,
          label: 'My Documents',
          active: isActive('/documents'),
          icon: NotebookPen,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: 'end',
      items: [
        {
          type: 'component',
          component: HelpMenu,
          label: 'Help Menu',
        },
        {
          type: 'component',
          component: UserMenu,
          label: 'User Menu',
        },
      ],
    },
  ];

  // Only add Tags to sidebar if there are tags in org
  if (tagSubmenus.length > 0) {
    groups.splice(1, 0, {
      groupLabel: 'Tags',
      items: [
        {
          type: 'link' as const,
          href: `/${organizationName}/tags`,
          label: 'Tags',
          active: isActive('/tags'),
          icon: Tag,
          submenus: tagSubmenus,
        },
      ],
    });
  }

  return groups;
}
