import { Code, File, FileImage, FileText, Loader2, User } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/components/ui/dialog';

interface DeleteDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  itemType: string;
  itemName: string;
  contentTypeName: string;
  onCancel: () => void;
  onDelete: () => void;
  message?: string;
  isLoading?: boolean;
}

const getIcon = (contentTypeName: string) => {
  switch (contentTypeName) {
    case 'user':
      return <User className="mr-2 h-4 w-4" />;
    case 'application_note':
      return <FileText className="mr-2 h-4 w-4" />;
    case 'supplemental-document':
      return <FileImage className="mr-2 h-4 w-4" />;
    case 'codebase':
      return <Code className="mr-2 h-4 w-4" />;
    default:
      return <File className="mr-2 h-4 w-4" />;
  }
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onOpenChange,
  itemType,
  itemName,
  contentTypeName,
  onCancel,
  onDelete,
  isLoading,
  message = 'will be deleted forever.',
}) => (
  <Dialog open={isOpen} onOpenChange={onOpenChange}>
    <DialogContent>
      <DialogTitle>Delete {itemType}?</DialogTitle>
      <DialogDescription>
        <span className="my-2 flex items-start">
          <span className="mt-0.5">{getIcon(contentTypeName)}</span>
          <span>
            <strong className="mr-0.5">{itemName}</strong> {message}
          </span>
        </span>
      </DialogDescription>
      <div className="mt-4 flex justify-end space-x-2">
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="destructive" onClick={onDelete} disabled={isLoading}>
          {isLoading ? 'Deleting...' : 'Delete'}
          {isLoading && <Loader2 className="ml-2 h-4 w-4" />}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);
