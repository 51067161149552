import { useState } from 'react';

import { RoleName, User } from '@/api/types/organization';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface ChangeRoleDialogProps {
  member: User;
  userRole: RoleName | undefined;
  isUpdatingUserRole: boolean;
  onChangeRole: (id: string, role: RoleName) => void;
  onClose: () => void;
}

export const ChangeRoleDialog: React.FC<ChangeRoleDialogProps> = ({
  member,
  userRole,
  isUpdatingUserRole,
  onChangeRole,
  onClose,
}) => {
  const [selectedRole, setSelectedRole] = useState<RoleName | undefined>(userRole);

  return (
    <DialogContent aria-describedby="role-change-description">
      <DialogHeader>
        <DialogTitle>Change user role</DialogTitle>
        <DialogDescription>Select a new role for this user to change their permissions.</DialogDescription>
      </DialogHeader>
      <Select
        value={selectedRole}
        onValueChange={(value: RoleName) => {
          setSelectedRole(value);
        }}
      >
        <SelectTrigger className="bg-background text-foreground">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="z-100">
          <SelectItem value="Admin" disabled={userRole === 'Admin'}>
            Admin
          </SelectItem>
          <SelectItem value="Editor" disabled={userRole === 'Editor'}>
            Editor
          </SelectItem>
        </SelectContent>
      </Select>
      <div className="mt-4 flex justify-end gap-2">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={isUpdatingUserRole || selectedRole === userRole}
          onClick={async () => {
            if (!selectedRole) return;
            await onChangeRole(member.user_id, selectedRole);
            onClose();
          }}
        >
          {isUpdatingUserRole ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </DialogContent>
  );
};
