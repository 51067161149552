import {
  AlertCircle,
  Braces,
  Code,
  File,
  FilePlus2,
  FileSpreadsheet,
  FileText,
  Folder,
  FolderCode,
  FolderSync,
} from 'lucide-react';

import { PrimaryAssetRecord, VersionStatus } from '@/api/types/node';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { SourceAssetRecord } from '../UserDefinedScope/SourceAssetRecord';

interface CellContentWithStatusIconProps {
  content: PrimaryAssetRecord | SourceAssetRecord;
}

export const getDocumentIcon = (type: string, iconSize: string = 'h-4 w-4') => {
  const iconMap: { [key: string]: React.ReactNode } = {
    driverTemplate: <FilePlus2 className={`${iconSize}`} />,
    template: <FilePlus2 className={`${iconSize}`} />,
    document: <FileText className={`${iconSize} text-blue-500`} />,
    application_note: <File className={`${iconSize} text-blue-500 dark:text-blue-300`} />,
    PAGE: <File className={`${iconSize} text-blue-500 dark:text-blue-300`} />,
    codebase: <Code className={`${iconSize} text-orange-500 dark:text-orange-300`} />,
    CODEBASE: <Code className={`${iconSize} text-orange-500 dark:text-orange-300`} />,
    'codebase-directory': <FolderCode className={`${iconSize} text-orange-500 dark:text-orange-300`} />,
    collection: <Folder className={`${iconSize} text-yellow-500 dark:text-yellow-300`} />,
    pdf_summary: <FileSpreadsheet className={`${iconSize} text-rose-500 dark:text-rose-300`} />,
    'supplemental-document': <FileSpreadsheet className={`${iconSize} text-rose-500 dark:text-rose-300`} />,
    FILE: <FileSpreadsheet className={`${iconSize} text-rose-500 dark:text-rose-300`} />,
    'codebase-file': <Braces className={`${iconSize} text-amber-500 dark:text-amber-300`} />,
  };
  return iconMap[type] || <File className={iconSize} />;
};

export const ContentIconWithStatus: React.FC<CellContentWithStatusIconProps> = ({ content }) => {
  const status = content?.most_recent_version?.status;
  const iconSize = 'h-4 w-4';

  const IconComponent = getDocumentIcon(content.kind, iconSize);

  return (
    <div className="relative flex flex-shrink-0 justify-center p-2 pl-0 text-muted-foreground">
      <TooltipProvider>
        {(status === VersionStatus.GENERATION_COMPLETE || status === VersionStatus.USER_DATA) && IconComponent}
        {status === VersionStatus.GENERATING && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>{IconComponent}</div>
            </TooltipTrigger>
            <TooltipContent>Generating documentation</TooltipContent>
          </Tooltip>
        )}
        {status === VersionStatus.CONNECTED && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <FolderSync className={`${iconSize} text-green-500`} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Connected codebase</TooltipContent>
          </Tooltip>
        )}
        {status === VersionStatus.CONNECTION_FAILED && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <AlertCircle className={`${iconSize} text-red-500`} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Error connecting codebase</TooltipContent>
          </Tooltip>
        )}
        {(!status || status === VersionStatus.GENERATION_ERROR) && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <AlertCircle className={`${iconSize} text-red-500`} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Error generating documentation</TooltipContent>
          </Tooltip>
        )}
      </TooltipProvider>
    </div>
  );
};
