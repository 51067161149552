import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset } from '@/api/types/node';

export function useGetHasLibraryData() {
  const codebaseAndPdfParams = {
    kind: [PrimaryAsset.CODEBASE, PrimaryAsset.FILE],
    limit: 1,
    offset: 0,
  };

  const pageAndTemplateParams = {
    kind: [PrimaryAsset.PAGE, PrimaryAsset.PAGE_TEMPLATE],
    limit: 1,
    offset: 0,
  };

  const {
    data: codebaseAndPdfData,
    isLoading: isLoadingCodebaseAndPdf,
    isFetching: isFetchingCodebaseAndPdf,
    refetch: refetchCodebaseAndPdf,
  } = useGetPrimaryAssetQuery(codebaseAndPdfParams);

  const {
    data: pageAndTemplateData,
    isLoading: isLoadingPageAndTemplate,
    isFetching: isFetchingPageAndTemplate,
    refetch: refetchPageAndTemplate,
  } = useGetPrimaryAssetQuery(pageAndTemplateParams);

  const codebaseAndPdfCount = codebaseAndPdfData?.results?.length ?? 0;
  const pageAndTemplateCount = pageAndTemplateData?.results?.length ?? 0;

  const hasCodebaseAndPdfRecords = codebaseAndPdfCount > 0;
  const hasPageAndTemplateRecords = pageAndTemplateCount > 0;
  const hasRecords = hasCodebaseAndPdfRecords || hasPageAndTemplateRecords;

  return {
    hasCodebaseAndPdfRecords,
    hasPageAndTemplateRecords,
    hasRecords,
    isLoading: isLoadingCodebaseAndPdf || isLoadingPageAndTemplate,
    isFetching: isFetchingCodebaseAndPdf || isFetchingPageAndTemplate,
    refetch: () => {
      refetchCodebaseAndPdf();
      refetchPageAndTemplate();
    },
  };
}
