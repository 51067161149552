import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useOrg } from '@/contexts/OrgContext';

let installed = false;

export const useErrorLogging = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Initialize Sentry
    if (import.meta.env.VITE_SENTRY_DSN && !installed) {
      console.info('INIT ERROR LOGGING');

      // This sets the sample rate to 10% in prod and 100% in other environments.
      const replaysSessionSampleRate = window.location.host === 'app.driverai.com' ? 0.1 : 1.0;
      const apiHostname = import.meta.env.VITE_API_URL ? new URL(import.meta.env.VITE_API_URL).hostname : 'localhost';

      Sentry.init({
        environment: window.location.host.replace(/\./g, '-'),
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
          Sentry.captureConsoleIntegration({
            levels: ['error', 'warn', 'info'],
          }),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', apiHostname],
        // Session Replay
        replaysSessionSampleRate: replaysSessionSampleRate,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });

      installed = true;
    }
  }, []);
};

export const useLogUser = () => {
  const { userId } = useOrg();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!userId || !Sentry.isInitialized()) return;

    Sentry.setUser({ id: userId });
  }, [userId]);
};
