import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Columns, ListFilter, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { filterExcludedColumns } from '@/components/BaseTable/utils/table-utils';
import { ActiveFilters } from '@/components/Filters/ActiveFilters';
import { ColumnsFilter } from '@/components/Filters/ColumnsFilter';
import { CreatorFilter } from '@/components/Filters/CreatorFilter';
import { DateFilter } from '@/components/Filters/DateFilter';
import { TableFilter } from '@/components/Filters/TableFilter';
import { TagsFilter } from '@/components/Filters/TagsFilter';
import GettingStarted from '@/components/Guides/GettingStarted';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useDebounce } from '@/hooks/useDebounce';
import { documentsRouteApi } from '@/utils/route';

import { NewDocumentAction } from './NewDocumentAction';
import { PagesTable } from './PagesTable';
import { TemplatesTable } from './TemplatesTable';
import {
  DEFAULT_PAGES_COLUMN_VISIBILITY,
  DEFAULT_TEMPLATES_COLUMN_VISIBILITY,
  DocumentTableType,
  DocumentType,
} from './constants';
import { pageColumns, templateColumns } from './document-columns';
import { useDocumentsTable } from './useDocumentsTable';

interface SearchParams {
  type?: DocumentType;
  selectedColumns?: string[];
  search?: string;
  columnFilters?: any[];
  sorting?: { id: string; desc: boolean }[];
  pageIndex?: string;
  pageSize?: string;
}

interface TableToolbarProps {
  table: Table<PrimaryAssetRecord>;
  data: PrimaryAssetRecord[];
  selectedType: DocumentTableType;
  onTypeChange: (type: DocumentTableType) => void;
  selectedColumns: string[];
  toggleColumn: (columnId: string) => void;
  setSelectedColumns: (columns: string[]) => void;
}

function TableToolbar({
  table,
  selectedType,
  onTypeChange,
  selectedColumns,
  toggleColumn,
  setSelectedColumns,
}: TableToolbarProps) {
  const navigate = documentsRouteApi.useNavigate();
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchValue = useDebounce(inputValue, 400);

  // Get the current tag filters
  const tagFilters = table
    .getState()
    .columnFilters.filter((filter) => filter.id === 'tags')
    .map((filter) => filter.value as string[])
    .flat();

  // Check if there are any non-kind filters active
  const isFiltered = table.getState().columnFilters.some((filter) => {
    if (filter.id === 'kind') return false;
    if (filter.id === 'display_name') return false;
    if (Array.isArray(filter.value)) return filter.value.length > 0;
    return !!filter.value;
  });

  // Get all active filters
  const activeFilters = table.getState().columnFilters.reduce(
    (acc, filter) => {
      if (filter.id === 'kind' || filter.id === 'display_name') return acc;

      if (filter.id === 'created_at' || filter.id === 'updated_at') {
        const value = filter.value as { from: string; to: string };
        if (value?.from) acc[`${filter.id}__gte`] = value.from;
        if (value?.to) acc[`${filter.id}__lte`] = value.to;
        return acc;
      }

      if (filter.id === 'tags') {
        acc.tag_ids = filter.value as string[];
        return acc;
      }

      if (filter.id === 'status') {
        acc['versions.status'] = filter.value as string[];
        return acc;
      }

      acc[filter.id] = filter.value;
      return acc;
    },
    {} as Record<string, any>
  );

  // Fetch filtered count for pages
  const { data: pagesData, isFetching: isPagesFetching } = useGetPrimaryAssetQuery({
    kind: [PrimaryAsset.PAGE],
    limit: 1,
    offset: 0,
    ...activeFilters,
    tag_ids: tagFilters.length > 0 ? tagFilters : undefined,
    display_name__ilike: debouncedSearchValue ? `%${debouncedSearchValue}%` : undefined,
  });

  // Fetch filtered count for templates
  const { data: templatesData, isFetching: isTemplatesFetching } = useGetPrimaryAssetQuery({
    kind: [PrimaryAsset.PAGE_TEMPLATE],
    limit: 1,
    offset: 0,
    ...activeFilters,
    tag_ids: tagFilters.length > 0 ? tagFilters : undefined,
    display_name__ilike: debouncedSearchValue ? `%${debouncedSearchValue}%` : undefined,
  });

  const isLoadingCounts = isPagesFetching || isTemplatesFetching;

  useEffect(() => {
    // Update the table's global filter
    table.setGlobalFilter(debouncedSearchValue);
  }, [debouncedSearchValue, table]);

  return (
    <div className="flex flex-col gap-4" data-loading={isLoadingCounts}>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex flex-shrink-0 items-center space-x-2">
          <Button
            variant={selectedType === DocumentTableType.PAGE ? 'secondary' : 'ghost'}
            size="sm"
            onClick={() => onTypeChange(DocumentTableType.PAGE)}
            disabled={isLoadingCounts}
          >
            Pages {!isLoadingCounts && `(${pagesData?.total_count ?? 0})`}
            {isLoadingCounts && <span className="animate-pulse">(...)</span>}
          </Button>
          <Button
            variant={selectedType === DocumentTableType.PAGE_TEMPLATE ? 'secondary' : 'ghost'}
            size="sm"
            onClick={() => onTypeChange(DocumentTableType.PAGE_TEMPLATE)}
            disabled={isLoadingCounts}
          >
            Templates {!isLoadingCounts && `(${templatesData?.total_count ?? 0})`}
            {isLoadingCounts && <span className="animate-pulse">(...)</span>}
          </Button>
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <div className="flex flex-wrap items-center gap-1.5">
            {filterExcludedColumns(selectedColumns).map((columnId) => {
              const column = table.getColumn(columnId);
              if (!column) return null;

              const columns = selectedType === DocumentTableType.PAGE ? pageColumns : templateColumns;
              const columnDef = columns.find((col) => col.id === columnId || (col as any).accessorKey === columnId);

              // Get the title from the column definition
              let columnTitle = columnId.replace(/_/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase());
              if (columnDef?.header && typeof columnDef.header === 'function') {
                try {
                  const headerComponent = columnDef.header({ column } as any);
                  if (headerComponent?.props?.title) {
                    columnTitle = headerComponent.props.title;
                  }
                } catch (e) {
                  // Fallback to the default title if header function fails
                }
              }

              if (columnId === 'created_at' || columnId === 'updated_at') {
                return (
                  <DateFilter
                    key={columnId}
                    column={column}
                    table={table}
                    showSelectedInTitle={false}
                    title={columnTitle}
                    routeApi={documentsRouteApi}
                  />
                );
              }

              if (columnId === 'creator') {
                return <CreatorFilter key={columnId} column={column} table={table} title={columnTitle} />;
              }

              return (
                <ActiveFilters
                  key={columnId}
                  column={column}
                  table={table}
                  title={columnTitle}
                  routeApi={documentsRouteApi}
                  columns={columns}
                />
              );
            })}
          </div>

          <div className="relative">
            <Input
              placeholder="Search documents..."
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              className="h-8 w-[150px] flex-shrink-0 pr-8 sm:w-[180px] md:w-[200px] lg:w-[250px]"
            />
            {inputValue && (
              <button
                onClick={() => setInputValue('')}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
              >
                <X className="h-4 w-4" />
              </button>
            )}
          </div>

          <div className="flex flex-shrink-0 items-center gap-2">
            <ColumnsFilter
              table={table}
              title={
                <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                  <Columns className="h-4 w-4" />
                </Button>
              }
              columns={selectedType === DocumentTableType.PAGE ? pageColumns : templateColumns}
              defaultColumnVisibility={
                selectedType === DocumentTableType.PAGE
                  ? DEFAULT_PAGES_COLUMN_VISIBILITY
                  : DEFAULT_TEMPLATES_COLUMN_VISIBILITY
              }
            />
            <TableFilter
              table={table}
              selectedColumns={selectedColumns}
              toggleColumn={toggleColumn}
              title={
                <Button variant="ghost" size="sm" className="relative h-8 w-8 p-0">
                  <ListFilter className="h-4 w-4" />
                  {selectedColumns.length > 0 && (
                    <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
                      {selectedColumns.length}
                    </span>
                  )}
                </Button>
              }
              columns={selectedType === DocumentTableType.PAGE ? pageColumns : templateColumns}
            />
            {table.getColumn('tags') && <TagsFilter column={table.getColumn('tags')} />}
          </div>

          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => {
                table.resetColumnFilters();
                setSelectedColumns([]);
                setInputValue('');
                navigate({
                  search: (prev: Record<string, unknown>) => ({
                    ...prev,
                    columnFilters: undefined,
                    selectedColumns: undefined,
                  }),
                  replace: true,
                });
              }}
              className="h-8 flex-shrink-0 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
          <NewDocumentAction />
        </div>
      </div>
    </div>
  );
}

interface CurrentTableProps {
  toolbarContent: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => React.ReactNode;
  selectedType: DocumentTableType;
}

function CurrentTableWrapper({ toolbarContent, selectedType }: CurrentTableProps) {
  const CurrentTable = selectedType === DocumentTableType.PAGE ? PagesTable : TemplatesTable;
  const defaultColumnVisibility =
    selectedType === DocumentTableType.PAGE ? DEFAULT_PAGES_COLUMN_VISIBILITY : DEFAULT_TEMPLATES_COLUMN_VISIBILITY;

  return <CurrentTable toolbarContent={toolbarContent} defaultColumnVisibility={defaultColumnVisibility} />;
}

export function DocumentsTable() {
  const navigate = documentsRouteApi.useNavigate();
  const query = documentsRouteApi.useSearch() as SearchParams;

  // Get selectedColumns from URL
  const selectedColumns = useMemo(() => {
    return query.selectedColumns || [];
  }, [query.selectedColumns]);

  // Get selectedType from URL or use default
  const selectedType = useMemo(() => {
    return query.type === DocumentType.PAGE_TEMPLATE ? DocumentTableType.PAGE_TEMPLATE : DocumentTableType.PAGE;
  }, [query.type]);

  const handleTypeChange = (type: DocumentTableType) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        type: type === DocumentTableType.PAGE ? DocumentType.PAGE : DocumentType.PAGE_TEMPLATE,
      }),
    });
  };

  const toggleColumn = (columnId: string) => {
    const newColumns = selectedColumns.includes(columnId)
      ? selectedColumns.filter((id) => id !== columnId)
      : [...selectedColumns, columnId];

    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        selectedColumns: newColumns.length > 0 ? newColumns : undefined,
      }),
      replace: true,
    });
  };

  const toolbarContent = (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => (
    <TableToolbar
      table={table}
      data={data}
      selectedType={selectedType}
      onTypeChange={handleTypeChange}
      selectedColumns={selectedColumns}
      toggleColumn={toggleColumn}
      setSelectedColumns={(columns) => {
        navigate({
          search: (prev: Record<string, unknown>) => ({
            ...prev,
            selectedColumns: columns.length > 0 ? columns : undefined,
          }),
          replace: true,
        });
      }}
    />
  );

  const { hasRecords, isLoading } = useDocumentsTable({
    columns: selectedType === DocumentTableType.PAGE ? pageColumns : templateColumns,
    kind: selectedType === DocumentTableType.PAGE ? [PrimaryAsset.PAGE] : [PrimaryAsset.PAGE_TEMPLATE],
    initialState: {
      columnVisibility:
        selectedType === DocumentTableType.PAGE ? DEFAULT_PAGES_COLUMN_VISIBILITY : DEFAULT_TEMPLATES_COLUMN_VISIBILITY,
    },
  });

  if (!hasRecords && !isLoading) {
    return (
      <div className="flex min-h-[90vh] flex-col items-start justify-center">
        <div className="mb-10 flex flex-col space-y-2">
          <h1 className="text-2xl font-semibold">Get started with Driver</h1>
        </div>
        <GettingStarted />
      </div>
    );
  }

  return <CurrentTableWrapper toolbarContent={toolbarContent} selectedType={selectedType} />;
}
