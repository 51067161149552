import { CheckIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';

interface ColumnsFilterProps {
  table: Table<PrimaryAssetRecord>;
  title?: ReactNode;
  columns: ColumnDef<PrimaryAssetRecord>[];
  defaultColumnVisibility?: Record<string, boolean>;
}

export function ColumnsFilter({ table, title, columns, defaultColumnVisibility }: ColumnsFilterProps) {
  const columnVisibility = table.getState().columnVisibility;
  const setColumnVisibility = table.setColumnVisibility;

  // Check if current visibility differs from default.
  // We are using this to determine if we should show the reset to default columns button
  const hasCustomVisibility = defaultColumnVisibility
    ? Object.entries(columnVisibility).some(([key, value]) => defaultColumnVisibility[key] !== value)
    : false;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{title || <Button variant="ghost">Columns</Button>}</DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[250px] p-0">
        <Command>
          <CommandInput placeholder="Show/Hide columns..." className="border-none ps-0 focus:ring-0" />
          <CommandList className="max-h-[400px] overflow-y-auto">
            <CommandEmpty>No results</CommandEmpty>
            <CommandGroup>
              {columns
                .filter((column) => {
                  const columnId = column.id || (column as any).accessorKey;
                  if (!columnId) return false;
                  // Only exclude select and actions columns
                  return columnId !== 'select' && columnId !== 'actions';
                })
                .map((column) => {
                  const columnId = column.id || (column as any).accessorKey;
                  if (!columnId) return null;

                  const tableColumn = table.getColumn(columnId);
                  if (!tableColumn) return null;

                  const isVisible = tableColumn.getIsVisible();
                  const columnTitle =
                    column.header && typeof column.header === 'function'
                      ? (column as any).header({ column: tableColumn }).props.title
                      : columnId.replace(/_/g, ' ');

                  return (
                    <CommandItem
                      key={columnId}
                      onSelect={() => tableColumn.toggleVisibility(!isVisible)}
                      className="w-full"
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isVisible ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span className="flex-1 truncate text-left capitalize">{columnTitle}</span>
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          </CommandList>
          {hasCustomVisibility && defaultColumnVisibility && (
            <CommandItem
              onSelect={() => {
                setColumnVisibility(defaultColumnVisibility);
              }}
              className="justify-center border-t text-center"
            >
              Reset to default columns
            </CommandItem>
          )}
        </Command>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
