import { useLocalStorage } from '@rehooks/local-storage';
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  OnChangeFn,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset, PrimaryAssetRecord, PrimaryAssetType } from '@/api/types/node';
import { useGetHasLibraryData } from '@/components/LibraryTable/useGetHasLibraryData';
import { StorageKeys } from '@/constants/storage';
import { documentsRouteApi } from '@/utils/route';

import { DocumentType } from './constants';

// Map column IDs to API sort fields
const SORT_FIELD_MAPPING: Record<string, string> = {
  display_name: 'display_name',
  created_at: 'created_at',
  updated_at: 'updated_at',
  creator: 'most_recent_version.creator.full_name',
};

interface SearchParams {
  type?: DocumentType;
  selectedColumns?: string[];
  search?: string;
  columnFilters?: ColumnFilter[];
  sorting?: { id: string; desc: boolean }[];
  pageIndex?: string;
  pageSize?: string;
}

interface UseDocumentsTableProps {
  columns: ColumnDef<PrimaryAssetRecord>[];
  kind: PrimaryAssetType[];
  initialState?: {
    columnVisibility?: Record<string, boolean>;
  };
}

export function useDocumentsTable({ columns, kind, initialState }: UseDocumentsTableProps) {
  const navigate = documentsRouteApi.useNavigate();
  const query = documentsRouteApi.useSearch() as SearchParams;

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(query.columnFilters || []);
  const [sorting, setSorting] = useState<SortingState>(
    query.sorting || [
      {
        id: 'updated_at',
        desc: true,
      },
    ]
  );
  const [pagination, setPagination] = useState({
    pageIndex: Number(query.pageIndex) || 0,
    pageSize: Number(query.pageSize) || 30,
  });
  const [globalFilter, setGlobalFilter] = useState(query.search || '');

  // Determine which storage key to use based on the kind
  const storageKey = kind.includes(PrimaryAsset.PAGE)
    ? StorageKeys.PAGES_COLUMN_VISIBILITY
    : StorageKeys.TEMPLATES_COLUMN_VISIBILITY;

  const [columnVisibility, setColumnVisibility] = useLocalStorage<VisibilityState>(
    storageKey,
    initialState?.columnVisibility || {}
  );

  const handleColumnVisibilityChange: OnChangeFn<VisibilityState> = (updaterOrValue) => {
    const newVisibility =
      typeof updaterOrValue === 'function' ? updaterOrValue(columnVisibility || {}) : updaterOrValue;
    setColumnVisibility(newVisibility);
  };

  // Only include API-specific filters, not the search term
  const apiColumnFilters = columnFilters.filter((filter) => filter.id !== 'display_name');

  const { hasPageAndTemplateRecords: hasRecords, isLoading: hasRecordsLoading } = useGetHasLibraryData();

  const { data, isLoading: isLoadingData } = useGetPrimaryAssetQuery({
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    kind,
    sort_by: sorting.length > 0 ? SORT_FIELD_MAPPING[sorting[0].id] || sorting[0].id : undefined,
    sort_direction: sorting.length > 0 ? (sorting[0].desc ? 'DESC' : 'ASC') : undefined,
    // Search in display_name only
    display_name__ilike: globalFilter ? `%${globalFilter}%` : undefined,
    ...apiColumnFilters.reduce(
      (acc, filter) => {
        if (filter.id === 'created_at' || filter.id === 'updated_at') {
          const value = filter.value as { from: string; to: string };
          if (value?.from) acc[`${filter.id}__gte`] = value.from;
          if (value?.to) acc[`${filter.id}__lte`] = value.to;
          return acc;
        }

        if (filter.id === 'tags') {
          acc.tag_ids = filter.value as string[];
          return acc;
        }

        if (filter.id === 'status') {
          acc['versions.status'] = filter.value as string[];
          return acc;
        }

        if (filter.id === 'creator') {
          acc['most_recent_version.creator.full_name__in'] = filter.value as string[];
          return acc;
        }

        acc[filter.id] = filter.value;
        return acc;
      },
      {} as Record<string, any>
    ),
  });

  const isLoading = isLoadingData || hasRecordsLoading;

  // Update URL when filters, sorting, or pagination change
  useEffect(() => {
    navigate({
      search: (prev) => ({
        ...prev,
        columnFilters: columnFilters.length > 0 ? columnFilters : undefined,
        sorting: sorting.length > 0 ? sorting : undefined,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        search: globalFilter || undefined,
      }),
      replace: true,
    });
  }, [columnFilters, sorting, pagination, globalFilter, navigate]);

  const table = useReactTable({
    data: data?.results || [],
    columns,
    state: {
      columnFilters,
      sorting,
      columnVisibility,
      globalFilter,
      pagination,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualFiltering: true,
    pageCount: Math.ceil((data?.total_count || 0) / pagination.pageSize),
    onPaginationChange: setPagination,
    initialState: {
      columnVisibility: initialState?.columnVisibility || {},
    },
  });

  return {
    table,
    data: data?.results || [],
    isLoading,
    hasRecords,
  };
}
