import { getUserback } from '@userback/widget';

export const UserbackMiddleware = (store: any) => (next: any) => (action: any) => {
  const Userback = getUserback();
  // You can customize this condition based on when you want to trigger the Userback event
  if (action?.meta?.arg?.endpointName === 'createDocument') {
    // @ts-ignore these are not defined in the types
    Userback?.addCustomEvent('createDocument');
  }
  if (action?.meta?.arg?.endpointName === 'postAgentPipelineAsync') {
    // @ts-ignore these are not defined in the types
    Userback?.addCustomEvent('smartInstructionExecution', {
      prompt: action?.meta?.arg?.originalArgs?.prompt,
    });
  }
  return next(action);
};
