import { ContentIconWithStatus } from '@/components/ContentStatus/ContentIconWithStatus';

interface CellContentIconProps {
  row: {
    getValue: <T>(key: string) => T;
  };
}

export const CellContentIcon: React.FC<CellContentIconProps> = ({ row }) => {
  // const type = row.getValue<string>('kind');
  // @ts-ignore, the only way to get the status is to access the original object. you cant access it with `getValue` without adding it as a column, and hiding columns is not easily
  const record = row.original as LibraryRecord;
  return (
    <div className="pl-2">
      <ContentIconWithStatus content={record} />
    </div>
  );
};
