import { PrimaryAssetRecord, VersionStatusType } from '@/api/types/node';
import { EXCLUDED_FILTER_COLUMNS, EXCLUDED_STATUSES } from '@/components/Filters/filter-constants';

export function filterExcludedColumns(columnIds: string[]): string[] {
  return columnIds.filter((id) => !EXCLUDED_FILTER_COLUMNS.includes(id as (typeof EXCLUDED_FILTER_COLUMNS)[number]));
}

export function getTopLanguage(row: PrimaryAssetRecord): string | null {
  const metadata = row.most_recent_version?.root_node?.misc_metadata as {
    analyzable_sloc_by_type?: Record<string, number>;
  };

  const slocByType = metadata?.analyzable_sloc_by_type;
  if (!slocByType || Object.keys(slocByType).length === 0) {
    return null;
  }

  const topLanguage = Object.entries(slocByType).reduce(
    (max, [lang, count]) => (count > max[1] ? [lang, count] : max),
    ['', 0]
  );
  return topLanguage[0];
}

export function getColumnValue(row: PrimaryAssetRecord, columnId: string): string | null {
  // Special cases for columns that need custom value access
  switch (columnId) {
    case 'file_count':
      return row.most_recent_version?.root_node?.misc_metadata?.total_files?.toString() ?? null;
    case 'pages':
      // For PDFs, pages are in the root node's misc_metadata
      if (row.kind === 'FILE') {
        return (row.most_recent_version?.root_node?.misc_metadata as any)?.total_pages?.toString() ?? null;
      }
      // For codebases, pages are in the root node's misc_metadata
      return (row.most_recent_version?.root_node?.misc_metadata as any)?.total_pages?.toString() ?? null;
    case 'created_at': {
      const date = new Date(row.created_at);
      if (isNaN(date.getTime())) return null;
      return date.toLocaleDateString();
    }
    case 'updated_at': {
      const date = new Date(row.updated_at);
      if (isNaN(date.getTime())) return null;
      return date.toLocaleDateString();
    }
    case 'top_language':
      return getTopLanguage(row);
    // Add more special cases here as needed
    default:
      return row[columnId as keyof PrimaryAssetRecord]?.toString() ?? null;
  }
}

export function filterExcludedStatuses(statuses: VersionStatusType[]): VersionStatusType[] {
  return statuses.filter((status) => !EXCLUDED_STATUSES.includes(status as any));
}
