import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import remarkEmoji from 'remark-emoji';
import remarkGfm from 'remark-gfm';

interface RenderMarkdownProps {
  content: any;
}

const RenderMarkdown: React.FC<RenderMarkdownProps> = ({ content }) => {
  const [copied, setCopied] = useState<Record<string, boolean>>({});

  const handleCopyCode = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setCopied((prevCopied) => ({ ...prevCopied, [text]: true }));
    setTimeout(() => setCopied((prevCopied) => ({ ...prevCopied, [text]: false })), 2000); // Reset after 2 seconds
  };

  return (
    <ReactMarkdown
      className="overflow-wrap whitespace-pre-wrap break-words leading-relaxed text-foreground"
      remarkPlugins={[remarkGfm, remarkEmoji]}
      components={{
        h1: ({ node, ...props }) => <h1 className="text-3xl font-bold" {...props} />,
        h2: ({ node, ...props }) => <h2 className="text-2xl font-bold" {...props} />,
        h3: ({ node, ...props }) => <h3 className="text-xl font-bold" {...props} />,
        h4: ({ node, ...props }) => <h4 className="text-lg font-bold" {...props} />,
        h5: ({ node, ...props }) => <h5 className="text-base font-bold" {...props} />,
        h6: ({ node, ...props }) => <h6 className="text-sm font-bold" {...props} />,
        ul: ({ node, ...props }) => {
          return <ul className="list-disc whitespace-normal py-2 pl-5" {...props} />;
        },
        ol: ({ node, ...props }) => {
          return <ol className="list-outside list-decimal whitespace-normal py-2 pl-5" {...props} />;
        },
        li: ({ node, ...props }) => {
          return <li className="mb-1 pl-2" {...props} />;
        },
        a: ({ node, ...props }) => {
          return (
            <a className="group relative inline-block text-blue-500 hover:text-blue-700" target="_blank" {...props}>
              <span className="relative z-10">{props.children}</span>
              <span className="absolute inset-0 -mx-1 rounded bg-blue-100 opacity-0 transition-opacity group-hover:opacity-100"></span>
            </a>
          );
        },
        blockquote: ({ node, ...props }) => {
          return <blockquote className="whitespace-pre-wrap border-l-4 border-neutral-300 pl-4 italic" {...props} />;
        },
        table: ({ node, ...props }) => {
          return <table className="w-full border-collapse text-left" {...props} />;
        },
        thead: ({ node, ...props }) => {
          return <thead className="bg-neutral-100" {...props} />;
        },
        th: ({ node, ...props }) => {
          return <th className="p-2" {...props} />;
        },
        tbody: ({ node, ...props }) => {
          return <tbody className="divide-y divide-neutral-300" {...props} />;
        },
        tr: ({ node, ...props }) => {
          return <tr className="divide-x divide-neutral-300" {...props} />;
        },
        td: ({ node, ...props }) => {
          return <td className="p-2" {...props} />;
        },
        img: ({ node, ...props }) => {
          // eslint-disable-next-line jsx-a11y/alt-text
          return <img className="w-full" style={{ maxWidth: '100%' }} {...props} />;
        },
        code: ({ node, className, children, ref, ...props }) => {
          const text = children?.toString() || '';
          const hasNewLines = text.includes('\n');

          // Check for a language class
          const match = /language-(\w+)/.exec(className || '');
          const language = match ? match[1] : '';

          if (hasNewLines) {
            // This is for block code
            return (
              <div>
                <div className="my-3 -mb-2 flex items-center justify-between rounded-t-md bg-neutral-950/80 py-2.5 pe-3 ps-2 text-xs text-muted-foreground">
                  <div> {language}</div>
                  <button
                    onClick={() => handleCopyCode(text)}
                    className="flex items-center focus:outline-none focus-visible:rounded-sm focus-visible:ring-2 focus-visible:ring-blue-500/90"
                  >
                    {copied[text] ? (
                      <>
                        <CheckIcon className="mr-1.5 h-4 w-4" />
                        Copied
                      </>
                    ) : (
                      <>
                        <ClipboardIcon className="mr-1.5 h-4 w-4" />
                        Copy code
                      </>
                    )}
                  </button>
                </div>
                <SyntaxHighlighter
                  {...props}
                  PreTag="div"
                  language={language}
                  style={vscDarkPlus as any}
                  className="rounded-b-md"
                >
                  {children?.toString() || ''}
                </SyntaxHighlighter>
              </div>
            );
          } else {
            // This is for inline code
            return (
              <code
                {...props}
                className={`${className} mx-0.5 rounded border border-neutral-500/10 bg-neutral-600/5 px-1 py-0.5 text-smaller text-foreground`}
              >
                {children}
              </code>
            );
          }
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default RenderMarkdown;
