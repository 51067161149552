import { createFileRoute } from '@tanstack/react-router';
import { track } from '@vercel/analytics';
import { Files, Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';

import { useCreateDocumentMutation, useGetContentQuery, useUpdateContentMutation } from '@/api/api';
import { EDITOR_ACTIONS_CONTAINER_ID } from '@/components/BlockEditor/BlockEditor';
import { ChatProvider } from '@/components/Chat/ChatContext';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import { PageContent } from '@/components/Pages/PageContent/PageContent';
import { PageSidebar } from '@/components/Pages/PageSidebar/PageSidebar';
import { SidebarTabType } from '@/components/Pages/SidebarTabType';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { TabProvider } from '@/contexts/TabContext';
import { TunerProvider } from '@/contexts/TunerContext';
import { useDriverTemplate } from '@/hooks/useDriverTemplate';
import { useOrgUrlParams } from '@/hooks/useOrgUrlParams';
import { transformLibraryContent } from '@/utils/formatEditorContentUtil';

export const Route = createFileRoute('/$organizationName/template/$contentId')({
  component: ProvidedTemplatePage,
});

function TemplatePage() {
  const { contentId } = Route.useParams();

  const { isDriverTemplate, driverTemplate } = useDriverTemplate();
  const {
    data,
    isLoading: _loading,
    isError: _error,
  } = useGetContentQuery({ node_id: contentId }, { skip: isDriverTemplate });
  const template = data?.results[0];
  const parsedContent = transformLibraryContent(template);
  const name = isDriverTemplate ? driverTemplate?.display_name : parsedContent?.name;

  const [createDocument] = useCreateDocumentMutation();
  const [updateContent] = useUpdateContentMutation();
  const [isCreatingPage, setIsCreatingPage] = useState(false);
  const { toast } = useToast();
  const navigate = Route.useNavigate();
  const { organizationName } = useOrgUrlParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
  useEffect(() => {
    document.title = name || 'Template';
  }, [name]);

  const record = isDriverTemplate ? driverTemplate : template;

  const createPage = () => {
    if (!record) {
      return;
    }
    let nodeId: string = '';
    setIsCreatingPage(true);
    createDocument()
      .then((res) => {
        if (res.error) {
          throw new Error('Failed to create page');
        }
        nodeId = res.data.node_id;
        return updateContent({
          contentId: nodeId,
          content: record.content,
          name: `${res.data.node.version.primary_asset.display_name}`,
        });
      })
      .then((res) => {
        if (res.error) {
          throw new Error('Failed to create page');
        }
        track('create_page', {
          organizationName: organizationName,
          actionLocation: 'template_banner',
          creationSource: 'template',
        });
        navigate({
          to: `/$organizationName/page/$pageId`,
          params: { organizationName: organizationName, pageId: nodeId },
        });
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Failed to create page',
          variant: 'destructive',
        });
      })
      .finally(() => {
        setIsCreatingPage(false);
      });
  };

  return (
    <>
      <DefaultLayout noContentPadding navbarTitle={name}>
        <FlexiblePanelLayout
          isRightPanelOpen={rightSidebarOpen}
          onRightPanelOpenChange={setRightSidebarOpen}
          pageType="page"
          mainPanelContent={
            <div className="relative">
              <div className="sticky -top-4 z-50 -mt-4 mb-4 w-[calc(100%-100px)] translate-x-[50px] bg-background pt-2">
                <div className="flex items-center justify-center gap-4 rounded border border-border/50 bg-amber-50 px-4 py-2 text-center dark:bg-yellow-600/15">
                  <span className="text-sm font-medium tracking-wide text-yellow-700 dark:text-yellow-600">
                    {`You're viewing a template`}
                  </span>
                  <Button variant="default" size="xs" onClick={createPage} className="h-auto py-1.5">
                    {isCreatingPage ? <Loader2 className="mr-2 h-4 w-4" /> : <Files className="mr-2 h-4 w-4" />}
                    Create page from template
                  </Button>
                </div>
              </div>
              <PageContent />
              <div id={EDITOR_ACTIONS_CONTAINER_ID} className="absolute right-[-10px] top-[2px]" />
            </div>
          }
          rightPanelContent={
            <PageSidebar pageId={contentId} sidebarOpen={rightSidebarOpen} setSidebarOpen={setRightSidebarOpen} />
          }
          rightPanelWidth={300}
        />
      </DefaultLayout>
    </>
  );
}

function ProvidedTemplatePage() {
  const { contentId } = Route.useParams();

  return (
    <TunerProvider>
      <ChatProvider pageId={contentId}>
        <TabProvider<SidebarTabType> defaultTab="properties">
          <TemplatePage />
        </TabProvider>
      </ChatProvider>
    </TunerProvider>
  );
}
