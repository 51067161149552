import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Column, Table } from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { SupportedRouteApi } from '@/types/route-types';

import { getColumnValue } from '../BaseTable/utils/table-utils';

interface ActiveFiltersProps<TData, TValue> {
  column?: Column<TData, TValue>;
  table: Table<TData>;
  title?: string | ReactNode;
  routeApi: SupportedRouteApi;
  columns: ColumnDef<TData>[];
}

export function ActiveFilters<TData extends PrimaryAssetRecord, TValue>({
  column,
  table,
  routeApi,
  columns,
}: ActiveFiltersProps<TData, TValue>) {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [persistedValues, setPersistedValues] = useState<string[]>([]);
  const navigate = routeApi.useNavigate();
  const query = routeApi.useSearch();

  // Initialize persisted values from URL if available
  useEffect(() => {
    if (!column) return;
    const columnFilters = query.columnFilters as Array<{ id: string; value: any }>;
    const columnFilter = columnFilters?.find((filter) => filter.id === column.id);
    if (columnFilter?.value) {
      const urlValues = Array.isArray(columnFilter.value) ? columnFilter.value : [columnFilter.value];
      setPersistedValues(urlValues);
    }
  }, [query.columnFilters, column]);

  if (!column) return null;

  const columnId = column.id;
  const columnDef = columns.find((col) => (col.id || (col as any).accessorKey) === columnId);
  if (!columnDef) return null;

  const columnTitle =
    columnDef.header && typeof columnDef.header === 'function'
      ? (columnDef as any).header({ column }).props.title
      : columnId.replace(/_/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase());

  const filterValue = column.getFilterValue();
  const currentValues = Array.isArray(filterValue) ? filterValue : filterValue ? [filterValue] : [];
  const count = currentValues.length;

  const allValues = table
    .getRowModel()
    .rows.map((row) => getColumnValue(row.original as PrimaryAssetRecord, columnId))
    .filter((value: string | null): value is string => value !== null && value !== '' && value !== '-');

  if (allValues.length > 0 && persistedValues.length === 0) {
    setPersistedValues(Array.from(new Set(allValues)).sort());
  }

  const selectedValues = new Map<string, string>(currentValues.map((value) => [String(value), String(value)]));

  const uniqueValues = Array.from(new Set([...persistedValues, ...allValues])).sort();

  const filteredValues = inputValue
    ? uniqueValues.filter((value) => value.toLowerCase().includes(inputValue.toLowerCase()))
    : uniqueValues;

  const handleFilterChange = (values: string[]) => {
    column.setFilterValue(values.length ? values : undefined);
    navigate({
      search: (prev) => ({
        ...prev,
        [columnId]: values.length ? values : undefined,
      }),
      replace: true,
    });
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className={cn('h-8 gap-2 px-3', open && 'bg-accent text-accent-foreground')}>
          {columnTitle}
          {count > 0 && (
            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
              {count}
            </span>
          )}
          {open ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput
            placeholder={`Filter ${columnTitle}...`}
            className="border-none ps-0 focus:ring-0"
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            <CommandGroup>
              {filteredValues.length === 0 ? (
                <CommandEmpty>No results found</CommandEmpty>
              ) : (
                <>
                  {Array.from(selectedValues.values())
                    .filter((value) => !inputValue || value.toLowerCase() === inputValue.toLowerCase())
                    .map((value) => (
                      <CommandItem
                        key={value}
                        className="w-full"
                        onSelect={() => {
                          selectedValues.delete(value);
                          const filterValues = Array.from(selectedValues.values());
                          handleFilterChange(filterValues);
                        }}
                      >
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            'bg-primary text-primary-foreground'
                          )}
                        >
                          <CheckIcon className="h-4 w-4" />
                        </div>
                        <span className="flex-1 truncate text-left">{value}</span>
                      </CommandItem>
                    ))}
                  {selectedValues.size > 0 && filteredValues.some((value) => !selectedValues.has(value)) && (
                    <CommandSeparator className="my-1" />
                  )}
                  {filteredValues
                    .filter((value) => !selectedValues.has(value))
                    .map((value) => (
                      <CommandItem
                        key={value}
                        className="w-full"
                        onSelect={() => {
                          selectedValues.set(value, value);
                          const filterValues = Array.from(selectedValues.values());
                          handleFilterChange(filterValues);
                        }}
                      >
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <CheckIcon className="h-4 w-4" />
                        </div>
                        <span className="flex-1 truncate text-left">{value}</span>
                      </CommandItem>
                    ))}
                </>
              )}
            </CommandGroup>
          </CommandList>
          {count > 0 && (
            <CommandItem
              onSelect={() => {
                handleFilterChange([]);
                setOpen(false);
              }}
              className="justify-center border-t text-center"
            >
              Clear filters
            </CommandItem>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
