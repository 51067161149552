import React, { ReactNode, useEffect } from 'react';

import { ExpandCollapseButton } from './ExpandCollapseButton';
import { LeftPanel } from './LeftPanel';
import { MainPanel } from './MainPanel';
import { RightPanel } from './RightPanel';
import { usePanelLayout } from './hooks/usePanelLayout';

interface FlexiblePanelLayoutProps {
  mainPanelContent: ReactNode;
  leftPanelContent?: ReactNode;
  rightPanelContent?: ReactNode;
  leftPanelHeader?: ReactNode;
  rightPanelHeader?: ReactNode;
  leftPanelWidth?: number;
  rightPanelWidth?: number;
  pageType: string;
  isRightPanelOpen?: boolean;
  isLeftPanelOpen?: boolean;
  showOnlyHeaders?: boolean;
  mainPanelHeader?: ReactNode;
  onRightPanelOpenChange?: (isOpen: boolean) => void;
  leftPanelContentClassName?: string;
  rightPanelContentClassName?: string;
  showRightPanelHeaderDivider?: boolean;
  hideLeftPanel?: boolean;
  hideRightPanel?: boolean;
  rightPanelClassName?: string;
  rightPanelHeaderClassName?: string;
  headerClassList?: string[];
}

const FlexiblePanelLayout: React.FC<FlexiblePanelLayoutProps> = ({
  mainPanelContent,
  leftPanelContent,
  rightPanelContent,
  leftPanelHeader,
  rightPanelHeader,
  leftPanelWidth = 240,
  rightPanelWidth = 400,
  pageType,
  isRightPanelOpen = true,
  isLeftPanelOpen = true,
  showOnlyHeaders = false,
  mainPanelHeader,
  onRightPanelOpenChange,
  leftPanelContentClassName,
  rightPanelClassName,
  rightPanelContentClassName,
  showRightPanelHeaderDivider = true,
  hideLeftPanel = false,
  hideRightPanel = false,
  rightPanelHeaderClassName,
  headerClassList,
}) => {
  const [layout, updateLayout] = usePanelLayout(
    pageType,
    leftPanelWidth,
    rightPanelWidth,
    isRightPanelOpen,
    isLeftPanelOpen,
    leftPanelWidth,
    rightPanelWidth
  );

  useEffect(() => {
    updateLayout({
      isRightPanelOpen: isRightPanelOpen,
      isLeftPanelOpen: isLeftPanelOpen,
    });
  }, [isRightPanelOpen, isLeftPanelOpen, updateLayout]);

  return (
    <div className="relative flex h-screen">
      {!hideLeftPanel && (
        <LeftPanel
          content={leftPanelContent}
          header={leftPanelHeader}
          headerClassList={headerClassList}
          showOnlyHeaders={showOnlyHeaders}
          layout={{
            LeftPanelWidth: layout.leftPanelWidth,
            isLeftPanelOpen: layout.isLeftPanelOpen,
          }}
          updateLayout={(updates) =>
            updateLayout({
              leftPanelWidth: updates.LeftPanelWidth,
            })
          }
          LeftPanelContentClassName={leftPanelContentClassName}
        />
      )}

      <MainPanel content={mainPanelContent} header={mainPanelHeader} />

      {!hideRightPanel && (
        <RightPanel
          className={rightPanelClassName}
          content={rightPanelContent}
          header={rightPanelHeader}
          showOnlyHeaders={showOnlyHeaders}
          layout={layout}
          updateLayout={updateLayout}
          rightPanelContentClassName={rightPanelContentClassName}
          showRightPanelHeaderDivider={showRightPanelHeaderDivider}
          headerClassName={rightPanelHeaderClassName}
        />
      )}

      {rightPanelContent && !showOnlyHeaders && !hideRightPanel && (
        <ExpandCollapseButton
          isOpen={layout.isRightPanelOpen}
          columnWidth={layout.rightPanelWidth}
          onToggle={() => {
            updateLayout({ isRightPanelOpen: !layout.isRightPanelOpen });
            onRightPanelOpenChange?.(!layout.isRightPanelOpen);
          }}
        />
      )}
    </div>
  );
};

export default FlexiblePanelLayout;
