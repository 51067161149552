import { Table } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { BaseTable } from '@/components/BaseTable/BaseTable';

import { DEFAULT_TEMPLATES_COLUMN_VISIBILITY } from './constants';
import { templateColumns } from './document-columns';
import { useDocumentsTable } from './useDocumentsTable';

interface TemplatesTableProps {
  toolbarContent?: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => ReactNode;
  defaultColumnVisibility?: Record<string, boolean>;
}

export function TemplatesTable({ toolbarContent, defaultColumnVisibility }: TemplatesTableProps) {
  const { table, data, isLoading } = useDocumentsTable({
    columns: templateColumns,
    kind: [PrimaryAsset.PAGE_TEMPLATE],
    initialState: {
      columnVisibility: defaultColumnVisibility || DEFAULT_TEMPLATES_COLUMN_VISIBILITY,
    },
  });

  return <BaseTable table={table} isLoading={isLoading} data={data} toolbarContent={toolbarContent} />;
}
