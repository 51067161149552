import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useState } from 'react';

import { useUploadPdfMutation } from '@/api/api';
import { useToast } from '@/components/ui/use-toast';
import { isFetchBaseQueryError } from '@/utils/errors';

// interface CustomUppyFile extends UppyFile<Record<string, unknown>, Record<string, unknown>> {
//   xhrUpload?: {
//     endpoint: string;
//     method?: string;
//     formData?: boolean;
//     fieldName?: string;
//   };
// }

const useUppyPdfUpload = () => {
  const [uploadPdf] = useUploadPdfMutation();
  const { toast } = useToast();

  const initializeUppy = () => {
    const uppyInstance = new Uppy({
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.pdf'],
      },
      autoProceed: false,
    });

    uppyInstance.on('file-added', (file) => {
      if (!file.name) {
        throw new Error('File name is required');
      }
    });

    uppyInstance.on('upload', async (uploadId, files) => {
      if (uppyInstance.getPlugin('XHRUpload')) return;

      try {
        // Get upload URLs for all files
        const uploadPromises = files.map(async (file) => {
          if (!file.name) {
            throw new Error('File name is required');
          }

          const mutationResponse = await uploadPdf({
            file_path: file.name,
          });
          const uploadUrl = mutationResponse.data?.upload_url;

          if (mutationResponse.error || !uploadUrl) {
            if (isFetchBaseQueryError(mutationResponse.error) && mutationResponse.error.data?.detail) {
              throw new Error(mutationResponse.error.data.detail);
            } else {
              throw new Error(`Failed to upload PDF: ${file.name}`);
            }
          }

          // Set the upload URL for each file
          uppyInstance.setFileState(file.id, {
            xhrUpload: {
              endpoint: uploadUrl,
              method: 'PUT',
              formData: false,
              fieldName: 'files',
            },
          });

          return uploadUrl;
        });

        // Wait for all upload URLs to be set
        await Promise.all(uploadPromises);

        // Configure the XHR plugin
        uppyInstance.use(XHRUpload, {
          method: 'PUT',
          endpoint: '', // This is required but will be overridden by individual file settings
          formData: false,
          fieldName: 'files',
          // Hack to get around this code change:
          // https://github.com/transloadit/uppy/pull/5651/files
          getResponseData: (xhr) => {
            return { url: xhr.responseURL };
          },
        });

        const result = await uppyInstance.upload();

        if (result?.successful) {
          // Handle success for each file
          result.successful.forEach((file) => {
            uppyInstance.emit('upload-success', file, {
              status: 200,
              body: {},
            });
          });
        } else {
          console.error('Failed to upload PDFs to S3.', result);
          throw new Error('Failed to upload PDFs to storage.');
        }
      } catch (e) {
        console.error(e);
        let msg = 'Failed to upload PDFs';
        if (e instanceof Error) {
          msg = e.message;
        }
        uppyInstance.info(msg, 'error', 5000);
        toast({
          title: 'Error',
          description: msg,
          variant: 'destructive',
        });
      }
    });

    return uppyInstance;
  };

  const [uppy] = useState<Uppy>(initializeUppy);

  return uppy;
};

export default useUppyPdfUpload;
