import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/logout')({
  component: LogoutPage,
});

function LogoutPage() {
  const { logout } = useAuth0();
  useEffect(() => {
    logout();
  }, [logout]);
  return <></>;
}
