import { useCallback, useEffect, useState } from 'react';

interface PanelLayout {
  leftPanelWidth: number;
  rightPanelWidth: number;
  isRightPanelOpen: boolean;
  isLeftPanelOpen: boolean;
}

export function usePanelLayout(
  pageType: string,
  initialLeftWidth: number,
  initialRightWidth: number,
  initialIsRightOpen: boolean,
  initialIsLeftOpen: boolean,
  suppliedLeftWidth: number,
  suppliedRightWidth: number
) {
  const [layout, setLayout] = useState<PanelLayout>(() => {
    const savedLayout = localStorage.getItem(`panelLayout_${pageType}`);
    if (savedLayout) {
      const parsedLayout = JSON.parse(savedLayout);
      return {
        leftPanelWidth:
          parsedLayout.leftPanelWidth !== initialLeftWidth ? parsedLayout.leftPanelWidth : suppliedLeftWidth,
        rightPanelWidth:
          parsedLayout.rightPanelWidth !== initialRightWidth ? parsedLayout.rightPanelWidth : suppliedRightWidth,
        isRightPanelOpen:
          parsedLayout.isRightPanelOpen !== undefined ? parsedLayout.isRightPanelOpen : initialIsRightOpen,
        isLeftPanelOpen: parsedLayout.isLeftPanelOpen !== undefined ? parsedLayout.isLeftPanelOpen : initialIsLeftOpen,
      };
    }
    return {
      leftPanelWidth: initialLeftWidth,
      rightPanelWidth: initialRightWidth,
      isRightPanelOpen: initialIsRightOpen,
      isLeftPanelOpen: initialIsLeftOpen,
    };
  });

  useEffect(() => {
    localStorage.setItem(`panelLayout_${pageType}`, JSON.stringify(layout));
  }, [layout, pageType]);

  const updateLayout = useCallback(
    (updates: Partial<PanelLayout>) => {
      setLayout((prevLayout) => ({
        ...prevLayout,
        ...updates,
      }));
    },
    [setLayout]
  );

  return [layout, updateLayout] as const;
}
