import { Check, File } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useCreateNewDocument } from '@/hooks/useCreateNewPage';

const EmptyStatePages: React.FC = () => {
  const { createNewDocument } = useCreateNewDocument();

  return (
    <div className="flex flex-col">
      <ol className="space-y-10">
        <li className="opacity-50">
          <div className="mb-4 flex flex-col items-start gap-y-2 md:flex-row">
            <div className="mr-4 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded bg-primary font-mono text-base text-background">
              <Check className="h-4 w-4" />
            </div>
            <div className="flex flex-col space-y-1">
              <h4 className="font-mono text-xl font-semibold text-primary">Add sources</h4>
              <div className="text text-primary/75">
                Sources are the building blocks of Driver, use them as a knowledge base to generate docs and reference
                in Pages.
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:pl-10"></div>
        </li>
        <li className="flex items-start">
          <div className="flex w-full flex-col items-start gap-y-2 md:flex-row">
            <div className="mr-4 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded bg-primary font-mono text-base text-background">
              2
            </div>
            <div className="flex w-full flex-col space-y-4">
              <div className="space-y-1">
                <h4 className="font-mono text-xl font-semibold text-primary">Get answers</h4>
                <div className="text text-primary/75">
                  Dive into tech docs about a codebase or write your own Pages.
                  <br />
                  Reference any source you add to Driver within a Page to automatically generate content.
                </div>
              </div>
              <div className="flex w-full flex-col gap-4 md:flex-row">
                <Card className="w-full rounded-lg p-4">
                  <div className="mb-4 flex flex-col">
                    <File className="mb-2 h-6 w-6" />
                    <h5 className="font-mono text-base font-semibold">Page</h5>
                  </div>
                  <ul className="mb-4 list-[square] space-y-2 pl-5 text-sm text-primary/75">
                    <li>Generate content automatically</li>
                    <li>Reference any source in Driver</li>
                    <li>Highlight and edit content with AI</li>
                  </ul>
                  <Button
                    variant="default"
                    className="w-full"
                    onClick={() =>
                      createNewDocument({
                        actionLocation: 'help',
                        creationSource: 'blank',
                      })
                    }
                  >
                    Start writing
                  </Button>
                </Card>
                <Card className="w-full rounded-lg bg-primary p-4 text-primary-foreground">
                  <div className="mb-4 mt-3 flex flex-col">
                    <h5 className="font-mono text-base font-semibold">How can I use Pages?</h5>
                  </div>
                  <ul className="list-[square] space-y-2 pl-5 text-sm text-primary-foreground/75">
                    <li>Document API endpoints</li>
                    <li>Create onboarding guides</li>
                    <li>Write technical specifications</li>
                    <li>Generate project documentation</li>
                    <li>and more...</li>
                  </ul>
                </Card>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default EmptyStatePages;
