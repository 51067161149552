import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError & { data: { detail: string } } => {
  return (
    typeof error === 'object' && error != null && 'status' in error && typeof (error as any).data?.detail === 'string'
  );
};

export const isSerializedError = (error: unknown): error is SerializedError => {
  return typeof error === 'object' && error != null && 'message' in error;
};
