import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { ChevronLeft, ChevronRight, FileIcon, Plus, Zap } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';

import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset } from '@/api/types/node';
import ActionButton from '@/components/ActionButton';
import AddSourceDialog from '@/components/AddSourceDialog';
import DocumentPreview from '@/components/DocumentPreview';
import SkeletonLoader from '@/components/DocumentPreview/SkeletonLoader';
import EmptyStatePages from '@/components/Guides/EmptyStatePages';
import GettingStarted from '@/components/Guides/GettingStarted';
import DefaultLayout from '@/components/LayoutDefault';
import { useGetHasLibraryData } from '@/components/LibraryTable/useGetHasLibraryData';
import { useCreateTemplate } from '@/components/Templates/useCreateTemplate';
import Codebases from '@/components/TrendingContent/Codebases';
import Pages from '@/components/TrendingContent/Pages';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useOrg } from '@/contexts/OrgContext';
import { useCreateNewDocument } from '@/hooks/useCreateNewPage';
import useScrollMask from '@/hooks/useScrollMask';

export const Route = createFileRoute('/$organizationName/')({
  component: Home,
});

function Home() {
  const { organizationId, organizationName, organizationDisplayName } = useOrg();
  const { scrollContainerRef, maskStyle, updateMaskStyle } = useScrollMask();
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Home`;
  }, [organizationDisplayName]);

  const [selectedTrendingOption, setSelectedTrendingOption] = useState<string>(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('selectedTrendingOption') || 'codebases';
    }
    return 'codebases';
  });

  const maxRecentDocumentsToShow = 7;

  const recentDocumentsParams = {
    text: '',
    limit: maxRecentDocumentsToShow,
    offset: 0,
    kind: [PrimaryAsset.PAGE],
    sort_direction: 'DESC' as const,
    sort_by: 'updated_at',
    tag_ids: [],
  };

  const { createNewDocument } = useCreateNewDocument();
  const { createTemplate } = useCreateTemplate();

  const { data, isLoading, isFetching, isError } = useGetPrimaryAssetQuery(recentDocumentsParams);

  const { hasRecords, isLoading: hasRecordsLoading, refetch: refetchHasRecords } = useGetHasLibraryData();

  const showLoading = isLoading || isFetching || hasRecordsLoading;
  const libraryError = isError;

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1); // -1 to account for potential rounding errors
    }
    updateMaskStyle();
  }, [scrollContainerRef, updateMaskStyle]);

  useEffect(() => {
    handleScroll(); // Initial check
  }, [data, handleScroll, showLoading]);

  useEffect(() => {
    localStorage.setItem('selectedTrendingOption', selectedTrendingOption);
  }, [selectedTrendingOption]);

  if (!organizationId) {
    return null;
  }

  const handleDialogOpenChanged = (isOpen: boolean) => {
    setIsDialogOpen(isOpen);

    // Refresh assets when the dialog is closed.
    if (!isOpen) {
      refetchHasRecords();

      // Redirect to library page.
      navigate({ to: `/${organizationName}/library` });
    }
  };

  return (
    <>
      <DefaultLayout navbarTitle="Home">
        <div className="">
          {organizationId && organizationName && (
            <div className="w-full space-y-8">
              {libraryError && (
                <Alert variant="destructive">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>There was an error loading the library.</AlertDescription>
                </Alert>
              )}
              {isDialogOpen || (!showLoading && !hasRecords) ? (
                <div className="flex min-h-[90vh] flex-col items-start justify-center">
                  <div className="mb-10 flex flex-col space-y-2">
                    <h1 className="text-2xl font-semibold">Get started with Driver</h1>
                  </div>
                  <GettingStarted onDialogOpenChanged={handleDialogOpenChanged} />
                </div>
              ) : !showLoading && (!data || data.results.length === 0) ? (
                <div className="flex min-h-[90vh] flex-col items-start justify-center">
                  <div className="mb-10 flex flex-col space-y-2">
                    <h1 className="text-2xl font-semibold">Get started with Driver</h1>
                  </div>
                  <EmptyStatePages />
                </div>
              ) : (
                <>
                  <div className="flex w-full flex-wrap gap-2 md:gap-4">
                    <ActionButton
                      icon={FileIcon}
                      title="Create new page"
                      description="Cutting edge documentation tools"
                      onClick={() =>
                        createNewDocument({
                          actionLocation: 'home',
                          creationSource: 'blank',
                        })
                      }
                    />
                    <ActionButton
                      icon={Zap}
                      title="Create new template"
                      description="Supercharge your creation flow"
                      onClick={() => createTemplate()}
                    />
                    <ActionButton
                      icon={Plus}
                      title="Add new source"
                      description="Connect codebases, PDFs, and more"
                      onClick={() => setIsAddSourceDialogOpen(true)}
                    />
                    <AddSourceDialog
                      isOpen={isAddSourceDialogOpen}
                      onClose={() => setIsAddSourceDialogOpen(false)}
                      organizationName={organizationName}
                    />
                  </div>

                  <div className="space-y-4">
                    <div className="text-lg font-medium text-foreground">Recent pages</div>
                    <div className="group/recent-docs relative">
                      {showLeftButton && (
                        <button
                          onClick={() => scroll('left')}
                          className="absolute left-0 top-1/2 z-10 -translate-y-3/4 rounded-full border border-border bg-background p-2 text-muted-foreground opacity-0 shadow-md transition-opacity hover:text-foreground active:bg-muted group-hover/recent-docs:opacity-100"
                        >
                          <ChevronLeft className="h-5 w-5" />
                        </button>
                      )}
                      <div
                        ref={scrollContainerRef}
                        className="no-scrollbar flex space-x-4 overflow-x-scroll"
                        style={maskStyle}
                        onScroll={handleScroll}
                      >
                        {isLoading
                          ? [...Array(4)].map((_, index) => <SkeletonLoader key={index} />)
                          : data?.results
                              .slice(0, maxRecentDocumentsToShow)
                              .map((doc) => (
                                <DocumentPreview
                                  key={doc.id}
                                  nodeId={doc.most_recent_version?.root_node?.id || ''}
                                  title={doc.display_name}
                                  lastEdited={doc.updated_at}
                                  link={`/${organizationName}/page/${doc.most_recent_version?.root_node?.id}`}
                                />
                              ))}
                      </div>
                      {showRightButton && (
                        <button
                          onClick={() => scroll('right')}
                          className="absolute right-0 top-1/2 z-10 -translate-y-3/4 rounded-full border border-border bg-background p-2 text-muted-foreground opacity-0 shadow-md transition-opacity hover:text-foreground active:bg-muted group-hover/recent-docs:opacity-100"
                        >
                          <ChevronRight className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center space-x-2">
                      <div className="text-lg font-medium text-foreground">Trending</div>
                      <Select value={selectedTrendingOption} onValueChange={setSelectedTrendingOption}>
                        <SelectTrigger className="h-auto w-auto gap-1.5 border-none py-1 pe-1 ps-2 text-muted-foreground shadow-none hover:bg-muted focus:outline-none focus:ring-0">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="codebases">Codebases</SelectItem>
                          <SelectItem value="pages">Pages</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    {selectedTrendingOption === 'codebases' && <Codebases />}
                    {selectedTrendingOption === 'pages' && <Pages />}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </DefaultLayout>
    </>
  );
}

export default Home;
