import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDeleteDocumentMutation, useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset } from '@/api/types/node';
import SkeletonLoader from '@/components/DocumentPreview/SkeletonLoader';
import { useToast } from '@/components/ui/use-toast';
import { useOrg } from '@/contexts/OrgContext';
import useScrollMask from '@/hooks/useScrollMask';

import { PreviewDocs } from './PreviewDocs';

export const RecentlyModified = () => {
  const { organizationName } = useOrg();
  const { scrollContainerRef, maskStyle, updateMaskStyle } = useScrollMask();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const { toast } = useToast();
  const [deleteDocument] = useDeleteDocumentMutation();

  const maxRecentDocumentsToShow = 7;

  // Get documents from the last 30 days
  const thirtyDaysAgo = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString();
  }, []);

  // Use a dedicated query for recently modified documents with memoized params
  const queryParams = useMemo(
    () => ({
      limit: maxRecentDocumentsToShow,
      offset: 0,
      kind: [PrimaryAsset.PAGE],
      sort_direction: 'DESC' as const,
      sort_by: 'updated_at',
      updated_at__gte: thirtyDaysAgo,
    }),
    [thirtyDaysAgo]
  );

  const { data, isLoading } = useGetPrimaryAssetQuery(queryParams);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1);
    }
    updateMaskStyle();
  }, [scrollContainerRef, updateMaskStyle]);

  const handleDelete = async (id: string) => {
    try {
      await deleteDocument({ id }).unwrap();
      toast({
        title: 'Success',
        description: 'Page deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete page',
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    handleScroll();
  }, [data, handleScroll, isLoading]);

  // Return null if there's no data and not loading
  if (!data?.results?.length && !isLoading) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="text-lg font-medium text-foreground">Recently Modified</div>
      <div className="group/recent-docs relative">
        {showLeftButton && (
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 z-10 -translate-y-3/4 rounded-full border border-border bg-background p-2 text-muted-foreground opacity-0 shadow-md transition-opacity hover:text-foreground active:bg-muted group-hover/recent-docs:opacity-100"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
        )}
        <div
          ref={scrollContainerRef}
          className="no-scrollbar flex space-x-4 overflow-x-scroll"
          style={maskStyle}
          onScroll={handleScroll}
        >
          {isLoading
            ? [...Array(4)].map((_, index) => <SkeletonLoader key={index} />)
            : data?.results
                .slice(0, maxRecentDocumentsToShow)
                .map((doc) => (
                  <PreviewDocs
                    key={doc.id}
                    nodeId={doc.most_recent_version?.root_node?.id || ''}
                    title={doc.display_name}
                    lastEdited={doc.updated_at}
                    link={`/${organizationName}/page/${doc.most_recent_version?.root_node?.id}`}
                    creator={doc.most_recent_version?.creator}
                    onDelete={() => handleDelete(doc.id)}
                  />
                ))}
        </div>
        {showRightButton && (
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 z-10 -translate-y-3/4 rounded-full border border-border bg-background p-2 text-muted-foreground opacity-0 shadow-md transition-opacity hover:text-foreground active:bg-muted group-hover/recent-docs:opacity-100"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};
