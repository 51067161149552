import { ListEnd, Loader } from 'lucide-react';
import React from 'react';

import { PrimaryAssetRecord, VersionStatus, VersionStatusType } from '@/api/types/node';
import { Button } from '@/components/ui/button';

interface CellStatusProps {
  row: { original: PrimaryAssetRecord };
}

export const CellStatus: React.FC<CellStatusProps> = ({ row }) => {
  const status = row.original.most_recent_version?.status;

  //  // generating status is also shown for pdfs
  //  // show nothing for non-codebase nodes?
  // if (row.original.kind !== PrimaryAssetType.CODEBASE) {
  //   return <></>;
  // }

  switch (status as VersionStatusType) {
    case VersionStatus.CONNECTED:
      return (
        <div className="hidden sm:block">
          <Button
            to="."
            search={(prev: any) => ({ ...prev, generateRowIds: [row.original.id] })}
            className="ml-2"
            size="sm"
          >
            Generate Docs <ListEnd className="ml-2 h-4 w-4" />
          </Button>
        </div>
      );
    case VersionStatus.GENERATING:
      return (
        <Button variant="outline" size="sm" disabled>
          Generating... <Loader className="ml-2 h-4 w-4 animate-spin" />
        </Button>
      );
    case VersionStatus.CONNECTING:
      return (
        <Button variant="outline" size="sm" disabled>
          Connecting... <Loader className="ml-2 h-4 w-4 animate-spin" />
        </Button>
      );
    default:
      return <></>;
  }
};
