import { User } from '@auth0/auth0-react';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';

export type PartialUser = Pick<User, 'email' | 'name' | 'picture'>;

const getInitial = (name: string | undefined) => {
  return name ? name.charAt(0).toUpperCase() : '?';
};

export interface UserAvatarProps {
  user: PartialUser;
  className?: string;
  fallbackClassName?: string;
  imageClassName?: string;
  size?: number;
}

export function UserAvatar({ user, className, size = 36, fallbackClassName, imageClassName }: UserAvatarProps) {
  return (
    // Use style since dynamic widths and heights are not supported in Tailwind
    <Avatar className={cn('inline-block', className)} style={{ width: size, height: size }}>
      <AvatarImage
        src={user.picture}
        width={size}
        height={size}
        className={imageClassName}
        style={{ width: size, height: size }}
      />
      <AvatarFallback className={fallbackClassName}>{getInitial(user?.name || user?.email)}</AvatarFallback>
    </Avatar>
  );
}
