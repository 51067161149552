import { PlusIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { Tags } from 'lucide-react';
import { useState } from 'react';

import { useCreateTagMutation, useGetTagsQuery } from '@/api/api';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';

import { ChooseTagColor, TagColorOption, tailwindClassToHex } from './ChooseTagColor';

interface TagsFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string | React.ReactNode;
}

export function TagsFilter<TData, TValue>({ column, title }: TagsFilterProps<TData, TValue>) {
  const { data: tags } = useGetTagsQuery({ type: 'tag', limit: 1000, offset: 0 });

  const selectedTagIds = (column?.getFilterValue() as string[]) || [];
  const selectedValues = new Map<string, any>(
    tags?.results.filter((tag) => selectedTagIds.includes(tag.id)).map((tag) => [tag.name, tag])
  );
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const [createTag, { isLoading: tagsLoading }] = useCreateTagMutation();

  const makeTag = (tag: TagColorOption) => {
    if (inputValue && inputValue?.length === 0) return;
    const hexColor = tailwindClassToHex(tag.className);
    createTag({
      name: inputValue ?? '',
      hex_color: hexColor,
      type: 'tag',
    })
      .then((d) => {
        if (d.error) throw new Error();
        setOpen(false);
        setInputValue('');
        toast({
          title: 'Success',
          description: `Created tag: ${inputValue}`,
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Failed to create tag',
          variant: 'destructive',
        });
      })
      .finally(() => {
        setInputValue('');
      });
  };

  return (
    <Popover>
      <ChooseTagColor
        name={inputValue}
        open={open}
        setOpen={setOpen}
        setInputValue={setInputValue}
        callback={makeTag}
        isLoading={tagsLoading}
      />
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="relative flex h-8 w-8 items-center justify-center rounded-md bg-transparent p-0 text-sm font-medium hover:bg-accent hover:text-accent-foreground"
        >
          <Tags className="h-4 w-4" />
          {selectedValues?.size > 0 && (
            <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
              {selectedValues.size}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-56 p-0" align="start">
        <Command>
          <CommandInput
            placeholder="Filter tags..."
            className="border-none ps-0 focus:ring-0"
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            <CommandGroup>
              {Array.from(selectedValues.values()).map((option) => (
                <CommandItem
                  key={option.name}
                  className="w-full"
                  onSelect={() => {
                    selectedValues.delete(option.name);
                    const filterValues = Array.from(selectedValues.entries()).map(([name, value]) => value.id);
                    column?.setFilterValue(filterValues.length ? filterValues : undefined);
                  }}
                >
                  <div
                    className={cn(
                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                      'bg-primary text-primary-foreground'
                    )}
                  >
                    <CheckIcon className={cn('h-4 w-4')} />
                  </div>
                  <div className="flex items-center">
                    <span className={`mr-2 h-2 w-2 rounded-full`} style={{ background: option.hex_color }}></span>
                    <span className="flex-1 truncate text-left">{option.name}</span>
                  </div>
                </CommandItem>
              ))}
              {selectedValues.size > 0 && tags?.results?.some((option) => !selectedValues.has(option.name)) && (
                <CommandSeparator className="my-1" />
              )}
              {tags?.results
                ?.filter((option) => !selectedValues.has(option.name))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((option) => (
                  <CommandItem
                    key={option.name}
                    className="w-full"
                    onSelect={() => {
                      selectedValues.set(option.name, option);
                      const filterValues = Array.from(selectedValues.entries()).map(([name, value]) => value.id);
                      column?.setFilterValue(filterValues.length ? filterValues : undefined);
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <div className="flex items-center">
                      <span className={`mr-2 h-2 w-2 rounded-full`} style={{ background: option.hex_color }}></span>
                      <span className="flex-1 truncate text-left">{option.name}</span>
                    </div>
                  </CommandItem>
                ))}
            </CommandGroup>
            <CommandGroup className="sticky bottom-0">
              {inputValue.length > 0 && (
                <CommandItem className="-mb-1 bg-background data-[selected=true]:bg-background">
                  <Button
                    variant="secondary"
                    className="w-full justify-start"
                    leadingIcon={PlusIcon}
                    onClick={() => setOpen(true)}
                  >
                    <span className="truncate">Create new tag: {inputValue}</span>
                  </Button>
                </CommandItem>
              )}
            </CommandGroup>
          </CommandList>
          {inputValue.length === 0 && <CommandEmpty>No results</CommandEmpty>}

          {selectedValues.size > 0 && (
            <CommandItem
              onSelect={() => column?.setFilterValue(undefined)}
              className="justify-center border-t text-center"
            >
              Clear filters
            </CommandItem>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
