import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useLogout = () => {
  const { logout } = useAuth0();

  const appLogout = useCallback(
    (e?: { preventDefault?: () => void }) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      logout({ logoutParams: { returnTo: window.location.origin } });
    },
    [logout]
  );
  return appLogout;
};
