import React, { useEffect, useState } from 'react';

import { RoleName } from '@/api/types/organization';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';

interface InviteUsersDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onInvite: (emails: string[], role: RoleName) => void;
  isCreatingInvitations: boolean;
}

const InviteUsersDialog: React.FC<InviteUsersDialogProps> = ({
  isOpen,
  onOpenChange,
  onInvite,
  isCreatingInvitations,
}) => {
  const [inviteEmails, setInviteEmails] = useState('');
  const [inviteRole, setInviteRole] = useState<RoleName>('Editor');
  const [emailError, setEmailError] = useState<string | null>(null);

  const validateEmails = (emails: string) => {
    if (!emails) {
      setEmailError(null);
      return;
    }
    const emailList = emails.split(',').map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailList.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(', ')}`);
    } else {
      setEmailError(null);
    }
  };

  const handleInvite = () => {
    if (!emailError) {
      const emails = inviteEmails.split(',').map((email) => email.trim());
      onInvite(emails, inviteRole);
      setInviteEmails('');
      setInviteRole('Editor');
    }
  };

  useEffect(() => {
    validateEmails(inviteEmails);
  }, [inviteEmails]);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="secondary" size="sm" className="h-[38px]">
          Invite People
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Invite Users</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Enter the email addresses of the users you want to invite and select their role.
        </DialogDescription>
        <div className="space-y-4 py-4">
          <div>
            <Textarea
              placeholder="Comma-separated email addresses"
              value={inviteEmails}
              onChange={(e) => setInviteEmails(e.target.value)}
              className="min-h-[100px]"
            />
            {emailError && <p className="mt-1 text-sm text-red-500">{emailError}</p>}
          </div>
          <Select value={inviteRole} onValueChange={(value: RoleName) => setInviteRole(value)}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent className="z-100">
              <SelectItem value="Admin">Admin</SelectItem>
              <SelectItem value="Editor">Editor</SelectItem>
            </SelectContent>
          </Select>
          <Button
            onClick={handleInvite}
            className="w-full"
            disabled={!!emailError || inviteEmails.trim() === '' || isCreatingInvitations}
          >
            {isCreatingInvitations ? 'Sending...' : 'Send Invitations'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUsersDialog;
