import { ArrowUpRight, BookOpenIcon, Calendar, Heart, HelpCircle, Keyboard, Mail } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

import KeyboardShortcuts from '@/components/Guides/KeyboardShortcuts';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';

export function HelpMenu() {
  // const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const feedbackRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let prevWidth = 0;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const currentWidth = entry.contentRect.width;
        if ((prevWidth <= 31 && currentWidth > 31) || (prevWidth > 31 && currentWidth <= 31)) {
          setShowHelp(currentWidth > 31);
          setShowFeedback(currentWidth > 31);
        }
        prevWidth = currentWidth;
      }
    });

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }
    if (feedbackRef.current) {
      observer.observe(feedbackRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center px-0.5 pb-1">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            ref={triggerRef}
            variant="ghost"
            className="flex w-full items-center justify-start p-2 px-1.5 transition-all hover:bg-secondary-foreground/5"
          >
            <div className="flex h-7 w-7 items-center justify-center">
              <HelpCircle className="h-6 w-6 text-primary/80" strokeWidth={1.25} />
            </div>
            <div
              className={cn(
                'flex flex-grow flex-col overflow-hidden text-left transition-all duration-300 ease-in-out',
                showHelp ? 'ml-3 w-full opacity-100' : 'max-w-0 opacity-0'
              )}
            >
              <p className="truncate text-sm font-normal text-primary">Help</p>
            </div>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56" side="right" align="end">
          <DropdownMenuItem asChild>
            <a href="https://www.driver.ai/docs" target="_blank" rel="noopener noreferrer">
              <BookOpenIcon className="mr-3 h-4 w-4 text-muted-foreground" />
              How to use Driver
            </a>
          </DropdownMenuItem>
          {/* <DropdownMenuItem className="hover:cursor-pointer" onClick={() => setIsHelpDialogOpen(true)}>
            <HelpCircle className="mr-3 h-4 w-4 text-muted-foreground" />
            How to use Driver
          </DropdownMenuItem> */}
          <DropdownMenuItem className="hover:cursor-pointer" onClick={() => setIsKeyboardShortcutsOpen(true)}>
            <Keyboard className="mr-3 h-4 w-4 text-muted-foreground" />
            Keyboard shortcuts
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <a href="https://changelog.driver.ai" target="_blank" rel="noopener noreferrer">
              <Calendar className="mr-3 h-4 w-4 text-muted-foreground" />
              What&apos;s new
              <ArrowUpRight className="ml-auto h-3 w-3" />
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a href="mailto:support@driver.ai" target="_blank" rel="noopener noreferrer">
              <Mail className="mr-3 h-4 w-4 text-muted-foreground" />
              Contact
              <ArrowUpRight className="ml-auto h-3 w-3" />
            </a>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Button
        ref={feedbackRef}
        variant="ghost"
        className="flex w-full items-center justify-start p-2 px-1.5 transition-all hover:bg-secondary-foreground/5"
        onClick={() => {
          // Hack to get around the Userback "JavaScript API is not available on your plan" error.
          const userbackButton: HTMLElement | null = document.querySelector('.userback-button');
          if (userbackButton) {
            userbackButton.classList.add('!block');
            userbackButton.click();

            // Add event listener to the userback button to close it after it's clicked.
            const onClick = () => {
              userbackButton.classList.remove('!block');
              userbackButton.removeEventListener('click', onClick);
            };
            userbackButton.addEventListener('click', onClick);
          }
        }}
      >
        <div className="flex h-7 w-7 items-center justify-center">
          <Heart className="h-6 w-6 text-primary/80" strokeWidth={1.25} />
        </div>
        <div
          className={cn(
            'flex flex-grow flex-col overflow-hidden text-left transition-all duration-300 ease-in-out',
            showFeedback ? 'ml-3 w-full opacity-100' : 'max-w-0 opacity-0'
          )}
        >
          <p className="truncate text-sm font-normal text-primary">Feedback</p>
        </div>
      </Button>

      {/* <Dialog open={isHelpDialogOpen} onOpenChange={setIsHelpDialogOpen}>
        <DialogContent className="max-h-screen max-w-5xl overflow-y-scroll p-8">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl font-medium">How to use Driver</DialogTitle>
          </DialogHeader>
          <HelpWithDriver />
        </DialogContent>
      </Dialog> */}

      <Dialog open={isKeyboardShortcutsOpen} onOpenChange={setIsKeyboardShortcutsOpen}>
        <DialogContent className="max-h-screen max-w-xl overflow-y-hidden p-8 pr-5">
          <DialogHeader className="mb-1">
            <DialogTitle className="text-xl font-medium">Keyboard shortcuts</DialogTitle>
          </DialogHeader>
          <KeyboardShortcuts />
        </DialogContent>
      </Dialog>
    </div>
  );
}
