import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import DefaultLayout from '@/components/LayoutDefault';
import PdfDropzone from '@/components/UploadService/PdfDropzone';
import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/$organizationName/new/pdf')({
  component: NewPdfPage,
});

function NewPdfPage() {
  const { organizationDisplayName } = useOrg();
  useEffect(() => {
    document.title = `New - ${organizationDisplayName}`;
  }, [organizationDisplayName]);

  return (
    <>
      <DefaultLayout navbarTitle="New">
        <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center space-y-5 p-2">
          <div className="space-y-1 text-center">
            <h1 className="text-xl font-semibold text-primary">Upload a PDF</h1>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <PdfDropzone />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}
