import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useState } from 'react';

import { useUploadCodebaseMutation } from '@/api/api';
import { useToast } from '@/components/ui/use-toast';
import { isFetchBaseQueryError } from '@/utils/errors';

const useUppyCodebaseUpload = () => {
  const [getS3Url] = useUploadCodebaseMutation();
  // const [analyzeCodebase] = useAnalyzeCodebaseMutation();
  const { toast } = useToast();

  const initializeUppy = () => {
    const uppyInstance = new Uppy({
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.zip'],
      },
      autoProceed: false,
    });

    let fileName = '';
    // let fileSize = 0;
    uppyInstance.on('file-added', (file) => {
      if (!file.name) {
        throw new Error('File name is required');
      }
      if (!file.size) {
        throw new Error('File size is required');
      }
      fileName = file.name;
      // fileSize = file.size;
    });

    uppyInstance.on('upload', async (uploadId, files) => {
      if (uppyInstance.getPlugin('XHRUpload')) return;
      try {
        const mutationResponse = await getS3Url({
          file_path: fileName,
        });
        const uploadUrl = mutationResponse.data?.upload_url;
        if (mutationResponse.error || !uploadUrl) {
          if (isFetchBaseQueryError(mutationResponse.error) && mutationResponse.error.data?.detail) {
            throw new Error(mutationResponse.error.data.detail);
          } else {
            throw new Error('Failed to upload codebase');
          }
        }
        uppyInstance.use(XHRUpload, {
          method: 'PUT',
          endpoint: uploadUrl,
          formData: false,
          fieldName: 'files',
          // Hack to get around this code change:
          // https://github.com/transloadit/uppy/pull/5651/files
          getResponseData: (xhr) => {
            return { url: xhr.responseURL };
          },
        });
        const result = await uppyInstance.upload();

        if (result?.successful && files[0]) {
          uppyInstance.emit('upload-success', files[0], {
            status: 200,
            body: {},
          });
        } else {
          console.error('Failed to upload codebase to S3.', result);
          throw new Error('Failed to upload codebase to storage.');
        }
      } catch (e) {
        console.error(e);
        let msg = 'Failed to upload codebase';
        if (e instanceof Error) {
          msg = e.message;
        }
        uppyInstance.info(msg, 'error', 5000);
        toast({
          title: 'Error',
          description: msg,
          variant: 'destructive',
        });
      }
    });

    return uppyInstance;
  };

  const [uppy] = useState<Uppy>(initializeUppy);

  return uppy;
};

export default useUppyCodebaseUpload;
