import upperFirst from 'lodash/upperFirst';
import { MoreHorizontal, Trash2Icon } from 'lucide-react';
import { useState } from 'react';

import { useDeleteDocumentMutation } from '@/api/api';
import { PrimaryAsset, PrimaryAssetType } from '@/api/types/node';
import { DeleteDialog } from '@/components/DeleteDialog';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useToast } from '@/components/ui/use-toast';

interface CellContentActionsProps {
  id: string;
  primary_asset_type: PrimaryAssetType;
  content_name: string;
}

export const CellContentActions: React.FC<CellContentActionsProps> = ({ id, primary_asset_type, content_name }) => {
  const [deleteDocument, { isLoading }] = useDeleteDocumentMutation();
  const { toast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getItemType = (contentTypeName: PrimaryAssetType): string => {
    switch (contentTypeName) {
      case PrimaryAsset.PAGE:
        return 'page';
      case PrimaryAsset.FILE:
        return 'PDF';
      case PrimaryAsset.CODEBASE:
        return 'codebase';
      default:
        return 'item';
    }
  };

  const itemType = getItemType(primary_asset_type);

  const handleDelete = () => {
    deleteDocument({
      id: id,
    })
      .then((res) => {
        if (res.error) {
          throw new Error('Failed to delete item');
        }
        toast({
          title: `${upperFirst(itemType)} deleted`,
          description: (
            <span>
              The {itemType} <strong>{content_name}</strong> has been successfully deleted.
            </span>
          ),
          variant: 'default',
        });
      })
      .catch(() => {
        toast({
          title: `Error deleting ${itemType}`,
          description: 'Please try again.',
          variant: 'destructive',
        });
      })
      .finally(() => {
        setIsDialogOpen(false);
      });
  };

  return (
    <div className="p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="h-6 w-6 p-0"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()} // Prevent row click
          >
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
          <DropdownMenuItem
            onClick={() => setIsDialogOpen(true)}
            className="text-destructive focus:bg-destructive/10 focus:text-destructive active:bg-destructive/20"
          >
            <Trash2Icon className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        itemType={itemType}
        itemName={content_name}
        contentTypeName={primary_asset_type}
        onCancel={() => setIsDialogOpen(false)}
        onDelete={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
};
