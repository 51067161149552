import { PanelLeft } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface SidebarToggleProps {
  isOpen: boolean | undefined;
  setIsOpen?: (isOpen: boolean) => void;
}

export function SidebarToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
  return (
    <div className="px-1">
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Button
              onClick={() => setIsOpen?.(!isOpen)}
              className="h-8 w-8 rounded-md hover:bg-secondary-foreground/5"
              variant="ghost"
              size="icon"
            >
              <PanelLeft className="h-4 w-4 opacity-70" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right" sideOffset={16} style={{ zIndex: 100 }}>
            {isOpen ? 'Collapse sidebar' : 'Expand sidebar'}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
