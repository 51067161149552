import React from 'react';

interface DocumentPreviewSkeletonProps {
  className?: string;
}

const DocumentPreviewSkeleton: React.FC<DocumentPreviewSkeletonProps> = ({ className }) => {
  return (
    <div className={`flex max-w-64 flex-1 flex-col ${className}`}>
      <div className="w-full overflow-clip rounded-lg border border-border bg-muted px-6 pb-0 pt-6">
        <div className="flex h-40 flex-col space-y-1 rounded-t-md bg-card p-4 shadow-md dark:bg-background">
          {[...Array(14)].map((_, index) => (
            <div
              key={index}
              className="h-1 animate-pulse rounded bg-muted-foreground/5"
              style={{ width: `${Math.floor(Math.random() * 71) + 30}%` }}
            ></div>
          ))}
        </div>
      </div>
      <div className="mt-4 space-y-1.5">
        <div className="h-4 w-3/4 animate-pulse rounded bg-muted-foreground/10"></div>
        <div className="flex items-center gap-1">
          <div className="h-3 w-3 animate-pulse rounded-full bg-muted-foreground/10"></div>
          <div className="h-3 w-1/4 animate-pulse rounded bg-muted-foreground/10"></div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreviewSkeleton;
