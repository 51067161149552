import { Loader } from 'lucide-react';
import colors from 'tailwindcss/colors';

import { Command, CommandItem, CommandList } from '@/components/ui/command';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';

export function tailwindClassToHex(tailwindClass: string) {
  if (tailwindClass.includes('#')) return tailwindClass;
  const [_, colorName, shade] = tailwindClass.split('-');
  const colorObject = colors[colorName as keyof typeof colors];
  return colorObject[shade as keyof typeof colorObject];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ChooseTagColorProps<TData, TValue> {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setInputValue: (open: string) => void;
  callback: (tag: TagColorOption) => void;
  isLoading: boolean;
}

export interface TagColorOption {
  name: string;
  className: string;
  color: string;
}

const colorOptions: TagColorOption[] = [
  {
    name: 'Grey',
    className: 'bg-gray-300',
    color: tailwindClassToHex('bg-gray-300'),
  },
  {
    name: 'Dark Grey',
    className: 'bg-gray-400',
    color: tailwindClassToHex('bg-gray-400'),
  },
  {
    name: 'Purple',
    className: 'bg-violet-500',
    color: tailwindClassToHex('bg-violet-500'),
  },
  {
    name: 'Blue',
    className: 'bg-blue-500',
    color: tailwindClassToHex('bg-blue-500'),
  },
  {
    name: 'Teal',
    className: 'bg-teal-400',
    color: tailwindClassToHex('bg-teal-400'),
  },
  {
    name: 'Green',
    className: 'bg-green-500',
    color: tailwindClassToHex('bg-green-500'),
  },
  {
    name: 'Yellow',
    className: 'bg-yellow-400',
    color: tailwindClassToHex('bg-yellow-400'),
  },
  {
    name: 'Orange',
    className: 'bg-orange-500',
    color: tailwindClassToHex('bg-orange-500'),
  },
  {
    name: 'Pink',
    className: 'bg-pink-500',
    color: tailwindClassToHex('bg-pink-500'),
  },
  {
    name: 'Red',
    className: 'bg-red-500',
    color: tailwindClassToHex('bg-red-500'),
  },
];

export function ChooseTagColor<TData, TValue>({
  name,
  open,
  setOpen,
  setInputValue,
  callback,
  isLoading,
}: ChooseTagColorProps<TData, TValue>) {
  // const [label, setLabel] = useState<string>('');

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-xs px-3 pb-2 pt-0">
        <DialogHeader>
          <div className="hidden">
            <DialogTitle>Choose a color</DialogTitle>
          </div>
          {isLoading && (
            <span className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm">
              <Loader className="animate-spin" size={32} data-testid="loading-indicator" />
            </span>
          )}
          <DialogDescription className="min-h-96">
            <Command>
              {/* <CommandInput
                value={label}
                disabled={isLoading}
                onValueChange={setLabel}
                className="w-full border-0 pl-0 ring-0 focus:outline-none"
                placeholder="Pick a color for tag"
                classNameWrapper="px-0"
              /> */}
              <div className="mt-2 text-sm">Pick a color for your tag</div>
              <CommandList className="max-h-full pt-2">
                {colorOptions.map((option) => (
                  <CommandItem
                    key={option.className}
                    onSelect={() => callback(option)}
                    className="flex w-full cursor-pointer items-center rounded-lg p-2 transition hover:bg-gray-100"
                  >
                    <span className={`mr-3 h-2 w-2 rounded-full ${option.className}`}></span>
                    <span>{option.name}</span>
                  </CommandItem>
                ))}
              </CommandList>
            </Command>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
