import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Column, Table } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';

import { PrimaryAssetRecord } from '@/api/types/node';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { SupportedRouteApi } from '@/types/route-types';

import { getDateRange } from '../LibraryTable/utils/date';

interface DateFilterProps {
  column?: Column<PrimaryAssetRecord, unknown>;
  table: Table<PrimaryAssetRecord>;
  showSelectedInTitle?: boolean;
  title?: string;
  routeApi: SupportedRouteApi;
}

export function DateFilter({ column, table, showSelectedInTitle = true, title, routeApi }: DateFilterProps) {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<DateRange | undefined>();
  const [search, setSearch] = useState('');
  const navigate = routeApi.useNavigate();
  const query = routeApi.useSearch();

  // Initialize date from URL if available
  useEffect(() => {
    if (!column) return;
    const columnFilters = query.columnFilters as Array<{ id: string; value: any }>;
    const dateFilter = columnFilters?.find((filter) => filter.id === column.id);
    if (dateFilter?.value) {
      setDate({
        from: new Date(dateFilter.value.from),
        to: new Date(dateFilter.value.to),
      });
    }
  }, [query.columnFilters, column]);

  if (!column) return null;

  const filterValue = column.getFilterValue() as DateRange | undefined;

  const updateUrlAndFilter = (newDate: { from: string; to: string } | undefined) => {
    column.setFilterValue(newDate);

    const columnFilters = query.columnFilters as Array<{ id: string; value: any }>;
    const updatedFilters = columnFilters
      ? columnFilters.map((filter) => {
          if (filter.id === column.id) {
            return {
              ...filter,
              value: newDate
                ? {
                    from: newDate.from,
                    to: newDate.to,
                  }
                : undefined,
            };
          }
          return filter;
        })
      : [];

    if (newDate) {
      const existingFilter = updatedFilters.find((filter) => filter.id === column.id);
      if (!existingFilter) {
        updatedFilters.push({
          id: column.id,
          value: {
            from: newDate.from,
            to: newDate.to,
          },
        });
      }
    } else {
      const index = updatedFilters.findIndex((filter) => filter.id === column.id);
      if (index !== -1) {
        updatedFilters.splice(index, 1);
      }
    }

    navigate({
      search: (prev) => ({
        ...prev,
        columnFilters: updatedFilters,
      }),
      replace: true,
    });
  };

  const handleDateRangeSelect = (range: string) => {
    const dateRange = getDateRange(range);
    if (dateRange) {
      updateUrlAndFilter(dateRange);
      setDate(undefined);
    }
  };

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (!range?.from) return;

    const newDate: DateRange = {
      from: range.from,
      to: range.to,
    };

    if (newDate.to) {
      updateUrlAndFilter({
        from: newDate.from!.toISOString(),
        to: newDate.to.toISOString(),
      });
    }

    setDate(newDate);
  };

  const getDisplayValue = () => {
    if (!filterValue) return title || 'Date';
    const range = Object.entries({
      today: 'Today',
      yesterday: 'Yesterday',
      last2days: 'Previous 2 days',
      lastweek: 'Last week',
      lastmonth: 'Last month',
      last3months: 'Last 3 months',
      last6months: 'Last 6 months',
      lastyear: 'Last year',
    }).find(([key]) => {
      const range = getDateRange(key);
      return range?.from === filterValue.from && range?.to === filterValue.to;
    });
    if (range) return `${title}: ${range[1]}`;

    // If it's a custom date range, format the dates
    const startDate = filterValue.from ? new Date(filterValue.from) : undefined;
    const endDate = filterValue.to ? new Date(filterValue.to) : undefined;
    if (!startDate || !endDate) return title || 'Date';
    return `${title}: ${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 gap-2 px-3">
          {getDisplayValue()}
          {filterValue && showSelectedInTitle && (
            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
              1
            </span>
          )}
          {open ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" align="start">
        <Command>
          <CommandInput
            className="border-none ps-0 focus:ring-0"
            placeholder="Search ranges..."
            value={search}
            onValueChange={setSearch}
            closeable={search.length > 0}
          />
          <CommandList>
            <CommandEmpty>No ranges found.</CommandEmpty>
            <CommandGroup>
              <CommandItem onSelect={() => handleDateRangeSelect('today')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('today')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Today</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('yesterday')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('yesterday')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Yesterday</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('last2days')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('last2days')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Previous 2 days</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('lastweek')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('lastweek')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Last week</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('lastmonth')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('lastmonth')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Last month</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('last3months')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('last3months')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Last 3 months</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('last6months')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('last6months')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Last 6 months</span>
              </CommandItem>
              <CommandItem onSelect={() => handleDateRangeSelect('lastyear')} className="w-full">
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    filterValue?.from === getDateRange('lastyear')?.from
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible'
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span className="flex-1 truncate text-left">Last year</span>
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup>
              <div className="flex items-center justify-between p-3">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center gap-2">
                    <CalendarIcon className="h-4 w-4" />
                    <span className="text-sm font-medium">Custom Range</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between text-sm text-muted-foreground">
                      <div className="flex items-center gap-2">
                        <span>Start:</span>
                        <span className="font-medium">
                          {date?.from ? format(date.from, 'MMM d, yyyy') : 'Select date'}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span>End:</span>
                        <span className="font-medium">{date?.to ? format(date.to, 'MMM d, yyyy') : 'Select date'}</span>
                      </div>
                    </div>
                    <Calendar
                      mode="range"
                      selected={date}
                      onSelect={handleRangeSelect}
                      captionLayout="dropdown"
                      numberOfMonths={1}
                      className="rounded-md border"
                      fromYear={2000}
                      fromMonth={new Date(2000, 0)}
                      toDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    />
                  </div>
                </div>
              </div>
            </CommandGroup>
          </CommandList>
          {filterValue && (
            <CommandItem
              onSelect={() => {
                column.setFilterValue(undefined);
                setDate(undefined);
                setOpen(false);
              }}
              className="justify-center border-t text-center"
            >
              Clear filter
            </CommandItem>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
