import { PlusIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import get from 'lodash/get';
import { CircleDashed, Loader } from 'lucide-react';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import {
  useAddTagToContentMutation,
  useCreateTagMutation,
  useGetTagsQuery,
  useRemoveTagFromContentMutation,
} from '@/api/api';
import { ContentRecord, PrimaryAssetRecord } from '@/api/types/node';
import { ChooseTagColor, TagColorOption } from '@/components/Filters/ChooseTagColor';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useToast } from '@/components/ui/use-toast';
import { useOrg } from '@/contexts/OrgContext';
import { useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';
import { PageTagData, TAG_ACTIONS, trackPageTagEvent } from '@/utils/analytics';

interface TagsCreateProps {
  title?: string;
  record: PrimaryAssetRecord | ContentRecord;
  onClose: () => void;
  selectedTags?: TagResult[];
}

export const TagsCreate = React.memo(({ record, onClose, selectedTags }: TagsCreateProps) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [tagIdLoading, setTagIdLoading] = useState('');
  const { userName, userId } = useUser();
  const { organizationId } = useOrg();
  const primaryAssetId = get(record, 'node.version.primary_asset.id', record.id);
  const documentName =
    'display_name' in record ? record.display_name : record?.node?.version?.primary_asset?.display_name;
  const [selectedValues, setSelectedValues] = useState<TagResult[]>(record?.tags ?? selectedTags ?? []);
  const title = 'Tags';
  const [createTag, { isLoading: _createTagLoading }] = useCreateTagMutation();
  const { data: tags } = useGetTagsQuery({
    type: 'tag',
    limit: 1000,
    offset: 0,
  });
  // PostHog Event Data
  const commonTagEventData = useMemo(
    () =>
      ({
        userId: userId,
        userName: userName,
        orgId: organizationId!,
        pageId: primaryAssetId,
        url: window.location.href,
        title: documentName,
      }) as PageTagData,
    [userId, userName, organizationId, primaryAssetId, documentName]
  );

  const [sortedTags, setSortedTags] = React.useState<TagResult[]>([]);

  React.useEffect(() => {
    if (tags?.results) {
      const tagList = [...tags.results];
      const sorted = tagList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setSortedTags(sorted);
      setSelectedValues(record?.tags ?? selectedTags ?? []);
    }
  }, [tags, record?.tags, selectedTags]);

  const { toast } = useToast();
  const [addTagToContent, { isLoading: tagAddLoading }] = useAddTagToContentMutation();
  const [removeTagFromContent, { isLoading: tagRemoveLoading }] = useRemoveTagFromContentMutation();
  const tagsLoading = tagAddLoading || tagRemoveLoading;

  const onSelectTag = useCallback(
    async (val: string, option: TagResult) => {
      setTagIdLoading(option.id);
      const isSelected = selectedValues.some((sv) => sv.id === option.id);

      try {
        if (!isSelected) {
          // Optimistically update UI for selecting a tag
          setSelectedValues((prev) => [...prev, option]);

          const result = await addTagToContent({
            tagId: option.id,
            primaryAssetId,
          });

          if ('error' in result) throw new Error();

          trackPageTagEvent({
            ...commonTagEventData,
            tagId: option.id,
            tagName: option.name,
            timestamp: new Date().toISOString(),
            action: TAG_ACTIONS.TAG_ADDED,
          });
        } else {
          // Optimistically update UI for removing a tag
          setSelectedValues((prev) => prev.filter((sv) => sv.id !== option.id));

          const result = await removeTagFromContent({
            tagId: option.id,
            primaryAssetId,
          });

          if ('error' in result) throw new Error();

          trackPageTagEvent({
            ...commonTagEventData,
            tagId: option.id,
            tagName: option.name,
            timestamp: new Date().toISOString(),
            action: TAG_ACTIONS.TAG_REMOVED,
          });
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: isSelected ? 'Failed to remove tag from document' : 'Failed to associate tag with document',
          variant: 'destructive',
        });
        // Revert the optimistic update if the API call fails
        setSelectedValues((prev) => (isSelected ? [...prev, option] : prev.filter((sv) => sv.id !== option.id)));
      } finally {
        setTagIdLoading('');
      }
    },
    [selectedValues, addTagToContent, removeTagFromContent, primaryAssetId, commonTagEventData, toast]
  );

  const addTag = useCallback(
    async (tag: TagColorOption) => {
      if (!inputValue) return;

      const tagName = inputValue;
      try {
        const newTag = await createTag({
          name: tagName,
          hex_color: tag.color,
          type: 'tag',
        });

        if (newTag.error || !newTag.data) throw new Error();

        // Optimistically add tag to UI
        setSelectedValues((prev) => [...prev, newTag.data]);

        const result = await addTagToContent({
          tagId: newTag.data.id,
          primaryAssetId,
        });

        if (result.error) throw new Error();

        trackPageTagEvent({
          ...commonTagEventData,
          tagId: newTag.data.id,
          tagName: tagName,
          timestamp: new Date().toISOString(),
          action: TAG_ACTIONS.TAG_ADDED,
        });

        setOpen(false);
        setInputValue('');
        toast({
          title: 'Tag created',
          description: (
            <span>
              <strong>{tagName}</strong> has been added to <strong>{documentName}</strong>
            </span>
          ),
        });
      } catch (error: unknown) {
        // Revert the optimistic update if the API call fails
        setSelectedValues((prev) => prev.filter((t) => t.id !== (error as any)?.data?.id));

        toast({
          title: 'Error',
          description: `Failed to associate tag ${tagName} with document ${documentName}`,
          variant: 'destructive',
        });
      } finally {
        setInputValue('');
        setOpen(false);
      }
    },
    [inputValue, createTag, addTagToContent, primaryAssetId, commonTagEventData, documentName, toast]
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverOpenChange = useCallback(
    (open: boolean) => {
      setIsPopoverOpen(open);
      if (open) {
        const sorted = [...sortedTags].sort((a, b) => {
          const aSelected = selectedValues.some((val) => val.name === a.name);
          const bSelected = selectedValues.some((val) => val.name === b.name);
          if (aSelected !== bSelected) {
            return aSelected ? -1 : 1;
          }
          return 0;
        });
        setSortedTags(sorted);
      }
      if (!open) {
        onClose();
      }
    },
    [sortedTags, selectedValues, onClose]
  );

  return (
    <Popover modal={true} onOpenChange={handlePopoverOpenChange}>
      <ChooseTagColor
        name={inputValue}
        open={open}
        setOpen={setOpen}
        setInputValue={setInputValue}
        callback={addTag}
        isLoading={tagsLoading}
      />
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn(
            'flex h-full w-full items-center justify-center whitespace-nowrap border-dashed transition-opacity duration-200',
            isPopoverOpen ? 'pointer-events-none opacity-0' : 'opacity-100'
          )}
        >
          <CircleDashed className="mr-2 h-3 w-3 flex-shrink-0" />
          <span className="truncate">{(record?.tags?.length ?? 0) > 0 ? 'Edit tags' : 'Add tags'}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-56 p-0" align="start" forceMount>
        <Command>
          <CommandInput
            placeholder={`Filter ${title?.toLowerCase()}...`}
            className="border-none ps-0 focus:ring-0"
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            {inputValue.length > 0 && (
              <CommandEmpty className="p-2">
                <Button
                  variant="secondary"
                  className="w-full justify-start"
                  leadingIcon={PlusIcon}
                  onClick={() => setOpen(true)}
                >
                  <span className="truncate">Create new tag: {inputValue}</span>
                </Button>
              </CommandEmpty>
            )}
            {inputValue.length === 0 && <CommandEmpty>No results</CommandEmpty>}
            <CommandGroup>
              {sortedTags.map((option) => {
                const isSelected = selectedValues.some((val) => val.id === option.id);
                return (
                  <CommandItem key={option.id} className="w-full" onSelect={(val) => onSelectTag(val, option)}>
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      {tagIdLoading === option.id ? (
                        <Loader className={cn('w-4, h-4 animate-spin')} />
                      ) : (
                        <CheckIcon className={cn('h-4 w-4')} />
                      )}
                    </div>
                    <div className="flex items-center">
                      <span className={`mr-2 h-2 w-2 rounded-full`} style={{ background: option.hex_color }}></span>
                      <span className="flex-1 truncate text-left">{option.name}</span>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <CommandGroup className="sticky bottom-0">
              {inputValue.length > 0 && (
                <CommandItem className="-mb-1 bg-background p-2 data-[selected=true]:bg-background">
                  <Button
                    variant="secondary"
                    className="w-full justify-start"
                    leadingIcon={PlusIcon}
                    onClick={() => setOpen(true)}
                  >
                    <span className="truncate">Create new tag: {inputValue}</span>
                  </Button>
                </CommandItem>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
});

TagsCreate.displayName = 'TagsCreate';
