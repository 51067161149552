import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface TagProps {
  tag: Tag;
  className?: string;
}

interface Tag {
  name: string;
  hex_color?: string;
  color?: string;
}

export const Tag: React.FC<TagProps> = ({ tag, className }) => {
  const color = tag.hex_color || tag.color;
  return (
    <Badge key={tag.name} variant="outline" className={cn('max-w-[100px] whitespace-nowrap px-1.5', className)}>
      {color && (
        <span className={`mr-2 h-2 w-2 flex-shrink-0 rounded-full`} style={{ background: color }}>
          {' '}
        </span>
      )}
      <span className="truncate">{tag.name}</span>
    </Badge>
  );
};
