import { Slot } from '@radix-ui/react-slot';
import { Link, LinkProps } from '@tanstack/react-router';
import { type VariantProps, cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import * as React from 'react';
import { LegacyRef } from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:opacity-50 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/80 active:bg-primary/70',
        neonBlue: 'bg-neon-blue text-primary-foreground hover:bg-neon-blue/80 active:bg-neon-blue/70',
        outline: 'border border-input hover:bg-accent hover:text-accent-foreground active:bg-accent-emphasis',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary-hover active:bg-secondary-emphasis',
        ghost: 'hover:bg-accent hover:text-accent-foreground active:bg-accent-emphasis text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        // TODO: Fix AI button CSS - low priority
        // ai: "relative bg-primary text-primary-foreground disabled:hover:bg-primary hover:bg-transparent rounded-md border-gradient",
        ai: 'bg-primary text-primary-foreground hover:bg-primary/80 active:bg-primary/70',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive-emphasis active:bg-destructive-emphasis/80',
        destructiveGhost: 'text-destructive hover:bg-destructive/10 active:bg-destructive/20',
        destructiveOutline:
          'border border-destructive/50 dark:border-destructive-emphasis/50 text-destructive dark:text-destructive-emphasis hover:bg-destructive/10 dark:hover:bg-destructive-emphasis/10 active:bg-destructive/20 dark:active:bg-destructive-emphasis/5',
      },
      size: {
        default: 'h-9 px-3 py-2',
        xs: 'h-6 rounded-md px-2 text-xs',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
        iconSm: 'h-6 w-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const iconSizeClasses = {
  default: 'h-4 w-4',
  xs: 'h-3.5 w-3.5',
  sm: 'h-3.5 w-3.5',
  lg: 'h-5 w-5',
  icon: 'h-4 w-4',
  iconSm: 'h-3 w-3',
};

const iconMarginClasses = {
  leading: {
    default: '-ml-0.5 mr-2',
    xs: '-ml-0.5 mr-1',
    sm: '-ml-0.5 mr-1',
    lg: '-ml-1 mr-2.5',
    icon: '',
    iconSm: '',
  },
  trailing: {
    default: 'ml-2 -mr-0.5',
    xs: 'ml-1 -mr-0.5',
    sm: 'ml-1 -mr-0.5',
    lg: 'ml-2.5 -mr-1',
    icon: '',
    iconSm: '',
  },
};

export type ButtonIcon = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

export interface BaseLinkProps {
  leadingIcon?: ButtonIcon;
  trailingIcon?: ButtonIcon;
  leadingIconClassName?: string;
  trailingIconClassName?: string;
  iconOnly?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
}

export interface ButtonProps
  extends BaseLinkProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export interface ButtonLinkProps
  extends BaseLinkProps,
    Omit<React.ButtonHTMLAttributes<HTMLAnchorElement>, 'href' | 'children'>,
    Omit<LinkProps, 'children'>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps | ButtonLinkProps>(
  (
    {
      className,
      variant,
      size,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      leadingIconClassName,
      trailingIconClassName,
      iconOnly = false,
      type,
      children,
      loading,
      ...props
    },
    ref
  ) => {
    const IconComponent = iconOnly ? LeadingIcon || TrailingIcon : null;

    const elemClassName = cn(buttonVariants({ variant, size, className }));

    const buttonContent = loading ? (
      <Loader2 className={cn('animate-spin', iconSizeClasses[size || 'default'])} />
    ) : (
      <>
        {iconOnly && IconComponent ? (
          <IconComponent className={cn(iconSizeClasses[size || 'default'])} />
        ) : (
          <>
            {LeadingIcon && (
              <span
                className={cn(
                  iconSizeClasses[size || 'default'],
                  iconMarginClasses.leading[size || 'default'],
                  leadingIconClassName,
                  'flex-shrink-0'
                )}
              >
                <LeadingIcon />
              </span>
            )}
            {children}
            {TrailingIcon && (
              <span
                className={cn(
                  iconSizeClasses[size || 'default'],
                  iconMarginClasses.trailing[size || 'default'],
                  trailingIconClassName,
                  'flex-shrink-0'
                )}
              >
                <TrailingIcon />
              </span>
            )}
          </>
        )}
      </>
    );

    const isLinkProps = (props: any): props is ButtonLinkProps => 'to' in props;

    if (isLinkProps(props)) {
      return (
        <Link {...props} className={elemClassName} ref={ref as LegacyRef<HTMLAnchorElement>}>
          {buttonContent}
        </Link>
      );
    } else {
      const { asChild, ...rest } = props as ButtonProps;
      const Comp = asChild ? Slot : 'button';

      return (
        <Comp className={elemClassName} type={type || 'button'} ref={ref as LegacyRef<HTMLButtonElement>} {...rest}>
          {buttonContent}
        </Comp>
      );
    }
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
