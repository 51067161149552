// this is not technically a sync/async but i wanted a way to distinguish it from
// next/navigation's useSearchParams
//
// on page load their implementation gives back nulls for search params that are in the url.
// on subsequent renders, the value becomes available, but since we're tying this variable to our api calls,
// we don't want to fetch items with no version, then to fetch again when the version becomes populated.
// and we don't want to include unnecessary checks on the routers' `ready` state in our api calls.
export function useSyncSearchParams() {
  const params = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;

  return params;
}
