import { ColumnSort, VisibilityState } from '@tanstack/react-table';

import { VersionStatus } from '@/api/types/node';

export const TableType = {
  PDF: 'PDF',
  CODEBASE: 'CODEBASE',
  PAGE: 'PAGE',
  PAGE_TEMPLATE: 'PAGE_TEMPLATE',
} as const;

export type TableType = (typeof TableType)[keyof typeof TableType];

export const DEFAULT_SORTING: ColumnSort[] = [{ id: 'updated_at', desc: true }];

export const DEFAULT_COLUMN_VISIBILITY: VisibilityState = {
  select: true,
  display_name: true,
  tags: true,
  top_language: false,
  file_count: true,
  size: false,
  pages: true,
  created_at: true,
  updated_at: true,
  creator: false,
  status: true,
  actions: true,
};

export const DEFAULT_CODEBASE_COLUMN_VISIBILITY: VisibilityState = {
  select: true,
  display_name: true,
  tags: true,
  top_language: false,
  file_count: true,
  size: false,
  pages: true,
  created_at: true,
  updated_at: true,
  creator: false,
  status: true,
  actions: true,
};

export const DEFAULT_PDF_COLUMN_VISIBILITY: VisibilityState = {
  select: true,
  display_name: true,
  tags: true,
  top_language: false,
  file_count: true,
  size: false,
  pages: true,
  created_at: true,
  updated_at: true,
  creator: false,
  status: true,
  actions: true,
};

export const DEFAULT_DOCUMENT_COLUMN_VISIBILITY: VisibilityState = {
  select: true,
  display_name: true,
  sources: true,
  tags: true,
  created_at: true,
  updated_at: true,
  creator: true,
  status: true,
  actions: true,
};

export const EXCLUDED_STATUSES = [VersionStatus.USER_DATA] as const;

export type ExcludedStatus = (typeof EXCLUDED_STATUSES)[number];

// TODO: We will add some of these columns to the filters in the future once we get BE support
// List of columns that should not appear in filters.
export const EXCLUDED_FILTER_COLUMNS = [
  'tags',
  'display_name',
  'file_count',
  'pages',
  'top_language',
  'size',
  'added_by',
] as const;
