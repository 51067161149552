import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import { useGetContentDownloadUrlQuery } from '@/api/api';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import { PDFPreview } from '@/components/PDFPreview';
import { Skeleton } from '@/components/ui/skeleton';
import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/$organizationName/pdf/$contentId')({
  component: PDFViewerPage,
});

function PDFViewerPage() {
  const { organizationId } = useOrg();
  const { contentId } = Route.useParams();

  const {
    data: pdfUrlResponse,
    isLoading: isPdfLoading,
    error: pdfError,
  } = useGetContentDownloadUrlQuery({
    contentId: contentId?.toString() || '',
  });

  const pdfUrl = pdfUrlResponse?.download_url;
  const pdfName = pdfUrlResponse?.content_name;

  useEffect(() => {
    document.title = pdfName || 'PDF';
  }, [pdfName]);

  if (!organizationId) {
    return null;
  }

  if (isPdfLoading) {
    return <LoadingState title="Loading PDF" />;
  }

  if (pdfError) {
    return <ErrorState />;
  }

  return (
    <>
      <DefaultLayout navbarTitle={pdfName} noContentPadding>
        <FlexiblePanelLayout
          pageType="pdf"
          mainPanelContent={<PDFPreview url={pdfUrl} />}
          // rightPanelHeader={<RightPanelHeader url={pdfUrl} name={pdfName} />}
          // rightPanelContent={<PDFSummary content={pdfData?.content} />}
          // rightPanelWidth={550}
        />
      </DefaultLayout>
    </>
  );
}

function LoadingState({ title }: { title?: string }) {
  return (
    <DefaultLayout navbarTitle={title} noContentPadding>
      <FlexiblePanelLayout
        pageType="pdf"
        mainPanelContent={
          <div className="relative mx-auto w-full max-w-2xl p-6">
            <div className="mb-4 flex items-center justify-between gap-4">
              <Skeleton className="h-8 w-3/4" />
              <Skeleton className="h-9 w-24" />
            </div>
            <div className="space-y-4">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-5/6" />
              <Skeleton className="h-4 w-4/5" />
              <Skeleton className="h-4 w-7/12" />
              <Skeleton className="h-4 w-3/4" />
            </div>
          </div>
        }
      />
    </DefaultLayout>
  );
}

function ErrorState() {
  return (
    <DefaultLayout navbarTitle="Error">
      <div>Error loading PDF</div>
    </DefaultLayout>
  );
}

// function RightPanelHeader({ url, name }: { url?: string; name?: string }) {
//   const handleDownload = () => {
//     if (!url || !name) return;
//     fetch(url)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const blobUrl = window.URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = blobUrl;
//         link.download = name;
//         document.body.appendChild(link);
//         link.click();
//         window.URL.revokeObjectURL(blobUrl);
//         document.body.removeChild(link);
//       });
//   };

//   return (
//     <div className="flex h-full w-full items-center justify-end space-x-1">
//       <Button size="sm" variant="ghost" onClick={() => url && window.open(url, '_blank')} title="View in full screen">
//         <Maximize2 className="h-4 w-4" />
//       </Button>
//       <Button size="sm" variant="default" onClick={handleDownload}>
//         <Download className="mr-2 h-4 w-4" />
//         Download
//       </Button>
//     </div>
//   );
// }

// function PDFSummary({ content }: { content?: string }) {
//   return (
//     <div className="relative mx-auto w-full max-w-2xl p-6">
//       <div className="mb-4 truncate text-2xl font-bold">Summary</div>
//       <Separator className="my-2" />
//       <div className="mb-4">
//         <BlockEditorBasic content={content || 'No summary available for this PDF.'} editable={false} />
//       </div>
//     </div>
//   );
// }

export default PDFViewerPage;
