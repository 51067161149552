import { useNavigate } from '@tanstack/react-router';

import { useCreateTemplateMutation } from '@/api/api';
import { useToast } from '@/components/ui/use-toast';
import { useOrgUrlParams } from '@/hooks/useOrgUrlParams';

export const useCreateTemplate = () => {
  const [createTemplate] = useCreateTemplateMutation();
  const { toast } = useToast();
  const { organizationName } = useOrgUrlParams();
  const navigate = useNavigate();

  const handleCreateTemplate = async () => {
    try {
      const res = await createTemplate();

      if ('error' in res) {
        throw new Error('Failed to create template');
      }

      navigate({
        to: `/$organizationName/template/$contentId`,
        params: { organizationName, contentId: res.data.node_id },
      });
      toast({ title: 'Template created' });

      return res.data;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create template',
        variant: 'destructive',
      });
      throw error;
    }
  };

  return { createTemplate: handleCreateTemplate };
};
