import { ColumnDef } from '@tanstack/react-table';

import { PrimaryAssetRecord } from '@/api/types/node';
import { actionsColumn, commonColumns } from '@/components/BaseTable/columns/utils';
import { DataTableColumnHeader } from '@/components/LibraryTable/ColumnHeader';
import { formatBytes } from '@/lib/utils';

import { CellStatus } from '../BaseTable/columns/CellStatus';
import { getTopLanguage } from '../BaseTable/utils/table-utils';

interface ExtendedMetadata {
  analyzable_bytes: any;
  analyzable_files?: number;
  top_language?: string;
  file_count?: number;
  size?: number;
  pages?: number;
  used_in?: string;
}

// Codebase specific columns
export const codebaseColumns: ColumnDef<PrimaryAssetRecord>[] = [
  ...commonColumns,
  {
    id: 'top_language',
    enableSorting: false,

    header: ({ column }) => <DataTableColumnHeader column={column} title="Top Language" />,
    accessorFn: (row) => getTopLanguage(row),
    cell: ({ row }) => {
      const value = row.getValue('top_language') as string;
      return value || '-';
    },
  },
  {
    accessorKey: 'file_count',
    size: 80,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="File Count" />,
    cell: ({ row }) => {
      const metadata = row.original.most_recent_version?.root_node?.misc_metadata as ExtendedMetadata;
      return <div>{metadata?.analyzable_files || '-'}</div>;
    },
  },
  {
    accessorKey: 'size',
    size: 80,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Size" />,
    cell: ({ row }) => {
      const metadata = row.original.most_recent_version?.root_node?.misc_metadata as ExtendedMetadata;
      return <div>{metadata?.analyzable_bytes ? formatBytes(metadata.analyzable_bytes) : '-'}</div>;
    },
  },
  {
    accessorKey: 'pages',
    size: 80,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Pages" />,
    cell: ({ row }) => {
      const metadata = row.original.most_recent_version?.root_node?.misc_metadata as ExtendedMetadata;
      return <div>{metadata?.pages || '-'}</div>;
    },
  },
  {
    accessorKey: 'created_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row }) => {
      const createdAt = row.original.created_at;
      return <div>{createdAt ? new Date(createdAt).toLocaleDateString() : '-'}</div>;
    },
  },
  {
    accessorKey: 'updated_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Updated" />,
    cell: ({ row }) => {
      const updatedAt = row.original.updated_at;
      return <div>{updatedAt ? new Date(updatedAt).toLocaleDateString() : '-'}</div>;
    },
  },
  {
    id: 'creator',
    accessorFn: (originalRow) => originalRow.most_recent_version?.creator?.full_name,
    size: 100,
    minSize: 90,
    maxSize: 120,
    enableColumnFilter: false,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Added By" />,
    cell: ({ row }) => {
      const creator = row.original.most_recent_version?.creator?.full_name;
      return <div>{creator || '-'}</div>;
    },
  },
  {
    accessorKey: 'status',
    size: 80,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      <div>
        <CellStatus row={row} />
      </div>
    ),
  },
  actionsColumn,
];

// PDF specific columns
export const pdfColumns: ColumnDef<PrimaryAssetRecord>[] = [
  ...commonColumns,
  {
    accessorKey: 'size',
    size: 80,
    minSize: 60,
    maxSize: 100,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Size" />,
    cell: ({ row }) => {
      const metadata = row.original.most_recent_version?.root_node?.misc_metadata as ExtendedMetadata;
      return <div>{metadata?.analyzable_bytes ? formatBytes(metadata.analyzable_bytes) : '-'}</div>;
    },
  },
  {
    accessorKey: 'pages',
    size: 80,
    minSize: 60,
    maxSize: 100,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Pages" />,
    cell: ({ row }) => {
      const metadata = row.original.most_recent_version?.root_node?.misc_metadata as ExtendedMetadata;
      return <div>{metadata?.pages || '-'}</div>;
    },
  },
  {
    accessorKey: 'created_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row }) => {
      const createdAt = row.original.created_at;
      return <div>{createdAt ? new Date(createdAt).toLocaleDateString() : '-'}</div>;
    },
  },
  {
    accessorKey: 'updated_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Updated" />,
    cell: ({ row }) => {
      const updatedAt = row.original.updated_at;
      return <div>{updatedAt ? new Date(updatedAt).toLocaleDateString() : '-'}</div>;
    },
  },
  {
    id: 'creator',
    accessorFn: (originalRow) => originalRow.most_recent_version?.creator?.full_name,
    size: 100,
    minSize: 90,
    maxSize: 120,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Added By" />,
    cell: ({ row }) => {
      const creator = row.original.most_recent_version?.creator?.full_name;
      return <div>{creator || '-'}</div>;
    },
  },
  {
    accessorKey: 'status',
    size: 80,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      <div>
        <CellStatus row={row} />
      </div>
    ),
  },
  actionsColumn,
];
