import { Table, VisibilityState } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { DEFAULT_CODEBASE_COLUMN_VISIBILITY, TableType } from '@/components/Filters/filter-constants';

import { BaseTable } from '../BaseTable/BaseTable';
import { codebaseColumns } from './columns';
import { useSourcesTable } from './useSourcesTable';

interface CodebaseTableProps {
  extraContent?: (data: PrimaryAssetRecord[]) => ReactNode;
  toolbarContent?: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => ReactNode;
  defaultColumnVisibility?: VisibilityState;
}

export function CodebaseTable({
  toolbarContent,
  extraContent,
  defaultColumnVisibility = DEFAULT_CODEBASE_COLUMN_VISIBILITY,
}: CodebaseTableProps) {
  const { data, table, isLoading } = useSourcesTable({
    columns: codebaseColumns,
    defaultFilters: [
      {
        id: 'kind',
        value: [PrimaryAsset.CODEBASE],
      },
    ],
    tableType: TableType.CODEBASE,
    defaultColumnVisibility,
  });

  return (
    <BaseTable
      table={table}
      isLoading={isLoading}
      data={data}
      toolbarContent={toolbarContent}
      extraContent={extraContent}
    />
  );
}
