import { useLocalStorage } from '@rehooks/local-storage';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  OnChangeFn,
  PaginationState,
  Updater,
  VisibilityState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { driverApi, useGetPrimaryAssetQuery, useGetTagsQuery } from '@/api/api';
import {
  ColumnFiltersAssetType,
  FetchPrimaryAssetParams,
  PrimaryAsset,
  PrimaryAssetRecord,
  PrimaryAssetResponse,
  VersionStatusType,
} from '@/api/types/node';
import {
  DEFAULT_CODEBASE_COLUMN_VISIBILITY,
  DEFAULT_PDF_COLUMN_VISIBILITY,
  DEFAULT_SORTING,
  TableType,
} from '@/components/Filters/filter-constants';
import { StorageKeys } from '@/constants/storage';
import { useAppDispatch } from '@/store';
import { libraryRouteApi } from '@/utils/route';

import { useGetHasLibraryData } from './useGetHasLibraryData';
import { useLibraryStatusPoller } from './useLibraryStatusPoller';

export const DEFAULT_PAGE_SIZE = 30;

const createTagNameToIdMap = (tags: any) => {
  const map: { [key: string]: string } = {};
  tags?.results.forEach((tag: TagResult) => {
    map[tag.name] = tag.id;
  });
  return map;
};

interface UseSourcesTableProps {
  defaultFilters?: ColumnFiltersState;
  columns: ColumnDef<PrimaryAssetRecord>[];
  tableType: TableType;
  defaultColumnVisibility?: VisibilityState;
}

export function useSourcesTable({
  defaultFilters = [],
  columns,
  tableType,
  defaultColumnVisibility = tableType === TableType.CODEBASE
    ? DEFAULT_CODEBASE_COLUMN_VISIBILITY
    : DEFAULT_PDF_COLUMN_VISIBILITY,
}: UseSourcesTableProps) {
  const navigate = libraryRouteApi.useNavigate();
  const query = libraryRouteApi.useSearch();
  const generateRowIds = query.generateRowIds;
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const dispatch = useAppDispatch();

  // Determine which storage key to use based on the table type
  const storageKey =
    tableType === TableType.CODEBASE ? StorageKeys.CODEBASE_COLUMN_VISIBILITY : StorageKeys.PDF_COLUMN_VISIBILITY;

  const [columnVisibility, setColumnVisibility] = useLocalStorage<VisibilityState>(storageKey, defaultColumnVisibility);

  // Update row selection when generateRowIds changes
  React.useEffect(() => {
    if (generateRowIds) {
      const newSelection = generateRowIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as Record<string, boolean>);
      setRowSelection(newSelection);
    } else {
      setRowSelection({});
    }
  }, [generateRowIds]);

  const handleColumnVisibilityChange: OnChangeFn<VisibilityState> = (updaterOrValue: Updater<VisibilityState>) => {
    const newVisibility =
      typeof updaterOrValue === 'function' ? updaterOrValue(columnVisibility || {}) : updaterOrValue;
    setColumnVisibility(newVisibility);
  };

  // Ensure we always have the kind filter from defaultFilters
  const kindFilter = defaultFilters.find((filter) => filter.id === 'kind');

  // Get filters from URL, preserving the kind filter
  const columnFilters = React.useMemo(() => {
    return query.columnFilters
      ? (query.columnFilters as ColumnFiltersState)
          .filter((filter) => filter.id !== 'kind')
          .concat(kindFilter ? [kindFilter] : [])
      : defaultFilters;
  }, [query.columnFilters, defaultFilters, kindFilter]);

  // Get sorting from URL or use default
  const sorting = React.useMemo(() => {
    return (query.sorting as ColumnSort[]) || DEFAULT_SORTING;
  }, [query.sorting]);

  // Get pagination from URL or use defaults
  const pagination = React.useMemo(() => {
    return {
      pageIndex: (query.pageIndex as number) || 0,
      pageSize: (query.pageSize as number) || DEFAULT_PAGE_SIZE,
    };
  }, [query.pageIndex, query.pageSize]);

  const handlePaginationChange = (updaterOrValue: Updater<PaginationState>) => {
    const newPagination = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
    navigate({
      search: (prev) => ({
        ...prev,
        pageIndex: newPagination.pageIndex,
        pageSize: newPagination.pageSize,
      }),
    });
  };

  const handleSortingChange = (updaterOrValue: Updater<ColumnSort[]>) => {
    const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
    navigate({
      search: (prev) => ({
        ...prev,
        sorting: newSorting.length === 0 ? DEFAULT_SORTING : newSorting,
        pageIndex: 0,
      }),
      replace: true,
    });
  };

  const handleColumnFiltersChange = (updaterOrValue: Updater<ColumnFiltersState>) => {
    const newFilters = typeof updaterOrValue === 'function' ? updaterOrValue(columnFilters) : updaterOrValue;

    // Ensure we preserve the kind filter when updating filters
    const newKindFilter = kindFilter || newFilters.find((filter) => filter.id === 'kind');
    const filtersWithoutKind = newFilters.filter((filter) => filter.id !== 'kind');
    const finalFilters = newKindFilter ? [...filtersWithoutKind, newKindFilter] : filtersWithoutKind;

    navigate({
      search: (prev) => ({
        ...prev,
        columnFilters: finalFilters,
        pageIndex: 0,
      }),
      replace: true,
    });
  };

  const {
    hasCodebaseAndPdfRecords: hasRecords,
    isLoading: hasRecordsLoading,
    isFetching: hasRecordsFetching,
    refetch: refetchHasRecords,
  } = useGetHasLibraryData();
  const { data: tags } = useGetTagsQuery({ type: 'tag', limit: 50, offset: 0 });
  const tagNameToIdMap = React.useMemo(() => createTagNameToIdMap(tags), [tags]);

  const params = React.useMemo(() => {
    const contentTypeNames: string[] = columnFilters
      .filter((filter) => filter.id === 'kind')
      .map((filter) => filter.value as string[])
      .flat();

    const filterIds: string[] = columnFilters
      .filter((filter) => filter.id === 'tags')
      .map((filter) => filter.value as string[])
      .flat()
      .map((tagName) => tagNameToIdMap[tagName] || tagName);

    const primaryAssetType =
      contentTypeNames.length > 0
        ? contentTypeNames.map((name) => PrimaryAsset[name as keyof typeof PrimaryAsset])
        : [...ColumnFiltersAssetType];

    // Get search text from URL query
    const searchText = query.search;
    // Get ActiveFilters values
    const status = columnFilters.find((filter) => filter.id === 'status')?.value as VersionStatusType[];
    const topLanguageFilters = columnFilters.find((filter) => filter.id === 'top_language')?.value as
      | string[]
      | undefined;

    // Get date range filters
    const createdDateRangeFilter = columnFilters.find((filter) => filter.id === 'created_at')?.value as
      | { from: string; to: string }
      | undefined;
    const updatedDateRangeFilter = columnFilters.find((filter) => filter.id === 'updated_at')?.value as
      | { from: string; to: string }
      | undefined;

    const params: FetchPrimaryAssetParams = {
      display_name__ilike: searchText ? `%${searchText}%` : undefined,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      kind: primaryAssetType,
      sort_direction: sorting[0]?.desc ? 'DESC' : 'ASC',
      sort_by: sorting[0]?.id ?? 'updated_at',
      tag_ids: filterIds,
      'versions.status': status,
      'versions.root_node.misc_metadata.top_language__in': topLanguageFilters?.length ? topLanguageFilters : undefined,
      created_at__gte: createdDateRangeFilter?.from,
      created_at__lte: createdDateRangeFilter?.to,
      updated_at__gte: updatedDateRangeFilter?.from,
      updated_at__lte: updatedDateRangeFilter?.to,
    };

    return params;
  }, [columnFilters, pagination, sorting, tagNameToIdMap, query.search]);

  const {
    data: response,
    isLoading: isLoadingResponse,
    isFetching: isFetchingResponse,
  } = useGetPrimaryAssetQuery(params);
  const isLoading = isLoadingResponse || hasRecordsLoading;
  const isFetching = isFetchingResponse || hasRecordsFetching;

  // Ensure we have data and it's an array
  const data = React.useMemo(() => {
    if (!response?.results) return [];
    return response.results;
  }, [response?.results]);

  const updateCache = (row: PrimaryAssetRecord) => {
    dispatch(
      driverApi.util.updateQueryData('getPrimaryAsset', params, (draft: PrimaryAssetResponse) => {
        const assetToUpdate = draft.results.find((asset) => asset.id === row.id);
        if (assetToUpdate && assetToUpdate.most_recent_version && row.most_recent_version?.status) {
          assetToUpdate.most_recent_version = row.most_recent_version;
        }
      })
    );
  };

  // poll the status of the rows that are processing
  useLibraryStatusPoller(data, updateCache);

  const generateRow = React.useMemo(() => {
    return data.find((row) => (generateRowIds as string[]).includes(row.id));
  }, [data, generateRowIds]);

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      columnFilters,
      sorting,
      columnVisibility,
      globalFilter,
      pagination,
      rowSelection,
    },
    onColumnFiltersChange: handleColumnFiltersChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualFiltering: true,
    pageCount: Math.ceil((response?.total_count || 0) / pagination.pageSize),
    onPaginationChange: handlePaginationChange,
    initialState: {
      columnVisibility: defaultColumnVisibility,
    },
  });

  return { data, table, isLoading, isFetching, hasRecords, generateRow, refetchHasRecords };
}
