import { MinusIcon } from 'lucide-react';
import React from 'react';

interface ExpandCollapseButtonProps {
  isOpen: boolean;
  columnWidth: number;
  onToggle: () => void;
}

export const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({ isOpen, columnWidth, onToggle }) => {
  return (
    <div
      className="group fixed inset-y-0 right-0 z-50 flex h-24 items-center transition-transform"
      style={{
        marginRight: isOpen ? `${columnWidth}px` : '0rem',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <button onClick={onToggle} className="h-full">
        <div
          className={`flex h-full w-5 items-center justify-center rounded-l-lg py-5 text-muted-foreground hover:bg-muted hover:text-foreground hover:ring-1 hover:ring-border focus:outline-none ${
            isOpen ? '' : 'pe-4 ps-6'
          }`}
        >
          <div className="group pointer-events-none">
            <MinusIcon
              className={`h-12 w-12 rotate-90 text-muted-foreground/50 ${isOpen ? 'group-hover:hidden' : 'hidden'}`}
            />
            <svg
              width="8"
              height="32"
              viewBox="0 0 8 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`text-muted-foreground group-hover:block ${isOpen ? 'hidden' : 'scale-x-[-1] transform transition-transform duration-100 group-hover:-translate-x-1'}`}
            >
              <path d="M2 2L6 16L2 30" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
            </svg>
          </div>
        </div>
      </button>
    </div>
  );
};
