import { SlashIcon } from '@radix-ui/react-icons';
import { Link } from '@tanstack/react-router';
import { MenuIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '@/components/ui/sheet';
import { useOrg } from '@/contexts/OrgContext';
import { cn } from '@/lib/utils';

import { Menu } from './Menu';

export function SidebarMobile() {
  const { organizationName, organizationDisplayName } = useOrg();

  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="ghost" size="icon">
          <MenuIcon size={18} className="opacity-70" />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full flex-col px-3 sm:w-72" side="left">
        <SheetHeader>
          <Button className="flex items-center justify-start pb-2 pt-1" variant="link" asChild>
            <Link
              to={`/$organizationName`}
              params={{ organizationName: organizationName || '' }}
              className="flex items-center gap-2 ps-2"
            >
              <img
                src="/logomark_driver_bg.webp"
                alt="Driver"
                width={22}
                height={22}
                className={cn('transform transition-all ease-in-out hover:rotate-90 dark:invert')}
              />
              <SlashIcon className="h-4 w-4 opacity-50" />
              <div className="text-sm">{organizationDisplayName}</div>
            </Link>
          </Button>
        </SheetHeader>
        <hr className="-mx-3 border-t border-neutral-200 dark:border-neutral-800" />
        <Menu isOpen setIsOpen={() => {}} />
      </SheetContent>
    </Sheet>
  );
}
