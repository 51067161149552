import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import SettingsTOC from '@/components/Settings/SettingsTOC';
import Invitations from '@/components/User/Invitations';
import UserManagement from '@/components/User/Management/UserManagement';
import { Separator } from '@/components/ui/separator';
import { useOrg } from '@/contexts/OrgContext';
import useRolesChecks from '@/hooks/useRoleChecks';

export const Route = createFileRoute('/$organizationName/settings/users')({
  component: UsersPage,
});

function UsersPage() {
  const { organizationDisplayName } = useOrg();
  const { isAdmin } = useRolesChecks();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Users`;
  }, [organizationDisplayName]);

  if (!isAdmin) {
    return <div>You are not authorized to access this page</div>;
  }

  return (
    <DefaultLayout noContentPadding navbarTitle="Settings">
      <FlexiblePanelLayout
        pageType="settings"
        leftPanelContent={<SettingsTOC />}
        leftPanelContentClassName="px-1.5 bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800"
        leftPanelHeader={<LeftHeader name="Settings" />}
        headerClassList={['bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800']}
        mainPanelContent={
          <div className="mx-auto max-w-3xl flex-1 space-y-10 p-6">
            <div>
              <div className="mx-auto flex flex-col items-start space-y-1">
                <h1 className="text-3xl font-normal text-primary">Users</h1>
                <p className="text-muted-foreground">Manage your users</p>
              </div>
              <div id="users" className="mt-2">
                <Separator />
              </div>
            </div>
            <UserManagement />
            <div id="invitations">
              <Separator />
            </div>
            <Invitations />
          </div>
        }
      />
    </DefaultLayout>
  );
}

export default UsersPage;
