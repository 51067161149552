import { Table } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { BaseTable } from '@/components/BaseTable/BaseTable';

import { DEFAULT_PAGES_COLUMN_VISIBILITY } from './constants';
import { pageColumns } from './document-columns';
import { useDocumentsTable } from './useDocumentsTable';

interface PagesTableProps {
  toolbarContent?: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => ReactNode;
  defaultColumnVisibility?: Record<string, boolean>;
}

export function PagesTable({ toolbarContent, defaultColumnVisibility }: PagesTableProps) {
  const { table, data, isLoading } = useDocumentsTable({
    columns: pageColumns,
    kind: [PrimaryAsset.PAGE],
    initialState: {
      columnVisibility: defaultColumnVisibility || DEFAULT_PAGES_COLUMN_VISIBILITY,
    },
  });

  return <BaseTable table={table} isLoading={isLoading} data={data} toolbarContent={toolbarContent} />;
}
