import { useNavigate } from '@tanstack/react-router';
import { ChevronRight, LucideIcon, MoreHorizontal } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

type Submenu = {
  href: string;
  label: string;
  active: boolean;
  hex_color?: string;
};

interface CollapseMenuButtonProps {
  icon: LucideIcon;
  label: string;
  active: boolean;
  submenus: Submenu[];
  isOpen: boolean | undefined;
}

export function CollapseMenuButton({ icon: Icon, label, active, submenus, isOpen }: CollapseMenuButtonProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const visibleSubmenus = submenus.slice(0, 5);
  const hasMoreSubmenus = submenus.length > 5;

  const SubMenuContent = () => (
    <Command>
      <CommandInput
        placeholder={`Filter ${label.toLowerCase()}...`}
        className="h-auto border-none py-2 pl-0 focus:ring-0 active:ring-0"
      />
      <CommandList>
        <CommandEmpty>No tags found.</CommandEmpty>
        <CommandGroup>
          {submenus.map(({ href, label: submenuLabel, hex_color }, index) => (
            <CommandItem
              key={index}
              value={submenuLabel}
              onSelect={() => {
                navigate({ to: href });
                setOpen(false);
              }}
              className="cursor-pointer"
            >
              <div
                className="mr-2 h-1.5 w-1.5 rounded-full"
                style={{
                  backgroundColor: hex_color || '#eaeaea',
                }}
              />
              <p className="max-w-[180px] truncate">{submenuLabel}</p>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return isOpen ? (
    <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="mt-3 w-full">
      <CollapsibleTrigger className="[&[data-state=open]>div>div>svg]:rotate-90" asChild>
        <Button
          variant={active ? 'secondary' : 'ghost'}
          className={cn(
            'h-auto w-full justify-start py-1 font-normal',
            active ? 'bg-secondary-foreground/10 hover:bg-secondary-foreground/5' : 'hover:bg-secondary-foreground/5'
          )}
        >
          <div className="flex w-full items-center justify-start gap-1 text-muted-foreground">
            <div className="flex items-center">
              <div className={cn('truncate', isOpen ? 'translate-x-0 opacity-100' : 'hidden')}>{label}</div>
            </div>
            <div
              className={cn('whitespace-nowrap', isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0')}
            >
              <ChevronRight size={14} className="transition-transform duration-200" />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down relative max-h-[240px] overflow-hidden overflow-y-auto pl-1">
        {visibleSubmenus.map(({ href, label, active, hex_color }, index) => (
          <Button
            variant="ghost"
            className={cn(
              'relative h-auto w-full justify-start py-1.5 text-sm font-normal text-muted-foreground',
              active && 'font-medium text-primary'
            )}
            key={index}
            // TODO: fix types
            to={href as any}
          >
            <div
              className="mr-2.5 h-1.5 w-1.5 rounded-full"
              style={{
                backgroundColor: hex_color || '#eaeaea',
              }}
            ></div>
            <div className="truncate">{label}</div>
          </Button>
        ))}
        {hasMoreSubmenus && (
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                className="relative h-auto w-full justify-start px-2 py-1.5 text-sm font-normal text-muted-foreground"
              >
                <MoreHorizontal size={14} className="mr-2" />
                <div className="truncate">More</div>
              </Button>
            </PopoverTrigger>
            <PopoverContent side="right" sideOffset={16} align="start" className="w-[200px] p-0">
              <SubMenuContent />
            </PopoverContent>
          </Popover>
        )}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button variant={active ? 'secondary' : 'ghost'} className={cn('w-full justify-start')}>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <span className={cn(isOpen === false ? '' : 'mr-3')}>
                      <Icon size={16} />
                    </span>
                    <p className={cn('max-w-[200px] truncate', isOpen === false ? 'opacity-0' : 'opacity-100')}>
                      {label}
                    </p>
                  </div>
                </div>
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="center" sideOffset={16}>
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <PopoverContent side="right" sideOffset={16} align="start" className="w-[200px] p-0">
        <SubMenuContent />
      </PopoverContent>
    </Popover>
  );
}
