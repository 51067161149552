// cspell:ignore navpanes
export function PDFPreview({ url }: { url?: string }) {
  if (!url) return null;
  return (
    <div className="relative mx-auto h-full w-full max-w-3xl flex-grow">
      <iframe
        src={`${url}#view=FitH&navpanes=0`}
        className="absolute inset-0 h-full w-full rounded bg-background ring-1 ring-border"
        title="PDF Viewer"
      />
    </div>
  );
}
