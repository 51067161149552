import { Eclipse, Moon, SunMedium } from 'lucide-react';
import { useTheme } from 'next-themes';

import { Button } from '@/components/ui/button';

export function ThemeToggle() {
  const { setTheme, theme } = useTheme();
  const displayTheme = theme ? theme.charAt(0).toUpperCase() + theme.slice(1) : '';

  return (
    <Button
      className="w-full justify-start ps-1"
      variant="ghost"
      onClick={() => {
        if (theme === 'dark') {
          setTheme('light');
        } else if (theme === 'light') {
          setTheme('system');
        } else {
          setTheme('dark');
        }
      }}
    >
      <div className="flex h-6 w-6 items-center justify-center">
        <Moon
          className={`h-[1rem] w-[1rem] transition-transform duration-500 ease-in-out ${theme === 'dark' ? 'scale-100' : 'scale-0'}`}
        />
        <SunMedium
          className={`absolute h-[1rem] w-[1rem] transition-transform duration-500 ease-in-out ${theme === 'light' ? 'scale-100' : 'scale-0'}`}
        />
        <Eclipse
          className={`absolute h-[1rem] w-[1rem] transition-transform duration-500 ease-in-out ${theme === 'system' ? 'scale-100' : 'scale-0'}`}
        />
      </div>
      <span className="ml-2 text-sm font-normal">
        Change theme <span className="text-muted-foreground">({displayTheme})</span>
      </span>
      <span className="sr-only">Change Theme</span>
    </Button>
  );
}
