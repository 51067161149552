import { Link } from '@tanstack/react-router';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { decode } from 'he';
import { UserRoundPen } from 'lucide-react';
import React, { useMemo } from 'react';

import PreviewCard from './PreviewCard';

interface DocumentPreviewProps {
  nodeId: string;
  title: string;
  lastEdited: string;
  link: string;
  className?: string;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ nodeId, title, lastEdited, link, className }) => {
  const relativeTime = useMemo(() => {
    const date = parseISO(lastEdited);
    const now = new Date();
    const fourWeeksInMs = 4 * 7 * 24 * 60 * 60 * 1000; // 4 weeks in milliseconds
    if (now.getTime() - date.getTime() > fourWeeksInMs) {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };
      if (date.getFullYear() !== now.getFullYear()) {
        options.year = 'numeric';
      }
      return date.toLocaleDateString('en-US', options);
    }

    const distance = formatDistanceToNow(date, { addSuffix: true });
    return distance
      .replace(/(\d+)\s+(day|hour|week|month|year|minute)s?/g, (_, num, unit) => `${num}${unit[0]}`)
      .replace(/about /, '')
      .replace(/less than a minute ago/, 'Just now')
      .replace(/1m ago/, 'Just now')
      .replace(/less than /, '<')
      .replace(/over /, '>')
      .replace(/almost /, '~');
  }, [lastEdited]);

  return (
    <Link to={link}>
      <div className={`group flex w-64 flex-1 flex-col hover:cursor-pointer ${className}`}>
        <PreviewCard nodeId={nodeId} height={160} />
        <div className="mt-4 space-y-1">
          <div className="line-clamp-1 overflow-hidden text-sm font-medium text-foreground">{decode(title)}</div>
          <div className="flex items-center gap-1 text-muted-foreground">
            <UserRoundPen className="h-3 w-3" />
            <div className="text-xs">{relativeTime}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DocumentPreview;
