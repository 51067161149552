import { useAuth0 } from '@auth0/auth0-react';

interface User {
  isAdmin: boolean;
  isEditor: boolean;
}

const useRolesChecks = (): User => {
  const { isLoading, user } = useAuth0();
  const isAdmin = !isLoading && user?.['ai.driver.roles']?.includes('Admin');
  const isEditor = !isLoading && user?.['ai.driver.roles']?.includes('Editor');
  return { isAdmin, isEditor };
};

export default useRolesChecks;
