import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'lucide-react';

import { useResetPasswordMutation } from '@/api/api';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useLogout } from '@/hooks/useLogout';

import { UserAvatar } from './UserAvatar';

export function Profile() {
  const { user, isLoading } = useAuth0();
  const logout = useLogout();
  const { toast } = useToast();
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();

  const handleResetPassword = async () => {
    resetPassword()
      .then((res) => {
        if (res.error) {
          throw new Error('Failed to reset password');
        }
        toast({
          title: 'Password reset email sent',
          description: 'Check your email for instructions',
        });
      })
      .catch((err) => {
        toast({
          variant: 'destructive',
          title: 'Error resetting password',
          description: err.message,
        });
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center space-x-2">
        <User className="h-4 w-4" />
        <h2 className="text-xl font-medium">Profile</h2>
        <Button variant="outline" onClick={logout} className="!ml-auto self-end">
          Log out
        </Button>
      </div>
      <div className="">
        <div className="mb-4 flex items-center space-x-2">
          {user && <UserAvatar user={user} />}
          <div>
            <p className="font-medium">{user?.name || user?.email || 'Unnamed User'}</p>
          </div>
        </div>
        <div className="space-y-2">
          {user?.name && user?.name !== user?.email && (
            <div>
              <label className="text-xs font-medium text-muted-foreground">Name</label>
              <p className="text-base text-primary">{user.name}</p>
            </div>
          )}
          <div>
            <label className="text-xs font-medium text-muted-foreground">Email</label>
            <p className="text-base text-primary">{user?.email}</p>
          </div>
          <div>
            <label className="text-xs font-medium text-muted-foreground">Roles</label>
            <p className="text-base text-primary">{user?.['ai.driver.roles']?.join(', ') ?? 'No roles'}</p>
          </div>
          <div>
            <label className="text-xs font-medium text-muted-foreground">Password</label>
            <Button variant="outline" className="block" onClick={handleResetPassword} disabled={isResetPasswordLoading}>
              {isResetPasswordLoading ? 'Sending...' : 'Change password'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
