import React from 'react';

import { ReportData } from '@/components/LibraryTable/GenerateDocs/GenerationSidebarContent';
import BillingBar from '@/components/Settings/Usage/BillingBar';
import { Card, CardContent } from '@/components/ui/card';

import { getTopUsedLanguages } from './utils';

interface TopLanguagesProps {
  reportData: ReportData[];
}

function TopLanguages({ reportData }: TopLanguagesProps) {
  const topLanguages = getTopUsedLanguages(reportData, 4);

  const getColor = (index: number) => {
    const colors = ['bg-blue-200', 'bg-blue-400', 'bg-blue-600', 'bg-blue-800'].reverse();
    return colors[index];
  };

  const usageData = topLanguages.map((language, index) => ({
    percentage: language.percentage,
    color: getColor(index),
    count: language.sloc,
    label: language.language,
  }));

  return (
    <Card>
      <CardContent className="rounded-xl bg-neutral-50 p-4 dark:bg-neutral-900">
        <div className="flex justify-between">
          <h4 className="text-md mb-2">Top Languages</h4>
        </div>

        <div className="mt-2 flex justify-between align-middle text-sm">
          <div className="mt-1 flex-auto">
            <BillingBar billingData={usageData} />
          </div>
        </div>

        {topLanguages.map((repo, i) => {
          const color = usageData[i].color;
          return (
            <div key={repo.language} className="mt-2 flex justify-between text-sm">
              <div className="w-24 min-w-24">
                <div className={`h-2 w-2 ${color} mr-2 inline-block rounded-full`}></div>
                <span className="text-sm">{repo.language}</span>
              </div>
              <span className="min-w-12 text-sm">{repo.fileCount} files</span>
              <span className="min-w-12 text-sm">{Math.round(repo.percentage)}%</span>
              <code className="min-w-12 text-sm">{repo.sloc.toLocaleString()} sloc</code>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default TopLanguages;
