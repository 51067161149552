import { useAuth0 } from '@auth0/auth0-react';
import { useSearch } from '@tanstack/react-router';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface OrgContextProps {
  organizationId: string | null;
  organizationName: string | null;
  organizationDisplayName: string | null;
  userId: string | null;
  organizationLogoUrl: string | null;
}

const OrgContext = createContext<OrgContextProps | undefined>(undefined);

export const OrgProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [organizationDisplayName, setOrganizationDisplayName] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [organizationLogoUrl, setOrganizationLogoUrl] = useState<string | null>(null);
  const { loginWithRedirect, isAuthenticated, isLoading, error, getIdTokenClaims, logout } = useAuth0();
  const { invitation, organization, organization_name }: any = useSearch({
    strict: false,
  });

  useEffect(() => {
    (async () => {
      if (!isLoading && isAuthenticated) {
        if (invitation && organization) {
          // Log out the user and redirect to the invitation link
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}?invitation=${invitation}&organization=${organization}&organization_name=${organization_name}`,
            },
          });
        } else {
          const claims = await getIdTokenClaims();
          setOrganizationId(claims?.org_id ?? null);
          setOrganizationName(claims?.org_name ?? null);
          setOrganizationDisplayName(claims?.org_display_name ?? 'My Driver');
          setUserId(claims?.sub ?? null);
          setOrganizationLogoUrl(claims?.org_logo_url ?? null);
        }
      } else if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          authorizationParams: {
            organization: organization as string,
            invitation: invitation as string,
          },
        });
      } else if (!isLoading && error) {
        // TODO: Handle loading errors
      }
    })();
  }, [
    isLoading,
    isAuthenticated,
    error,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    invitation,
    organization,
    organization_name,
  ]);

  return (
    <OrgContext.Provider
      value={{
        organizationId,
        organizationName,
        organizationDisplayName,
        userId,
        organizationLogoUrl,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export const useOrg = () => {
  const context = useContext(OrgContext);
  if (context === undefined) {
    throw new Error('useOrg must be used within a OrgProvider');
  }
  return context;
};
