import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import InstructionPreview from './instruction/Preview';

export const InstructionPromptNodeName = 'smartInstruction';
export const InstructionPromptTagName = 'smart-instruction';

const createSmartInstructionPreviewNode = (name: string) => {
  return Node.create({
    name: name,
    group: 'block',
    atom: true,

    addAttributes() {
      return {
        prompt: {
          default: '',
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: InstructionPromptTagName,
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return [InstructionPromptTagName, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
      return ReactNodeViewRenderer(InstructionPreview);
    },
  });
};

export default createSmartInstructionPreviewNode;
