import { Navbar } from '@/components/Navbar/Navbar';
import { Sidebar } from '@/components/Sidebar';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useSidebarToggle } from '@/hooks/useSidebarToggle';
import { useStore } from '@/hooks/useStore';
import { cn } from '@/lib/utils';

export default function DefaultLayout({
  children,
  navbarTitle = '',
  noContentPadding = false,
}: {
  children: React.ReactNode;
  navbarTitle?: string;
  noContentPadding?: boolean;
}) {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  const isOpen = sidebar.isOpen ?? false;

  return (
    <>
      <Sidebar />
      <main
        className={cn(
          'min-h-screen bg-white transition-[margin-left] duration-300 ease-in-out dark:bg-neutral-950',
          isOpen ? 'lg:ml-56' : 'lg:ml-14'
        )}
      >
        <div className="lg:hidden">
          <Navbar title={navbarTitle} />
        </div>
        <ScrollArea className="h-[calc(100%-57px)] lg:h-screen">
          <div className={cn('', !noContentPadding && 'container-2xl p-4 sm:p-6 lg:px-20 xl:px-16 2xl:px-8')}>
            {children}
          </div>
        </ScrollArea>
      </main>
    </>
  );
}
