import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import SettingsTOC from '@/components/Settings/SettingsTOC';
import WorkspaceSettings from '@/components/Settings/WorkspaceSettings';
import TagsList from '@/components/TagsList/TagsList';
import { Separator } from '@/components/ui/separator';
import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/$organizationName/settings/workspace')({
  component: WorkspacePage,
});

function WorkspacePage() {
  const { organizationDisplayName } = useOrg();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Settings`;
  }, [organizationDisplayName]);

  return (
    <DefaultLayout noContentPadding navbarTitle="Settings">
      <FlexiblePanelLayout
        pageType="settings"
        leftPanelContent={<SettingsTOC />}
        leftPanelContentClassName="px-1.5 bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800"
        leftPanelHeader={<LeftHeader name="Settings" />}
        headerClassList={['bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800']}
        mainPanelContent={
          <div className="mx-auto max-w-3xl flex-1 space-y-10 p-6">
            <div>
              <div className="mx-auto flex flex-col items-start space-y-1">
                <h1 className="text-3xl font-normal text-primary">Workspace</h1>
                <p className="text-muted-foreground">Manage your settings</p>
              </div>
              <div id="workspace" className="mt-2">
                <Separator />
              </div>
            </div>

            <WorkspaceSettings />

            {/* <div id="collections">
              <Separator />
            </div>
            <TagsList type="collection" /> */}

            <div id="tags">
              <Separator />
            </div>

            <TagsList type="tag" />
          </div>
        }
      />
    </DefaultLayout>
  );
}
