import { useLocation, useNavigate } from '@tanstack/react-router';

import { useCreateDocumentMutation } from '@/api/api';
import { useOrg } from '@/contexts/OrgContext';
import { useUser } from '@/hooks/useUser';
import { trackPageCreated } from '@/utils/analytics';

import { useOrgUrlParams } from './useOrgUrlParams';

export const useCreateNewDocument = () => {
  const { organizationName } = useOrgUrlParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [createDocument] = useCreateDocumentMutation();
  const { userName, userId } = useUser();
  const { organizationId } = useOrg();

  const createNewDocument = async ({
    actionLocation,
    creationSource,
  }: {
    actionLocation: string;
    creationSource: 'blank' | 'template';
  }) => {
    try {
      const response = await createDocument();

      const { data } = response;
      if (!data) {
        throw new Error('Failed to create a new document.');
      }
      const { node_id } = data;

      const pageData = {
        pageId: node_id,
        title: 'New Page',
        userId: userId,
        userName: userName,
        orgId: organizationId!,
        timestamp: new Date().toISOString(),
        tags: [],
        pageHash: '',
      };
      trackPageCreated(pageData);

      const newPath = `/${organizationName}/page/${node_id}`;
      // TODO: Use router.push instead of window.location.href when we have a better way to handle the data
      // router.push(newPath);
      // NOTE: This is a temporary fix to prevent the new page from inheriting the content of the original page (if user is on a page)
      if (location.pathname.includes('/page/')) {
        window.location.href = newPath;
      } else {
        navigate({ to: '/$organizationName/page/$pageId', params: { organizationName, pageId: node_id } });
      }
    } catch (error) {
      console.error('Error creating new document:', error);
      throw error;
    }
  };

  return { createNewDocument };
};
