import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

const useScrollMask = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [maskStyle, setMaskStyle] = useState({});

  const updateMaskStyle = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 1; // -1 for potential rounding errors
      const isScrollable = scrollWidth > clientWidth;

      if (!isScrollable) {
        setMaskStyle({});
      } else if (scrollLeft === 0) {
        setMaskStyle({
          maskImage: 'linear-gradient(to left, transparent 0%, black 96px)',
        });
      } else if (isAtEnd) {
        setMaskStyle({
          maskImage: 'linear-gradient(to right, transparent 0%, black 96px)',
        });
      } else {
        setMaskStyle({
          maskImage: 'linear-gradient(to right, transparent 0%, black 96px, black calc(100% - 96px), transparent 100%)',
        });
      }
    }
  }, []);

  const debouncedUpdateMaskStyle = useCallback(() => {
    setTimeout(updateMaskStyle, 0);
  }, [updateMaskStyle]);

  useLayoutEffect(() => {
    debouncedUpdateMaskStyle();
    // Run it again after a short delay to catch any late updates
    const timer = setTimeout(debouncedUpdateMaskStyle, 1);
    return () => clearTimeout(timer);
  }, [debouncedUpdateMaskStyle]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedUpdateMaskStyle);
      return () => container.removeEventListener('scroll', debouncedUpdateMaskStyle);
    }
  }, [debouncedUpdateMaskStyle]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver(debouncedUpdateMaskStyle);
      resizeObserver.observe(container);
      return () => resizeObserver.disconnect();
    }
  }, [debouncedUpdateMaskStyle]);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateMaskStyle);
    return () => window.removeEventListener('resize', debouncedUpdateMaskStyle);
  }, [debouncedUpdateMaskStyle]);

  return { scrollContainerRef, maskStyle, updateMaskStyle: debouncedUpdateMaskStyle };
};

export default useScrollMask;
