import { Palette } from 'lucide-react';
import { useTheme } from 'next-themes';
import React from 'react';

const ThemeSelector: React.FC = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center space-x-2">
        <Palette className="h-4 w-4" />
        <h2 className="text-xl font-medium">Theme</h2>
      </div>
      <div className="flex space-x-4">
        <ThemeOption theme="light" currentTheme={theme} setTheme={setTheme} />
        <ThemeOption theme="dark" currentTheme={theme} setTheme={setTheme} />
        <ThemeOption theme="system" currentTheme={theme} setTheme={setTheme} />
      </div>
    </div>
  );
};

const ThemeOption: React.FC<{
  theme: 'light' | 'dark' | 'system';
  currentTheme: string | undefined;
  setTheme: (theme: string) => void;
}> = ({ theme, currentTheme, setTheme }) => {
  const getThemeClass = (width: string) => {
    const gradientPercentage =
      width === 'w-full' ? '50%' : width === 'w-[71.5%]' ? '70%' : width === 'w-10/12' ? '60%' : '50%';

    return `${width} h-2.5 ${
      theme === 'light'
        ? 'bg-neutral-300'
        : theme === 'dark'
          ? 'bg-neutral-600'
          : `bg-gradient-to-r from-neutral-300 from-${gradientPercentage} to-neutral-600 to-${gradientPercentage}`
    } rounded`;
  };

  const isSelected = currentTheme === theme;
  const selectedClass = isSelected ? 'opacity-100' : 'opacity-0 -translate-y-2';

  return (
    <div className="flex cursor-pointer flex-col items-center" onClick={() => setTheme(theme)}>
      <div
        className={`outline-3 h-16 w-40 rounded-lg border border-border outline outline-offset-4 ${
          theme === 'light'
            ? 'bg-white'
            : theme === 'dark'
              ? 'bg-neutral-950'
              : 'bg-gradient-to-r from-white from-50% to-neutral-950 to-50%'
        } p-2 ${isSelected ? 'outline-primary' : 'outline-none'} relative overflow-hidden`}
      >
        <div className="absolute inset-2 space-y-1.5 transition-all duration-300 ease-in-out">
          <div className={`${getThemeClass('w-full')}`}></div>
          <div className="flex">
            <div
              className={`h-2.5 w-1/2 ${theme !== 'dark' ? 'bg-neutral-300' : 'bg-neutral-600'} rounded-l transition-all duration-300 ${selectedClass}`}
            ></div>
            <div
              className={`h-2.5 w-[21.5%] ${theme !== 'light' ? 'bg-neutral-600' : 'bg-neutral-300'} rounded-r transition-all duration-300 ${selectedClass}`}
            ></div>
          </div>
          <div className="flex">
            <div
              className={`h-2.5 w-1/2 ${theme !== 'dark' ? 'bg-neutral-300' : 'bg-neutral-600'} rounded-l transition-all duration-300 ${selectedClass}`}
            ></div>
            <div
              className={`h-2.5 w-1/3 ${theme !== 'light' ? 'bg-neutral-600' : 'bg-neutral-300'} rounded-r transition-all duration-300 ${selectedClass}`}
            ></div>
          </div>
        </div>
      </div>
      <span className="mt-2 capitalize">{theme}</span>
    </div>
  );
};

export default ThemeSelector;
