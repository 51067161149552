import { useQuery } from '@apollo/client';
import { GitHubLogoIcon } from '@radix-ui/react-icons';
import { createFileRoute } from '@tanstack/react-router';
import { GitlabIcon, Loader2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { useGetGitProviderAppsQuery } from '@/api/api';
import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import { SimpleErrorMessage } from '@/components/Errors/SimpleErrorMessage';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import GitLabProviderForm from '@/components/GitIntegration/GitLabProviderForm';
import GitProviderList from '@/components/GitIntegration/GitProviderList';
import useGitHubAuth from '@/components/GitIntegration/hooks/useGitHubAuth';
import DefaultLayout from '@/components/LayoutDefault';
import SettingsTOC from '@/components/Settings/SettingsTOC';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useOrg } from '@/contexts/OrgContext';
import { GET_CONNECTED_GIT_PROVIDERS } from '@/graphql/queries';
import useRolesChecks from '@/hooks/useRoleChecks';

export const Route = createFileRoute('/$organizationName/settings/integrations')({
  component: AddTokenPage,
});

function AddTokenPage() {
  const { organizationDisplayName } = useOrg();
  const { handleButtonClick } = useGitHubAuth();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { isAdmin } = useRolesChecks();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Integrations`;
  }, [organizationDisplayName]);

  const {
    data: connectedProvidersData,
    loading: isLoadingGitHubProviders,
    error: gitHubProvidersError,
  } = useQuery(GET_CONNECTED_GIT_PROVIDERS);
  const hasConnectedGitHubProviders = connectedProvidersData?.connectedGitProviders.length > 0;

  const {
    data: gitProviderApps,
    isLoading: isLoadingGitProviderApps,
    error: gitProviderAppsError,
    refetch: refetchGitProviderApps,
  } = useGetGitProviderAppsQuery();

  const handleFormClose = () => {
    setIsDialogOpen(false);
    refetchGitProviderApps();
  };

  if (!isAdmin) {
    return <div>You are not authorized to access this page</div>;
  }

  return (
    <DefaultLayout noContentPadding navbarTitle="Settings">
      <FlexiblePanelLayout
        pageType="settings"
        leftPanelContent={<SettingsTOC />}
        leftPanelContentClassName="px-1.5 bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800"
        leftPanelHeader={<LeftHeader name="Settings" />}
        headerClassList={['bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800']}
        mainPanelContent={
          <div className="mx-auto max-w-3xl flex-1 space-y-10 p-6">
            <div>
              <div className="mx-auto flex flex-col items-start space-y-1">
                <h1 className="text-3xl font-normal text-primary">Integrations</h1>
                <p className="text-muted-foreground">Manage Git providers and access to repositories here.</p>
              </div>
              <div id="git-providers" className="mt-2">
                <Separator />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-lg font-medium">GitHub</h2>
                <p className="text-sm text-muted-foreground">Connect your GitHub repositories</p>
              </div>
              {gitHubProvidersError ? (
                <SimpleErrorMessage>Failed to load GitHub connection.</SimpleErrorMessage>
              ) : (
                <Button variant="secondary" size="default" onClick={handleButtonClick} className="">
                  <GitHubLogoIcon className="me-2.5 h-4 w-4" />
                  {isLoadingGitHubProviders ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : hasConnectedGitHubProviders ? (
                    'Edit Permissions'
                  ) : (
                    'Authorize'
                  )}
                </Button>
              )}
            </div>

            <div>
              <div className="mb-4 flex items-center justify-between">
                <div>
                  <h2 className="text-lg font-medium">GitLab Self-Managed</h2>
                  <p className="text-sm text-muted-foreground">Manage your Git provider integrations.</p>
                </div>
                {gitProviderAppsError ? (
                  <SimpleErrorMessage>Failed to load GitLab connection.</SimpleErrorMessage>
                ) : (
                  <Button variant="secondary" size="default" onClick={() => setIsDialogOpen(true)} className="">
                    <GitlabIcon className="me-2.5 h-4 w-4" />
                    Authorize
                  </Button>
                )}
              </div>

              <GitProviderList
                gitProviderApps={gitProviderApps || []}
                isLoading={isLoadingGitProviderApps}
                // We're already displaying the error so don't pass it in.
                // error={gitProviderAppsError}
                refetch={refetchGitProviderApps}
              />
              <GitLabProviderForm isOpen={isDialogOpen} onClose={handleFormClose} />
            </div>
          </div>
        }
      />
    </DefaultLayout>
  );
}
