import { Skeleton } from '@/components/ui/skeleton';

const SkeletonLoader: React.FC = () => {
  return (
    <div className="w-full overflow-hidden rounded-md border px-6 py-4">
      <div className="space-y-2">
        {[...Array(10)].map((_, index) => (
          <Skeleton key={index} className="h-7 w-[256px]" />
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
