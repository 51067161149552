import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { Sparkles } from 'lucide-react';

const InstructionPreview = (props: NodeViewProps) => {
  const { node } = props;

  return (
    <NodeViewWrapper className="relative my-4">
      <div className="relative rounded-lg border border-border" data-testid="instruction-preview">
        <div className="group/main rounded-lg px-4 py-6" data-testid="instruction-saved">
          <div className="flex w-full items-center justify-between">
            <div className="me-1 flex w-full items-center gap-3">
              <Sparkles className="h-4 w-4 flex-shrink-0 fill-indigo-500 text-indigo-500 dark:fill-indigo-400 dark:text-indigo-400" />
              <div className="w-full overflow-hidden border-none bg-transparent p-0 text-indigo-600 outline-none focus:ring-0 dark:text-indigo-300">
                {node.attrs.prompt}
              </div>
            </div>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default InstructionPreview;
