import { Copy, MoreHorizontal, Trash } from 'lucide-react';

import { Invitation, RoleName } from '@/api/types/organization';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface InvitationRowProps {
  invitation: Invitation;
  role?: RoleName;
  onCopyUrl: (url: string) => void;
  onDelete: (id: string) => void;
}

export const InvitationRow: React.FC<InvitationRowProps> = ({ invitation, role, onCopyUrl, onDelete }) => {
  return (
    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
      <td className="truncate p-2 align-middle">
        <div className="flex items-center gap-3">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex w-full flex-col">
                  <div className="truncate text-sm">{invitation.invitee.email}</div>
                  <div className="text-sm text-muted-foreground">{role}</div>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{invitation.invitee.email}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </td>
      <td className="p-2 align-middle">
        <Badge variant={invitation.expires_at < new Date().toISOString() ? 'destructive' : 'secondary'}>
          {invitation.expires_at < new Date().toISOString() ? 'Expired' : 'Pending'}
        </Badge>
      </td>
      <td className="p-2 align-middle">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span>{new Date(invitation.created_at).toLocaleDateString()}</span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{new Date(invitation.created_at).toLocaleString()}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </td>
      <td className="p-2 align-middle">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span>{new Date(invitation.expires_at).toLocaleDateString()}</span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{new Date(invitation.expires_at).toLocaleString()}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </td>
      <td className="p-2 align-middle">
        <div className="flex items-center gap-3">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="truncate text-sm">{invitation.inviter.name}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>{invitation.inviter.name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </td>
      <td className="p-2 align-middle">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onCopyUrl(invitation.invitation_url)}>
              <Copy className="mr-2 h-4 w-4" />
              Copy Invitation URL
            </DropdownMenuItem>
            <DropdownMenuItem className="text-destructive" onClick={() => onDelete(invitation.id)}>
              <Trash className="mr-2 h-4 w-4" />
              Revoke Invitation
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </td>
    </tr>
  );
};
