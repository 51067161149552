import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Columns, ListEnd, ListFilter, Tags, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

import { useGetPrimaryAssetQuery } from '@/api/api';
import { PrimaryAsset, PrimaryAssetRecord } from '@/api/types/node';
import { filterExcludedColumns } from '@/components/BaseTable/utils/table-utils';
import { ActiveFilters } from '@/components/Filters/ActiveFilters';
import { ColumnsFilter } from '@/components/Filters/ColumnsFilter';
import { DateFilter } from '@/components/Filters/DateFilter';
import { StatusFilter } from '@/components/Filters/StatusFilter';
import { TableFilter } from '@/components/Filters/TableFilter';
import { TagsFilter } from '@/components/Filters/TagsFilter';
import {
  DEFAULT_CODEBASE_COLUMN_VISIBILITY,
  DEFAULT_PDF_COLUMN_VISIBILITY,
  TableType,
} from '@/components/Filters/filter-constants';
import GettingStarted from '@/components/Guides/GettingStarted';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useDebounce } from '@/hooks/useDebounce';
import { libraryRouteApi } from '@/utils/route';

import { CodebaseTable } from './CodebaseTable';
import { GenerationSidebar } from './GenerateDocs/GenerationSidebar';
import { NewAssetAction } from './NewAssetAction';
import { PDFTable } from './PDFTable';
import { codebaseColumns, pdfColumns } from './columns';
import { useSourcesTable } from './useSourcesTable';

interface TableToolbarProps {
  table: Table<PrimaryAssetRecord>;
  data: PrimaryAssetRecord[];
  selectedType: TableType;
  onTypeChange: (type: TableType) => void;
  selectedColumns: string[];
  toggleColumn: (columnId: string) => void;
  setSelectedColumns: (columns: string[]) => void;
}

function TableToolbar({
  table,
  data,
  selectedType,
  onTypeChange,
  selectedColumns,
  toggleColumn,
  setSelectedColumns,
}: TableToolbarProps) {
  const [inputValue, setInputValue] = useState('');
  const navigate = libraryRouteApi.useNavigate();
  const debouncedSearchValue = useDebounce(inputValue, 1000);

  // Get the appropriate defaultColumnVisibility based on selectedType
  const defaultColumnVisibility = useMemo(() => {
    return selectedType === TableType.CODEBASE ? DEFAULT_CODEBASE_COLUMN_VISIBILITY : DEFAULT_PDF_COLUMN_VISIBILITY;
  }, [selectedType]);

  const handleSearchChange = (value: string) => {
    setInputValue(value);
  };

  // Update URL and column filter based on the debounced search value
  useEffect(() => {
    navigate({
      search: (prev) => ({
        ...prev,
        search: debouncedSearchValue,
      }),
      replace: true,
    });

    const column = table.getColumn('display_name');
    if (column) {
      column.setFilterValue(debouncedSearchValue);
    }
  }, [debouncedSearchValue, table, navigate]);

  // Get the current tag filters
  const tagFilters = table
    .getState()
    .columnFilters.filter((filter) => filter.id === 'tags')
    .map((filter) => filter.value as string[])
    .flat();

  // Check if there are any non-kind filters active
  const isFiltered = table.getState().columnFilters.some((filter) => {
    if (filter.id === 'kind') return false;
    if (filter.id === 'display_name') return false; // Exclude display_name from filtered state since we handle it via API
    if (Array.isArray(filter.value)) return filter.value.length > 0;
    return !!filter.value;
  });

  // Get all active filters
  const activeFilters = table.getState().columnFilters.reduce(
    (acc, filter) => {
      if (filter.id === 'kind' || filter.id === 'display_name') return acc;

      if (filter.id === 'created_at' || filter.id === 'updated_at') {
        const value = filter.value as { from: string; to: string };
        if (value?.from) acc[`${filter.id}__gte`] = value.from;
        if (value?.to) acc[`${filter.id}__lte`] = value.to;
        return acc;
      }

      if (filter.id === 'tags') {
        acc.tag_ids = filter.value as string[];
        return acc;
      }

      if (filter.id === 'status') {
        acc['versions.status'] = filter.value as string[];
        return acc;
      }

      acc[filter.id] = filter.value;
      return acc;
    },
    {} as Record<string, any>
  );

  // Fetch filtered count for codebases
  const { data: codebasesData, isFetching: isCodebasesFetching } = useGetPrimaryAssetQuery({
    kind: [PrimaryAsset.CODEBASE],
    limit: 1,
    offset: 0,
    ...activeFilters,
    tag_ids: tagFilters.length > 0 ? tagFilters : undefined,
    display_name__ilike: debouncedSearchValue ? `%${debouncedSearchValue}%` : undefined,
  });

  // Fetch filtered count for PDFs
  const { data: pdfsData, isFetching: isPDFsFetching } = useGetPrimaryAssetQuery({
    kind: [PrimaryAsset.FILE],
    limit: 1,
    offset: 0,
    ...activeFilters,
    tag_ids: tagFilters.length > 0 ? tagFilters : undefined,
    display_name__ilike: debouncedSearchValue ? `%${debouncedSearchValue}%` : undefined,
  });

  const isLoadingCounts = isCodebasesFetching || isPDFsFetching;

  const currentSelection = table.getState().rowSelection;
  const selectedAssetsIds = Object.entries(currentSelection)
    .filter(([_, value]) => value)
    .map(([key]) => key);
  const hasSelection = selectedAssetsIds.length > 0;

  return (
    <div className="flex flex-col gap-4" data-loading={isLoadingCounts}>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex flex-shrink-0 items-center space-x-2">
          <Button
            variant={selectedType === TableType.CODEBASE ? 'secondary' : 'ghost'}
            size="sm"
            onClick={() => onTypeChange(TableType.CODEBASE)}
            disabled={isLoadingCounts}
          >
            Codebases {!isLoadingCounts && `(${codebasesData?.total_count ?? 0})`}
            {isLoadingCounts && <span className="animate-pulse">(...)</span>}
          </Button>
          <Button
            variant={selectedType === TableType.PDF ? 'secondary' : 'ghost'}
            size="sm"
            onClick={() => onTypeChange(TableType.PDF)}
            disabled={isLoadingCounts}
          >
            PDFs {!isLoadingCounts && `(${pdfsData?.total_count ?? 0})`}
            {isLoadingCounts && <span className="animate-pulse">(...)</span>}
          </Button>
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <div className="flex flex-wrap items-center gap-1.5">
            {filterExcludedColumns(selectedColumns).map((columnId) => {
              const column = table.getColumn(columnId);
              if (!column) return null;

              const columns = selectedType === TableType.CODEBASE ? codebaseColumns : pdfColumns;
              const columnDef = columns.find((col) => col.id === columnId || (col as any).accessorKey === columnId);

              // Get the title from the column definition
              let columnTitle = columnId.replace(/_/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase());
              if (columnDef?.header && typeof columnDef.header === 'function') {
                try {
                  const headerComponent = columnDef.header({ column } as any);
                  if (headerComponent?.props?.title) {
                    columnTitle = headerComponent.props.title;
                  }
                } catch (e) {
                  console.error(e);
                }
              }

              if (columnId === 'created_at' || columnId === 'updated_at') {
                return (
                  <DateFilter
                    key={columnId}
                    column={column}
                    table={table}
                    showSelectedInTitle={false}
                    title={columnTitle}
                    routeApi={libraryRouteApi}
                  />
                );
              }

              if (columnId === 'status') {
                return <StatusFilter key={columnId} column={column} table={table} title={columnTitle} />;
              }

              return (
                <ActiveFilters
                  key={columnId}
                  column={column}
                  table={table}
                  title={columnTitle}
                  routeApi={libraryRouteApi}
                  columns={columns}
                />
              );
            })}
          </div>

          <div className="relative">
            <Input
              placeholder="Search assets..."
              value={inputValue}
              onChange={(event) => handleSearchChange(event.target.value)}
              className="h-8 w-[150px] flex-shrink-0 pr-8 sm:w-[180px] md:w-[200px] lg:w-[250px]"
            />
            {inputValue && (
              <button
                onClick={() => handleSearchChange('')}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
              >
                <X className="h-4 w-4" />
              </button>
            )}
          </div>

          <div className="flex flex-shrink-0 items-center gap-2">
            <ColumnsFilter
              table={table}
              title={
                <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                  <Columns className="h-4 w-4" />
                </Button>
              }
              columns={selectedType === TableType.CODEBASE ? codebaseColumns : pdfColumns}
              defaultColumnVisibility={defaultColumnVisibility}
            />
            <TableFilter
              table={table}
              selectedColumns={selectedColumns}
              toggleColumn={toggleColumn}
              title={
                <Button variant="ghost" size="sm" className="relative h-8 w-8 p-0">
                  <ListFilter className="h-4 w-4" />
                  {selectedColumns.length > 0 && (
                    <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-primary-foreground">
                      {selectedColumns.length}
                    </span>
                  )}
                </Button>
              }
              columns={selectedType === TableType.CODEBASE ? codebaseColumns : pdfColumns}
            />
            {table.getColumn('tags') && (
              <TagsFilter
                column={table.getColumn('tags')}
                title={
                  <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                    <Tags className="h-4 w-4" />
                  </Button>
                }
              />
            )}
          </div>

          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => {
                table.resetColumnFilters();
                setSelectedColumns([]);
                handleSearchChange('');
                navigate({
                  search: (prev) => ({
                    ...prev,
                    columnFilters: undefined,
                    selectedColumns: undefined,
                    search: undefined,
                  }),
                  replace: true,
                });
              }}
              className="h-8 flex-shrink-0 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}

          <div className="flex flex-shrink-0 items-center gap-2">
            {!hasSelection && <NewAssetAction />}
            {hasSelection && (
              <Button
                className="h-8"
                size="sm"
                onClick={() => {
                  const currentSelection = table.getState().rowSelection;
                  const selectedAssetsIds = Object.entries(currentSelection)
                    .filter(([_, value]) => value)
                    .map(([key]) => {
                      // Get the row data to access the actual ID
                      const row = table.getRow(key);
                      return row.original.id;
                    });

                  navigate({
                    search: (prev) => ({
                      ...prev,
                      generateRowIds: selectedAssetsIds,
                    }),
                  });
                }}
              >
                Generate Selected <ListEnd className="ml-2 h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface CurrentTableProps {
  toolbarContent: (table: Table<PrimaryAssetRecord>, data: PrimaryAssetRecord[]) => React.ReactNode;
  extraContent?: (data: PrimaryAssetRecord[]) => React.ReactNode;
  selectedType: TableType;
  selectedColumns: string[];
  toggleColumn: (columnId: string) => void;
}

function CurrentTableWrapper({ toolbarContent, extraContent, selectedType }: CurrentTableProps) {
  const CurrentTable = selectedType === TableType.CODEBASE ? CodebaseTable : PDFTable;
  const defaultColumnVisibility =
    selectedType === TableType.CODEBASE ? DEFAULT_CODEBASE_COLUMN_VISIBILITY : DEFAULT_PDF_COLUMN_VISIBILITY;

  return (
    <CurrentTable
      toolbarContent={toolbarContent}
      extraContent={extraContent}
      defaultColumnVisibility={defaultColumnVisibility}
    />
  );
}

export function SourcesTable() {
  const navigate = libraryRouteApi.useNavigate();
  const query = libraryRouteApi.useSearch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Get selectedType from URL or use default
  const selectedType = useMemo(() => {
    return (query.type as TableType) || TableType.CODEBASE;
  }, [query.type]);

  const { hasRecords, isLoading } = useSourcesTable({
    columns: selectedType === TableType.CODEBASE ? codebaseColumns : pdfColumns,
    tableType: selectedType,
  });

  // Get selectedColumns from URL
  const selectedColumns = useMemo(() => {
    return query.selectedColumns || [];
  }, [query.selectedColumns]);

  // Update sidebar state when generateRowIds changes in URL
  useEffect(() => {
    if (query.generateRowIds?.length > 0) {
      setIsSidebarOpen(true);
    }
  }, [query.generateRowIds]);

  const handleTypeChange = (type: TableType) => {
    navigate({
      search: (prev) => ({
        ...prev,
        type,
      }),
    });
  };

  const toggleColumn = (columnId: string) => {
    const newColumns = selectedColumns.includes(columnId)
      ? selectedColumns.filter((id) => id !== columnId)
      : [...selectedColumns, columnId];

    // Update URL with new selected columns
    navigate({
      search: (prev) => ({
        ...prev,
        selectedColumns: newColumns.length > 0 ? newColumns : undefined,
      }),
      replace: true,
    });
  };

  if (!hasRecords && !isLoading) {
    return (
      <div className="flex min-h-[90vh] flex-col items-start justify-center">
        <div className="mb-10 flex flex-col space-y-2">
          <h1 className="text-2xl font-semibold">Get started with Driver</h1>
        </div>
        <GettingStarted />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <CurrentTableWrapper
        selectedType={selectedType}
        selectedColumns={selectedColumns}
        toggleColumn={toggleColumn}
        toolbarContent={(table, data) => (
          <TableToolbar
            table={table}
            data={data}
            selectedType={selectedType}
            onTypeChange={handleTypeChange}
            selectedColumns={selectedColumns}
            toggleColumn={toggleColumn}
            setSelectedColumns={(columns) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  selectedColumns: columns.length > 0 ? columns : undefined,
                }),
                replace: true,
              });
            }}
          />
        )}
        extraContent={(data) => {
          const selectedRecords = data.filter((asset) => query.generateRowIds?.includes(asset.id));
          return (
            <GenerationSidebar
              data={selectedRecords}
              open={isSidebarOpen}
              onOpenChange={(open) => {
                setIsSidebarOpen(open);
                if (!open) {
                  navigate({
                    search: (prev) => ({
                      ...prev,
                      generateRowIds: undefined,
                    }),
                  });
                }
              }}
            />
          );
        }}
      />
    </div>
  );
}
