import { Link, useLocation } from '@tanstack/react-router';
import React from 'react';

import { useOrg } from '@/contexts/OrgContext';
import useRolesChecks from '@/hooks/useRoleChecks';

const SettingsTOC: React.FC = () => {
  const { isAdmin } = useRolesChecks();
  const { organizationName } = useOrg();
  const location = useLocation();

  const tocItems = [
    {
      id: 'workspace',
      label: 'Workspace',
      path: `/${organizationName}/settings/workspace`,
    },
    {
      id: 'account',
      label: 'Account',
      path: `/${organizationName}/settings/account`,
    },
  ];

  if (isAdmin) {
    tocItems.push({
      id: 'usage',
      label: 'Billing & Usage',
      path: `/${organizationName}/settings/usage`,
    });
    tocItems.push({
      id: 'integrations',
      label: 'Integrations',
      path: `/${organizationName}/settings/integrations`,
    });
    tocItems.push({
      id: 'users',
      label: 'Users',
      path: `/${organizationName}/settings/users`,
    });
  }
  return (
    <nav className="w-full">
      <ul className="sticky flex flex-col space-y-1">
        {tocItems.map((item) => (
          <li key={item.id}>
            <Link
              className={`ml-2 w-full justify-start text-sm ${
                location.pathname.split('/').slice(2).join('/').includes(item.path.split('/').slice(2).join('/'))
                  ? 'font-bold'
                  : ''
              }`}
              to={item.path}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SettingsTOC;
