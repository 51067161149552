import { AnimatePresence, motion } from 'framer-motion';
import { ChevronLeft, FolderCode, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { CodebaseUploadDropzone } from '@/components/UploadService/CodebaseUploadDropzone/CodebaseUploadDropzone';
import { SOC2Badge } from '@/components/UploadService/CodebaseUploadDropzone/SOC2Badge';
import PdfDropzone from '@/components/UploadService/PdfDropzone';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';

import SourceButton from './SourceButton';

interface AddSourceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  organizationName: string;
}

const AddSourceDialog: React.FC<AddSourceDialogProps> = ({ isOpen, onClose, organizationName }) => {
  const [showPdfDropzone, setShowPdfDropzone] = useState(false);
  const [showCodebaseDropzone, setShowCodebaseDropzone] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (!isOpen) {
      setShowPdfDropzone(false);
      setShowCodebaseDropzone(false);
    }
  }, [isOpen]);

  // const handleAddCodebase = () => {
  //   router.push(`/${organizationName}/new`);
  //   onClose();
  // };

  const handleUploadPDF = () => {
    setShowPdfDropzone(true);
  };

  const handlePdfBack = () => {
    setShowPdfDropzone(false);
  };

  const handleCodebaseUpload = () => {
    setShowCodebaseDropzone(true);
  };

  const handleCodebaseBack = () => {
    setShowCodebaseDropzone(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-fit overflow-hidden p-0">
        <div className="relative h-[450px] w-[705px]">
          <AnimatePresence initial={false} mode="wait">
            {!showPdfDropzone && !showCodebaseDropzone ? (
              <motion.div
                key="source-buttons"
                initial={{ opacity: 0, x: '-100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '-100%' }}
                transition={{ duration: 0.25, ease: 'easeInOut' }}
                className="flex h-full gap-4 p-6"
              >
                {/* <SourceButton
                  onClick={handleAddCodebase}
                  icon={FolderCode}
                  title="Connect a codebase"
                  subtitle="GitHub, zip"
                /> */}
                <SourceButton
                  onClick={handleCodebaseUpload}
                  icon={FolderCode}
                  title="Upload a codebase"
                  subtitle="zip"
                />
                <SourceButton onClick={handleUploadPDF} icon={Upload} title="Upload files" subtitle="pdf" />
              </motion.div>
            ) : showPdfDropzone ? (
              <motion.div
                key="dropzone"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '100%' }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                className="h-full p-6"
              >
                <div className="space-y-2">
                  <div className="flex items-center gap-1">
                    <Button onClick={handlePdfBack} variant="ghost" className="px-2">
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <div>Upload files</div>
                  </div>
                  <PdfDropzone />
                </div>
              </motion.div>
            ) : showCodebaseDropzone ? (
              <motion.div
                key="dropzone"
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '100%' }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                className="h-full p-6"
              >
                <div className="space-y-2">
                  <div className="flex items-center gap-1">
                    <Button onClick={handleCodebaseBack} variant="ghost" className="px-2">
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <div>Upload a codebase</div>
                  </div>
                  <CodebaseUploadDropzone
                    onSuccess={() => {
                      toast({
                        title: 'Success',
                        description: 'Codebase uploaded successfully',
                      });
                      onClose();
                    }}
                  />
                  <SOC2Badge />
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSourceDialog;
