import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import { Profile } from '@/components/Settings/Profile';
import SettingsTOC from '@/components/Settings/SettingsTOC';
import ThemeSelector from '@/components/Settings/ThemeSelector';
import { Separator } from '@/components/ui/separator';
import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/$organizationName/settings/account')({
  component: Account,
});

function Account() {
  const { organizationDisplayName } = useOrg();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Account`;
  }, [organizationDisplayName]);

  return (
    <DefaultLayout noContentPadding navbarTitle="Settings">
      <FlexiblePanelLayout
        pageType="settings"
        leftPanelContent={<SettingsTOC />}
        leftPanelContentClassName="px-1.5 bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800"
        leftPanelHeader={<LeftHeader name="Settings" />}
        headerClassList={['bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800']}
        mainPanelContent={
          <div className="mx-auto max-w-3xl flex-1 space-y-10 p-6">
            <div>
              <div className="mx-auto flex flex-col items-start space-y-1">
                <h1 className="text-3xl font-normal text-primary">Account</h1>
                <p className="text-muted-foreground">Manage your account</p>
              </div>
              <div id="workspace" className="mt-2">
                <Separator />
              </div>
            </div>
            <Profile />

            <Separator />

            <ThemeSelector />
          </div>
        }
      />
    </DefaultLayout>
  );
}

export default Account;
