import { LockClosedIcon } from '@heroicons/react/16/solid';

export function SOC2Badge() {
  return (
    <div className="flex items-center justify-center text-sm text-green-700 dark:text-green-400">
      <LockClosedIcon className="me-1 h-4 w-4" />
      SOC 2 Type II Compliant
    </div>
  );
}
