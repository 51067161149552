import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import LeftHeader from '@/components/CodebasePanels/LeftHeader';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import SettingsTOC from '@/components/Settings/SettingsTOC';
import RecentCharges from '@/components/Settings/Usage/RecentCharges';
import UsageDetails from '@/components/Settings/Usage/UsageDetails';
import { useOrg } from '@/contexts/OrgContext';
import useRolesChecks from '@/hooks/useRoleChecks';

export const Route = createFileRoute('/$organizationName/settings/usage')({
  component: UsagePage,
});

function UsagePage() {
  const { organizationDisplayName } = useOrg();
  const { isAdmin } = useRolesChecks();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Settings`;
  }, [organizationDisplayName]);

  if (!isAdmin) {
    return <div>You are not authorized to access this page</div>;
  }

  return (
    <DefaultLayout noContentPadding navbarTitle="Settings">
      <FlexiblePanelLayout
        pageType="settings"
        leftPanelContent={<SettingsTOC />}
        leftPanelContentClassName="px-1.5 bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800"
        leftPanelHeader={<LeftHeader name="Settings" />}
        headerClassList={['bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800']}
        mainPanelContent={
          <div className="mx-auto max-w-3xl flex-1 space-y-6 p-6">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-3xl font-normal text-primary">Billing & Usage</h1>
            </div>
            <p className="text-muted-foreground">Track your consumption and view your upcoming bill</p>
            <UsageDetails />
            <RecentCharges />
            {/* <NextBill /> */}
          </div>
        }
      />
    </DefaultLayout>
  );
}
