import * as React from 'react';
import TextareaAutosizeComponent, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { cn } from '@/lib/utils';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const textareaStyles =
  'flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50';

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  return <textarea className={cn(textareaStyles, className)} ref={ref} {...props} />;
});
Textarea.displayName = 'Textarea';

// TextareaAutosize component that uses the same styling as the Textarea component
const TextareaAutosize = React.forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  ({ className, ...props }, ref) => {
    return <TextareaAutosizeComponent data-slot="textarea" className={cn(textareaStyles, className)} {...props} />;
  }
);
TextareaAutosize.displayName = 'TextareaAutosize';

export { Textarea, TextareaAutosize };
