export const getDateRange = (range: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  const lastWeek = new Date(today);
  lastWeek.setDate(lastWeek.getDate() - 7);

  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const last3Months = new Date(today);
  last3Months.setMonth(last3Months.getMonth() - 3);

  const last6Months = new Date(today);
  last6Months.setMonth(last6Months.getMonth() - 6);

  const lastYear = new Date(today);
  lastYear.setFullYear(lastYear.getFullYear() - 1);

  switch (range) {
    case 'today':
      return {
        from: today.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'yesterday':
      return {
        from: yesterday.toISOString(),
        to: new Date(today.getTime() - 1).toISOString(),
      };
    case 'last2days':
      return {
        from: twoDaysAgo.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'lastweek':
      return {
        from: lastWeek.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'lastmonth':
      return {
        from: lastMonth.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'last3months':
      return {
        from: last3Months.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'last6months':
      return {
        from: last6Months.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    case 'lastyear':
      return {
        from: lastYear.toISOString(),
        to: new Date(today.getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999).toISOString(),
      };
    default:
      return undefined;
  }
};
