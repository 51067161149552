import { CheckIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode, useState } from 'react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { filterExcludedColumns } from '@/components/BaseTable/utils/table-utils';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';

interface TableFilterProps {
  table: Table<PrimaryAssetRecord>;
  title: ReactNode;
  selectedColumns: string[];
  toggleColumn: (columnId: string) => void;
  columns: ColumnDef<PrimaryAssetRecord>[];
}

export function TableFilter({ table, title, selectedColumns, toggleColumn, columns }: TableFilterProps) {
  const [search, setSearch] = useState('');
  const columnVisibility = table.getState().columnVisibility;
  const filterableColumns = table.getAllColumns().filter((column) => column.getCanFilter());
  const visibleColumns = filterableColumns.filter((column) => columnVisibility[column.id]);
  const filteredColumns = filterExcludedColumns(visibleColumns.map((col) => col.id));

  const filteredColumnsList = filteredColumns.filter((columnId) => {
    const columnDef = columns.find((col) => (col.id || (col as any).accessorKey) === columnId);
    if (!columnDef) return false;

    const columnTitle =
      columnDef.header && typeof columnDef.header === 'function'
        ? (columnDef as any).header({ column: table.getColumn(columnId) }).props.title
        : columnId.replace(/_/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase());

    return columnTitle.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Popover>
      <PopoverTrigger asChild>{title}</PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput
            className="border-none ps-0 focus:ring-0"
            placeholder="Filter columns..."
            value={search}
            onValueChange={setSearch}
          />
          <CommandList>
            <CommandEmpty>No columns found.</CommandEmpty>
            <CommandGroup>
              {filteredColumnsList.map((columnId) => {
                const column = table.getColumn(columnId);
                if (!column) return null;

                const columnDef = columns.find((col) => (col.id || (col as any).accessorKey) === columnId);
                if (!columnDef) return null;

                const isSelected = selectedColumns.includes(columnId);
                const columnTitle =
                  columnDef.header && typeof columnDef.header === 'function'
                    ? (columnDef as any).header({ column }).props.title
                    : columnId.replace(/_/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase());

                return (
                  <CommandItem
                    key={columnId}
                    onSelect={() => toggleColumn(columnId)}
                    className="flex items-center gap-2"
                  >
                    <div
                      className={cn(
                        'flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className="h-4 w-4" />
                    </div>
                    <span>{columnTitle}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
