import { ChevronDown, ChevronUp, FileCode2, FileSpreadsheet } from 'lucide-react';
import { useState } from 'react';

import { CodebaseUploadDropzone } from '@/components/UploadService/CodebaseUploadDropzone/CodebaseUploadDropzone';
import { SOC2Badge } from '@/components/UploadService/CodebaseUploadDropzone/SOC2Badge';
import PdfDropzone from '@/components/UploadService/PdfDropzone';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useToast } from '@/components/ui/use-toast';

export function NewAssetAction() {
  const [isOpen, setIsOpen] = useState(false);
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [isCodebaseDialogOpen, setIsCodebaseDialogOpen] = useState(false);
  const { toast } = useToast();

  const handleNewCodebase = () => {
    setIsCodebaseDialogOpen(true);
  };

  const handleNewFile = () => {
    setIsFileDialogOpen(true);
  };

  return (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="default" size="sm">
            Add
            {isOpen ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48 p-1.5">
          <DropdownMenuItem onClick={handleNewCodebase} className="py-2 hover:cursor-pointer">
            <FileCode2 className="mr-3 h-4 w-4" />
            <span>Upload Codebase</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleNewFile} className="py-2 hover:cursor-pointer">
            <FileSpreadsheet className="mr-3 h-4 w-4" />
            <span>Upload PDF</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isCodebaseDialogOpen} onOpenChange={setIsCodebaseDialogOpen}>
        <DialogContent className="w-[75vw] max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="font-medium">Upload a codebase file</div>
            </DialogTitle>
            <DialogDescription className="sr-only">.zip files only</DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <CodebaseUploadDropzone
              onSuccess={() => {
                toast({
                  title: 'Success',
                  description: 'Codebase uploaded successfully',
                });
                setIsCodebaseDialogOpen(false);
              }}
            />
          </div>
          <SOC2Badge />
        </DialogContent>
      </Dialog>

      <Dialog open={isFileDialogOpen} onOpenChange={setIsFileDialogOpen}>
        <DialogContent className="w-[75vw] max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="font-medium">Upload files</div>
            </DialogTitle>
            <DialogDescription className="sr-only">.pdf files only</DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <PdfDropzone
              onSuccess={() => {
                toast({
                  title: 'Success',
                  description: 'File uploaded successfully',
                });
                setIsFileDialogOpen(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
