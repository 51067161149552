import { ArrowUpRightIcon } from 'lucide-react';
import React from 'react';

import { ReportData } from '@/components/LibraryTable/GenerateDocs/GenerationSidebarContent';
import AnalysisTooltipPopover from '@/components/Settings/Usage/AnalysisTooltipPopover';
import BillingBar from '@/components/Settings/Usage/BillingBar';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useOrg } from '@/contexts/OrgContext';

interface ComputedUsageProps {
  used: number;
  toBeUsed: number;
  total: number;
  isOverage: boolean;
  reportData: ReportData[];
}

function ComputedUsage({ used, toBeUsed, total, isOverage, reportData }: ComputedUsageProps) {
  const { organizationDisplayName, organizationName } = useOrg();
  const remaining = total - used - toBeUsed;
  const remainingMin = isOverage ? 0 : remaining;
  const remainingPercentage = (remainingMin / total) * 100;

  const codebaseUsageData = reportData.map((repo) => ({
    percentage: ((repo.reportData?.analyzable_sloc ?? 0) / total) * 100,
    color: repo.color,
    count: repo.reportData?.analyzable_sloc ?? 0,
    label: repo.repoName,
  }));

  const usageData = [
    {
      percentage: (used / total) * 100,
      color: 'bg-blue-500',
      count: used,
      label: 'Used',
    },
    ...codebaseUsageData,
    {
      percentage: remainingPercentage,
      color: 'bg-gray-500',
      count: remaining,
      label: 'Remaining',
    },
  ];

  const sendEmail = () => {
    const subject = 'Increase usage limit';
    const orgName = organizationDisplayName ?? organizationName;
    const neededSloc = Intl.NumberFormat().format(Math.abs(remaining));
    const body = `Hello,

I would like to increase my usage limit for my organization ${orgName}.

I need an additional ${neededSloc} SLOC to process these sources.

Thank you,
    `;
    const recipient = 'support@driver.ai';

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Card>
      <CardContent className="rounded-xl bg-neutral-50 p-4 dark:bg-neutral-900">
        <div className="flex justify-between">
          <h4 className="text-md mb-2">
            Computed line usage
            <AnalysisTooltipPopover />
          </h4>
          {isOverage && (
            <Button variant="outline" size="xs" onClick={sendEmail}>
              Adjust limit
              <ArrowUpRightIcon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        {!isOverage && (
          <p className="mb-4 text-sm text-muted-foreground">Importing this source stays within your monthly limit.</p>
        )}
        {isOverage && (
          <p className="mb-4 text-sm text-muted-foreground">
            Importing this source would exceed your limit by {new Intl.NumberFormat().format(Math.abs(remaining))} SLOC.
          </p>
        )}
        <div className="mt-2 flex justify-between align-middle text-sm">
          <div className="mt-1 flex-auto">
            <BillingBar billingData={usageData} />
          </div>
        </div>
        <div className="mb-4 mt-2 flex justify-between text-sm">
          <div className="flex font-bold">Total:</div>
          <div className="flex items-center space-x-2 font-mono text-muted-foreground">
            <code>{(used + toBeUsed).toLocaleString()}</code>
            <code> / </code>
            <code>{total?.toLocaleString()}</code>
          </div>
        </div>

        <div className="mt-2 flex justify-between text-sm">
          <div>
            <div className={`mr-2 inline-block h-2 w-2 rounded-full bg-blue-500`}></div>
            <span className="text-sm">Year to date</span>
          </div>
          <div className="flex items-center space-x-2 font-mono text-muted-foreground">
            <code>{used.toLocaleString()}</code>
          </div>
        </div>
        {reportData.map((repo, i) => {
          return (
            <div key={repo.repoName} className="mt-2 flex justify-between text-sm">
              <div>
                <div className={`h-2 w-2 ${repo.color} mr-2 inline-block rounded-full`}></div>
                <span className="text-sm">{repo.repoName}</span>
              </div>
              <div className="flex items-center space-x-2 font-mono text-muted-foreground">
                <code>{repo.reportData?.analyzable_sloc?.toLocaleString()}</code>
              </div>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default ComputedUsage;
