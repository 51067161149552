import { Dashboard } from '@uppy/react';
import * as React from 'react';

import useUppyCodebaseUpload from './useUppyCodebaseUpload';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

interface CodebaseUploadDropzoneProps {
  onSuccess?: () => void;
}

export const CodebaseUploadDropzone: React.FC<CodebaseUploadDropzoneProps> = ({ onSuccess }) => {
  const uppy = useUppyCodebaseUpload();

  React.useEffect(() => {
    uppy.on('upload-success', () => {
      onSuccess?.();
    });
  }, [uppy, onSuccess]);

  return (
    <div className="relative">
      <Dashboard
        id="codebase-upload-dropzone"
        uppy={uppy}
        plugins={['Webcam']}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={false}
        theme="light"
        height={340}
        width="100%"
        note="ZIP files only"
        locale={{
          strings: {
            dropPasteFiles: 'Drag and drop files here or %{browse}',
            browseFiles: 'click to upload',
            dropHint: 'Drop to upload your file',
            cancel: 'Remove file',
            done: 'Add another codebase',
            xFilesSelected: {
              0: '',
              1: '',
            },
            uploadComplete: 'Success',
          },
          pluralize: (n) => (n === 1 ? 0 : 1),
        }}
        className="!border-none"
      />
      <div className="pointer-events-none absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white/5" />
      </div>
    </div>
  );
};
