import { useSidebarToggle } from '@/hooks/useSidebarToggle';
import { useStore } from '@/hooks/useStore';
import { cn } from '@/lib/utils';

import { Menu } from './Menu';
import { SidebarHeader } from './SidebarHeader';

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  const isOpen = sidebar.isOpen ?? false;
  const setIsOpen = sidebar.setIsOpen;

  const handleBorderClick = () => {
    setIsOpen();
  };

  return (
    <aside
      className={cn(
        'fixed left-0 top-0 z-20 h-screen -translate-x-full transition-[width] duration-300 ease-in-out lg:translate-x-0',
        isOpen ? 'w-56' : 'w-14'
      )}
    >
      <div className="relative flex h-full flex-col overflow-hidden overflow-y-auto border-r border-neutral-200 bg-neutral-50 px-2 pb-2 dark:border-neutral-800 dark:bg-neutral-900">
        <SidebarHeader isOpen={isOpen} />
        <hr className="-mx-2 mb-4 border-t border-neutral-200 dark:border-neutral-800" />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div
        className={cn(
          'group absolute -right-1 top-0 h-full w-0.5 px-1',
          isOpen ? 'cursor-w-resize' : 'cursor-e-resize'
        )}
        onClick={handleBorderClick}
      >
        <div className={cn('absolute left-0.5 top-0 h-full w-0.5 transition-colors group-hover:bg-primary/30')}></div>
      </div>
    </aside>
  );
}
