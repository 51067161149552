import React, { ReactNode } from 'react';

interface MainContentProps {
  content: ReactNode;
  header?: ReactNode;
}

export const MainPanel: React.FC<MainContentProps> = ({ content, header }) => {
  return (
    <div className="flex flex-grow flex-col overflow-hidden">
      {header && (
        <div className="mx-auto w-full max-w-3xl">
          <div className="mx-4 h-[56px] flex-shrink-0">{header}</div>
        </div>
      )}
      <div id="main-panel" className="flex-grow overflow-auto p-4">
        {content}
      </div>
    </div>
  );
};
