import { formatDistanceToNow, isValid, parseISO } from 'date-fns';

export const formatRelativeDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isValid(date)) {
    const relativeDate = formatDistanceToNow(date, {
      addSuffix: true,
    });
    return relativeDate === 'less than a minute ago' ? 'just now' : `${relativeDate.replace(/^about /, '')}`;
  }
  return 'Unknown';
};

export const formatSmartRelativeDate = (dateString: string): string => {
  const date = parseISO(dateString);
  const now = new Date();
  const fourWeeksInMs = 4 * 7 * 24 * 60 * 60 * 1000; // 4 weeks in milliseconds

  if (now.getTime() - date.getTime() > fourWeeksInMs) {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  }

  const distance = formatDistanceToNow(date, { addSuffix: true });
  return distance
    .replace(/less than a minute ago/, 'Just now')
    .replace(/1m ago/, 'Just now')
    .replace(/(\d+)\s+(day|hour|week|month|year|minute)s?/g, (_, num, unit) => `${num}${unit[0]}`)
    .replace(/about /, '')
    .replace(/less than /, '<')
    .replace(/over /, '>')
    .replace(/almost /, '~');
};
