import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { ReactElement, ReactNode } from 'react';

import security from '@/api/security';

export default function Auth0Apollo({ children }: { children: ReactNode }): ReactElement {
  const { getAccessTokenSilently } = security;
  // TODO: Error handling?

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently()();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
    const httpLink = createHttpLink({
      uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently]);

  return <ApolloProvider client={client}> {children} </ApolloProvider>;
}
