import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

import { useGetVersionsQuery } from '@/api/api';
import { BrowsableVersionStatuses, VersionRecord } from '@/api/types/node';
import * as DropdownMenu from '@/components/ui/dropdown-menu';
import { useSyncSearchParams } from '@/hooks/useSyncSearchParams';

interface LeftHeaderProps {
  name?: string;
  className?: string;
}

const getMostRecentVersion = (versions: VersionRecord[]) => {
  return [...versions].sort((a, b) => {
    return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
  })?.[0];
};

const LeftHeader: React.FC<LeftHeaderProps> = ({ name, className }) => {
  const { codebaseId } = useParams({ strict: false });
  const navigate = useNavigate();
  const params = useSyncSearchParams();
  const currentVersion = params?.get('versionId') || null;
  const [selectedVersion, setSelectedVersion] = useState<string | null>(currentVersion);

  const { data: versionsData } = useGetVersionsQuery(
    {
      primary_asset_id: codebaseId || '',
      limit: 10,
      sort_by: 'created_at',
      status: BrowsableVersionStatuses,
    },
    { skip: !codebaseId }
  );

  const versions = versionsData?.results ?? [];
  const hasVersions = versions.length > 0;
  const version = versions.find((v) => v.id === selectedVersion);
  const mostRecentVersion = getMostRecentVersion(versions);
  const versionDisplayName = version?.display_name || 'Select Version';

  useEffect(() => {
    // default to most recent version if no version is in the url
    if (currentVersion === null && mostRecentVersion) {
      setSelectedVersion(mostRecentVersion?.id);
      // update the url
      const url = `${window.location.pathname}?versionId=${mostRecentVersion.id}`;
      navigate({ to: url, replace: true });
    }
    // otherwise, use the version in the url
    if (currentVersion) {
      setSelectedVersion(currentVersion);
    }
  }, [currentVersion, mostRecentVersion, navigate]);

  return (
    <div className={`flex flex-col px-2 pb-4 pt-4 ${className}`}>
      <h2 className="mb-2 truncate text-sm text-primary">{name}</h2>
      <div className="flex items-center gap-2">
        {hasVersions && (
          <DropdownMenu.DropdownMenu>
            <DropdownMenu.DropdownMenuTrigger className="flex h-8 w-48 max-w-48 gap-2 rounded-md border border-input bg-background px-3 pt-1.5 text-sm font-medium hover:bg-accent hover:text-accent-foreground">
              <span className="truncate text-smaller">{versionDisplayName}</span>
              <ChevronDownIcon className="h-4 w-4" />
            </DropdownMenu.DropdownMenuTrigger>
            <DropdownMenu.DropdownMenuContent scrollable>
              {versions.map((version) => (
                <DropdownMenu.DropdownMenuItem
                  key={version.id}
                  onClick={() => {
                    setSelectedVersion(version.id);
                    const currentPath = window.location.pathname;
                    navigate({ to: `${currentPath}?versionId=${version.id}` });
                  }}
                >
                  <span className="max-w-24 truncate text-smaller">{version.display_name}</span>
                </DropdownMenu.DropdownMenuItem>
              ))}
            </DropdownMenu.DropdownMenuContent>
          </DropdownMenu.DropdownMenu>
        )}
      </div>
    </div>
  );
};

export default LeftHeader;
