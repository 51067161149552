// cspell:ignore couldn
import { Link, createFileRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/404')({
  component: NotFound404,
});

export function NotFound404() {
  useEffect(() => {
    document.title = '404 - Driver';
  }, []);

  const [key, setKey] = useState(0);

  const handleClick = () => {
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <main key={key} className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-neutral-600">
            Sorry, we couldn&apos;t find the page you&apos;re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/">
              <div
                onClick={handleClick}
                className="rounded-md bg-neutral-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600"
              >
                Back to homepage
              </div>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
