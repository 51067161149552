import { ChevronDown, ChevronUp, FileText, SquarePen } from 'lucide-react';
import { useState } from 'react';

import { useCreateTemplate } from '@/components/Templates/useCreateTemplate';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useToast } from '@/components/ui/use-toast';
import { useCreateNewDocument } from '@/hooks/useCreateNewPage';

export function NewDocumentAction() {
  const [isOpen, setIsOpen] = useState(false);
  const { createNewDocument } = useCreateNewDocument();
  const { createTemplate } = useCreateTemplate();
  const { toast } = useToast();

  const handleCreatePage = () => {
    createNewDocument({ actionLocation: 'documents_table', creationSource: 'blank' })
      .then(() => {
        toast({
          title: 'Page created',
          description: 'Your new page is ready to use.',
          variant: 'default',
        });
      })
      .catch((error) => {
        toast({
          title: 'Failed to create Page',
          description: 'Please try again.',
          variant: 'destructive',
        });
      });
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="default" size="sm">
          New
          {isOpen ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-48 p-1.5">
        <DropdownMenuItem onClick={handleCreatePage} className="py-2 hover:cursor-pointer">
          <SquarePen className="mr-3 h-4 w-4" />
          <span>New Page</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={createTemplate} className="py-2 hover:cursor-pointer">
          <FileText className="mr-3 h-4 w-4" />
          <span>New Template</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
