import he from 'he';
import * as marked from 'marked';

import { ContentRecord } from '@/api/types/node';

marked.setOptions({
  breaks: true,
});

const isJsonString = (content: any): boolean => {
  try {
    JSON.parse(content);
    return true;
  } catch (error) {
    return false;
  }
};

const isEncoded = (content: any): boolean => {
  try {
    const decoded = he.decode(content, {
      strict: true,
    });
    if (decoded === content) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const isMarkdown = (content: any): boolean => {
  try {
    const parsed = marked.parse(content, { silent: false });
    if (parsed === content) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @deprecated Make this a private function and use transformLibraryContent instead
 */
export const formatEditorContent = (content: any): any => {
  if (typeof content === 'string') {
    const decodedContent = he.decode(content);

    if (isJsonString(decodedContent)) {
      return JSON.parse(decodedContent);
    } else {
      return marked.parse(decodedContent).toString();
    }
  }
  return content;
};

// FUTURE:
// A) Stop calling this so many times.
//   i) We should only call it once after the initial API call finishes
//   ii) If that isn't do-able then useMemo
// B) Detecting the content type is problematic and leads to unexpected parsing.
//   i) For instance in PE-2028, no transforms were needed but it detected properly encoded HTML and then decoded it which lead to issues when the HTML was inserted back into the editor.
//   ii) We need to do a better job of knowing the format of the content we're accessing, not guessing.
export const transformLibraryContent = (
  record: ContentRecord | { content: any; content_name: any; node?: any } | undefined | null
): any => {
  if (!record) return { name: '', content: '' };
  const name = record.content_name ?? record?.node?.version?.primary_asset?.display_name;
  if (record.content === ' ') return { name: name, content: '' };
  if (record.content === '') return { name: name, content: '' };
  const json = isJsonString(record.content);
  const markdown = isMarkdown(record.content);
  const encoded = isEncoded(record.content);

  if (json) {
    const parsedContent = JSON.parse(record?.content);
    const encoded = isEncoded(parsedContent?.content);
    if (encoded) {
      const decoded = he.decode(parsedContent?.content);
      const json = isJsonString(decoded);
      if (json) {
        const parsedContent = JSON.parse(decoded);
        if (parsedContent.type === 'doc') {
          return { name: name, content: parsedContent };
        }
        return { name: name, content: parsedContent.content };
      }
      return { name: name, content: decoded };
    }
    if (!parsedContent) {
      return { name: name, content: '' };
    }
    if (parsedContent?.type === 'doc') {
      return { name: name, content: parsedContent };
    }
    return { name: name, content: parsedContent.content };
  } else if (encoded) {
    const decoded = he.decode(record.content);
    const json = isJsonString(decoded);
    if (json) {
      const parsedContent = JSON.parse(decoded);
      if (parsedContent.type === 'doc') {
        return { name: name, content: parsedContent };
      }
      return { name: name, content: parsedContent.content };
    }
    return { name: name, content: decoded };
  } else if (markdown) {
    const content = marked.parse(record.content).toString();
    return { name: name, content: content };
  }
  return { name: name, content: record?.content };
};
