import { LucideIcon } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';

interface SourceButtonProps {
  onClick: () => void;
  icon: LucideIcon;
  title: string;
  subtitle: string;
}

const SourceButton: React.FC<SourceButtonProps> = ({ onClick, icon: Icon, title, subtitle }) => {
  return (
    <Button
      variant="secondary"
      className="group flex h-full w-80 flex-col items-center space-y-2.5 py-8"
      onClick={onClick}
    >
      <Icon
        className="h-8 w-8 transition-transform duration-300 ease-in-out group-hover:-translate-y-0.5"
        strokeWidth={1.5}
      />
      <div className="flex flex-col gap-1">
        <span className="text-foreground">{title}</span>
        <span className="text-muted-foreground/80">{subtitle}</span>
      </div>
    </Button>
  );
};

export default SourceButton;
