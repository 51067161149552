import { LucideIcon } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';

interface ActionButtonProps {
  icon: LucideIcon;
  title: string;
  description: string;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ icon: Icon, title, description, onClick }) => {
  return (
    <Button
      variant="outline"
      className="flex flex-1 items-center justify-start space-x-4 rounded-md border px-4 py-8 transition-all duration-300 hover:bg-card hover:shadow-lg"
      onClick={onClick}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-md bg-muted">
        <Icon className="h-5 w-5 text-muted-foreground" />
      </div>
      <div className="flex flex-col text-left">
        <span className="text-sm text-foreground">{title}</span>
        <span className="text-sm font-normal text-muted-foreground">{description}</span>
      </div>
    </Button>
  );
};

export default ActionButton;
