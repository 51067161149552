import { CircleHelpIcon } from 'lucide-react';
import React, { useState } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

interface AnalysisTooltipPopoverProps {}

const AnalysisTooltipPopover: React.FC<AnalysisTooltipPopoverProps> = ({}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open}>
      <PopoverTrigger asChild className="inline-block cursor-pointer">
        <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <CircleHelpIcon className="ml-2 mt-[-2px] inline-block h-4 w-4 text-muted-foreground" />
        </div>
      </PopoverTrigger>
      <PopoverContent>
        We determine source lines analyzed by calculating the character bytes of source code. One line of source code
        averages fifty bytes. E.g., 500MB of source code would be charged as 10 million lines of source code.
      </PopoverContent>
    </Popover>
  );
};

export default AnalysisTooltipPopover;
