import { useEffect, useState } from 'react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { Tag } from '@/components/Tag/Tag';

import { TagsCreate } from './TagsCreate';

interface TagBarProps {
  record: PrimaryAssetRecord;
  showAddTag?: boolean;
}

const MAX_VISIBLE_TAGS = 1;

export const TableCellTagBar: React.FC<TagBarProps> = ({ record, showAddTag = false }) => {
  const [isTagsCreateVisible, setTagsCreateVisible] = useState(false);
  const tags = (record?.tags ?? []).filter((tag) => tag.type === 'tag');
  const remainingTagsCount = Math.max(0, tags.length - MAX_VISIBLE_TAGS);

  useEffect(() => {
    setTagsCreateVisible(showAddTag && tags.length === 0);
  }, [showAddTag, tags]);

  return (
    <div className={`group relative h-10 max-h-10 w-[125px] p-2`}>
      {!isTagsCreateVisible && (
        <div className="flex h-full w-full items-center space-x-2 transition-opacity duration-200 group-hover:opacity-0">
          <>
            {tags.slice(0, MAX_VISIBLE_TAGS).map((tag) => (
              <Tag key={tag.name} tag={tag} className="h-6 flex-shrink-0" />
            ))}
            {remainingTagsCount > 0 && (
              <span className="inline-flex flex-shrink-0 items-center rounded-md bg-muted px-2 py-1 text-xs font-medium text-muted-foreground">
                +{remainingTagsCount}
              </span>
            )}
          </>
        </div>
      )}

      <div
        className={`absolute inset-1 flex h-8 items-center ${
          isTagsCreateVisible ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-200 group-hover:opacity-100`}
      >
        {record && <TagsCreate record={record} onClose={() => setTagsCreateVisible(false)} />}
      </div>
    </div>
  );
};
