import { FileCode2, Upload } from 'lucide-react';
import { useState } from 'react';

import { CodebaseUploadDropzone } from '@/components/UploadService/CodebaseUploadDropzone/CodebaseUploadDropzone';
import { SOC2Badge } from '@/components/UploadService/CodebaseUploadDropzone/SOC2Badge';
import PdfDropzone from '@/components/UploadService/PdfDropzone';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useOrg } from '@/contexts/OrgContext';
import { useCreateNewDocument } from '@/hooks/useCreateNewPage';
import useRolesChecks from '@/hooks/useRoleChecks';

import { toast } from '../ui/use-toast';

export interface GettingStartedProps {
  onDialogOpenChanged?: (isOpen: boolean) => void;
}

export default function GettingStarted({ onDialogOpenChanged }: GettingStartedProps) {
  const { organizationName } = useOrg();
  const { isAdmin } = useRolesChecks();
  const { createNewDocument } = useCreateNewDocument();
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [isCodebaseDialogOpen, setIsCodebaseDialogOpen] = useState(false);

  const handleFileDialogOpen = (isOpen: boolean) => {
    setIsFileDialogOpen(isOpen);
    if (onDialogOpenChanged) {
      onDialogOpenChanged(isOpen);
    }
  };

  const handleCodebaseDialogOpen = (isOpen: boolean) => {
    setIsCodebaseDialogOpen(isOpen);
    if (onDialogOpenChanged) {
      onDialogOpenChanged(isOpen);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full max-w-4xl">
        <ol className="space-y-10">
          <li>
            <div className="mb-4 flex flex-col items-start gap-y-2 md:flex-row">
              <div className="mr-4 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded bg-primary font-mono text-base text-background">
                1
              </div>
              <div className="flex flex-col space-y-1">
                <h4 className="font-mono text-xl font-semibold text-primary">Add sources</h4>
                <div className="text text-primary/75">
                  Automatically generate documentation from sources, or use them as context for creating custom
                  documents in Driver Studio.
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:pl-10">
              <Card className="flex flex-col rounded-lg p-4">
                <div className="mb-4 flex">
                  <FileCode2 className="mr-3 h-6 w-6" />
                  <h5 className="font-mono text-base font-semibold">Connect a Codebase</h5>
                </div>
                <div className="flex flex-grow flex-col justify-between">
                  <ul className="mb-4 list-[square] space-y-2 pl-5 text-primary/80">
                    <li>Automatically generate documentation for all files</li>
                    <li>Supports most languages and frameworks</li>
                    <li>Keep documentation up to date across codebase updates</li>
                  </ul>
                  <div className="flex w-full gap-x-2">
                    {isAdmin && (
                      // TODO: fix types
                      <Button
                        variant="default"
                        to={`/${organizationName}/settings/integrations` as any}
                        className="w-[50%]"
                      >
                        Configure Git Provider
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      onClick={() => handleCodebaseDialogOpen(true)}
                      className={isAdmin ? 'w-[50%]' : 'w-full'}
                    >
                      Upload codebase ZIP
                    </Button>
                  </div>
                </div>
              </Card>
              <Card className="flex flex-col rounded-lg p-4">
                <div className="mb-4 flex">
                  <Upload className="mr-3 h-6 w-6" />
                  <h5 className="font-mono text-base font-semibold">PDF</h5>
                </div>
                <div className="flex flex-grow flex-col justify-between">
                  <ul className="mb-4 list-[square] space-y-2 pl-5 text-primary/75">
                    <li>Search across all pages</li>
                    <li>Parse images and tables</li>
                    <li>No page limit</li>
                  </ul>
                  <div className="flex w-full gap-x-2">
                    <Button variant="secondary" onClick={() => handleFileDialogOpen(true)} className="w-full">
                      Upload PDF
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </li>
          <li className="flex items-start">
            <div className="flex flex-col items-start gap-y-2 md:flex-row">
              <div className="mr-4 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded bg-primary font-mono text-base text-background">
                2
              </div>
              <div className="flex flex-col space-y-1">
                <h4 className="font-mono text-xl font-semibold text-primary">Get answers</h4>
                <div className="text text-primary/75">
                  Dive into tech docs about a codebase or write your own Pages.
                  <br />
                  Reference any source you add to Driver within a Page to automatically generate content.
                </div>
                <div>
                  <Button
                    variant="outline"
                    className="mt-2 w-36"
                    onClick={() =>
                      createNewDocument({
                        actionLocation: 'help',
                        creationSource: 'blank',
                      })
                    }
                  >
                    Start writing
                  </Button>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <Dialog open={isCodebaseDialogOpen} onOpenChange={handleCodebaseDialogOpen}>
        <DialogContent className="w-[75vw] max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="font-medium">Upload a codebase file</div>
            </DialogTitle>
            <DialogDescription className="sr-only">.zip files only</DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <CodebaseUploadDropzone
              onSuccess={() => {
                toast({
                  title: 'Success',
                  description: 'Codebase uploaded successfully',
                });
                handleCodebaseDialogOpen(false);
              }}
            />
          </div>
          <SOC2Badge />
        </DialogContent>
      </Dialog>

      <Dialog open={isFileDialogOpen} onOpenChange={handleFileDialogOpen}>
        <DialogContent className="w-[75vw] max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="font-medium">Upload files</div>
            </DialogTitle>
            <DialogDescription className="sr-only">.pdf files only</DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <PdfDropzone />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
