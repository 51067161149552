// Make sure you have this utility function
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router';
import { Building2, LogOut, Settings } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

import { ThemeToggle } from '@/components/Navbar/ThemeToggle';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useLogout } from '@/hooks/useLogout';
import { useOrgUrlParams } from '@/hooks/useOrgUrlParams';
import { useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';

import { UserAvatar } from '../Settings/UserAvatar';

export function UserMenu() {
  const { userName, email, isDriverAiUser, user } = useUser();
  const { organizationName } = useOrgUrlParams();
  const { loginWithRedirect } = useAuth0();
  const logOut = useLogout();
  const [showEmail, setShowEmail] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setShowEmail(entry.contentRect.width > 31);
      }
    });

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex items-center justify-center px-0.5 pb-1">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            ref={triggerRef}
            variant="ghost"
            className="flex w-full items-center p-2 px-1 hover:bg-secondary-foreground/5"
          >
            <div className="ml-0.5 flex items-center justify-center rounded-full bg-secondary-foreground/5">
              {user && <UserAvatar user={user} size={24} />}
            </div>
            <div
              className={cn(
                'flex flex-grow flex-col overflow-hidden text-left transition-all duration-300 ease-in-out',
                showEmail ? 'ml-3 w-full opacity-100' : 'max-w-0 opacity-0'
              )}
            >
              <p className="truncate text-sm font-normal text-primary">{email}</p>
            </div>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56" side="right" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1.5">
              {userName && userName !== email ? (
                <>
                  <p className="text-sm font-medium leading-none">{userName}</p>
                  <p className="text-xs leading-none text-muted-foreground">{email}</p>
                </>
              ) : (
                <p className="text-sm font-medium text-muted-foreground">{email}</p>
              )}
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <ThemeToggle />
            {isDriverAiUser && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'organization',
                    },
                  });
                }}
              >
                <Building2 className="mr-3 h-4 w-4 text-muted-foreground" />
                Change organization
              </DropdownMenuItem>
            )}
            <DropdownMenuItem asChild>
              <Link to={`/$organizationName/settings/workspace`} params={{ organizationName }}>
                <Settings className="mr-3 h-4 w-4 text-muted-foreground" />
                Settings
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="hover:cursor-pointer" onClick={logOut}>
            <LogOut className="mr-3 h-4 w-4 text-muted-foreground" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
