import { AlertCircle, Search, UserIcon } from 'lucide-react';
import React, { useState } from 'react';

import {
  useGetOrganizationRolesQuery,
  useGetOrganizationUsersQuery,
  useRemoveUserFromOrganizationMutation,
  useUpdateUserRolesMutation,
} from '@/api/api';
import { RoleName } from '@/api/types/organization';
import { Alert, AlertTitle } from '@/components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/components/ui/use-toast';

import UserListItem from './UserListItem';

const UserManagement: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const { toast } = useToast();

  const { data: orgUsers = { members: [] }, isLoading, isError } = useGetOrganizationUsersQuery();
  const [removeUser] = useRemoveUserFromOrganizationMutation();
  const [updateUserRoles, { isLoading: isUpdatingUserRole }] = useUpdateUserRolesMutation();
  const { data: rolesData } = useGetOrganizationRolesQuery({});
  const members = orgUsers?.members || [];

  const filterMembers = () => {
    return members?.filter((member) => {
      const roleMatch = member.roles?.some((role) => role.name === roleFilter);
      const matchesRole = roleFilter === 'all' || roleMatch;
      const searchTerm = filter.toLowerCase();
      const matchesName = member.name.toLowerCase().includes(searchTerm);
      const matchesEmail = member.email.toLowerCase().includes(searchTerm);
      const matchesSearch = matchesName || matchesEmail;
      return matchesRole && matchesSearch;
    });
  };

  const filteredMembers = filterMembers();

  const changeRole = async (uid: string, role: RoleName) => {
    try {
      const roleId = rolesData?.roles?.find((r) => r.name === role)?.id;
      if (!roleId) throw new Error('missing data');
      await updateUserRoles({
        uid,
        roles: { roles: [roleId] },
      }).unwrap();
      toast({
        title: 'User role updated',
        description: 'The user role has been updated',
      });
    } catch (error) {
      toast({
        title: 'Failed to update user role',
        variant: 'destructive',
        description: 'An error occurred while updating the user role',
      });
    }
  };

  const handleRemoveUser = async (userId: string) => {
    try {
      await removeUser(userId).unwrap();
      toast({
        title: 'User removed',
        description: 'The user has been removed from the organization',
      });
    } catch (error) {
      toast({
        title: 'Failed to remove user',
        variant: 'destructive',
        description: 'An error occurred while removing the user',
      });
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-row items-center space-x-2">
        <UserIcon className="h-4 w-4" />
        <h2 className="text-xl font-medium">Members</h2>
      </div>
      <div className="flex items-center justify-between space-x-2">
        <div className="flex w-full items-center space-x-2">
          <div className="relative w-auto flex-grow md:max-w-80">
            <input
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filter users..."
              className="w-full rounded border border-border bg-transparent p-2 pl-8 text-sm placeholder:text-muted-foreground hover:border-primary/50 focus:ring-primary"
            />
            <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
          </div>
          <Select value={roleFilter} onValueChange={(value: string) => setRoleFilter(value)}>
            <SelectTrigger className="w-28 bg-background text-foreground">
              <SelectValue placeholder="All" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              {rolesData?.roles.map((role) => (
                <SelectItem key={role.id} value={role.name}>
                  {role.name}s
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <ul className="space-y-4 pt-4">
        {filteredMembers.map((member) => (
          <UserListItem
            key={member.user_id}
            member={member}
            onChangeRole={changeRole}
            onRemoveUser={handleRemoveUser}
            isUpdatingUserRole={isUpdatingUserRole}
          />
        ))}
      </ul>

      {isLoading && <div className="pt-4 text-sm text-muted-foreground">Loading...</div>}

      {!isError && !isLoading && filteredMembers.length === 0 && (
        <div className="text-md text-muted-foreground">No users found</div>
      )}

      {isError && (
        <Alert variant="destructive">
          <AlertCircle className="-mt-1 h-4 w-4" />
          <AlertTitle>Error loading users</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default UserManagement;
