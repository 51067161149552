import { Resizable } from 're-resizable';
import React, { ReactNode } from 'react';

interface LeftPanelProps {
  content?: ReactNode;
  header?: ReactNode;
  headerClassList?: string[];
  showOnlyHeaders: boolean;
  layout: {
    LeftPanelWidth: number;
    isLeftPanelOpen: boolean;
  };
  updateLayout: (update: Partial<{ LeftPanelWidth: number }>) => void;
  LeftPanelContentClassName?: string;
}

export const LeftPanel: React.FC<LeftPanelProps> = ({
  content,
  header,
  headerClassList,
  showOnlyHeaders,
  layout,
  updateLayout,
  LeftPanelContentClassName,
}) => {
  if (!content && !header) return null;

  const panelContent = (
    <div
      className={`${
        showOnlyHeaders || !content ? 'h-fit' : 'h-full'
      } flex flex-col overflow-hidden bg-white dark:bg-neutral-950`}
    >
      {header && (
        <>
          <div className={`h-[56px] flex-shrink-0 border-r border-border px-4 ${headerClassList?.join(' ') || ''}`}>
            {header}
          </div>
          <hr className="border-border" />
        </>
      )}
      {!showOnlyHeaders && content && (
        <div className={`flex-grow overflow-hidden border-r border-border p-3 ${LeftPanelContentClassName || ''}`}>
          {content}
        </div>
      )}
    </div>
  );

  if (showOnlyHeaders || !content) {
    return (
      <div className="absolute left-0 top-0 z-50 flex h-fit max-w-[300px] flex-col overflow-hidden truncate text-ellipsis">
        {panelContent}
      </div>
    );
  }

  return (
    <Resizable
      defaultSize={{
        width: `${layout.LeftPanelWidth}px`,
        height: '100%',
      }}
      minWidth="240px"
      maxWidth="480px"
      enable={{ right: true }}
      onResize={(e, direction, ref) => {
        const newWidth = parseFloat(ref.style.width);
        updateLayout({ LeftPanelWidth: newWidth });
      }}
    >
      {panelContent}
    </Resizable>
  );
};
