import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import React, { useEffect } from 'react';

import { useOrg } from '@/contexts/OrgContext';
import { useUser } from '@/hooks/useUser';

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  const { userId } = useUser();
  const { organizationName, organizationId, organizationDisplayName } = useOrg();

  useEffect(() => {
    if (import.meta.env.VITE_POSTHOG_KEY && import.meta.env.VITE_POSTHOG_HOST) {
      // Initialize PostHog
      posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        autocapture: false,
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        // Uncomment this if you are running in dev mode.
        // This will show console logs for debugging.
        // debug: true,
        loaded: (posthog) => {
          // Ensure we're not in opt-out mode
          // Uncomment this if you are running in dev mode and want to capture events.
          // posthog.opt_in_capturing();
        },
      });

      // Initialize non-group specific and non-user specific feature flags
      posthog.reloadFeatureFlags();
    } else {
      console.warn('PostHog not initialized: Missing env vars', {
        hasKey: !!import.meta.env.VITE_POSTHOG_KEY,
        hasHost: !!import.meta.env.VITE_POSTHOG_HOST,
      });
    }
  }, []);

  // Set company group and identify user
  useEffect(() => {
    if (!userId || !organizationId) {
      return;
    }

    try {
      posthog.group('company', organizationId, {
        id: organizationId,
        name: organizationName,
        displayName: organizationDisplayName,
        environment: import.meta.env.MODE,
        isDev: import.meta.env.DEV,
        isProd: import.meta.env.PROD,
      });

      posthog.identify(userId, {
        distinct_id: userId,
        organizationId,
        $groups: {
          company: organizationId,
        },
      });

      // DO NOT REMOVE THIS
      // This capture event is to make sure that
      // the group properties are set correctly.
      posthog.capture('posthog_group_set', {
        id: organizationId,
        name: organizationName,
        displayName: organizationDisplayName,
        environment: import.meta.env.MODE,
        isDev: import.meta.env.DEV,
        isProd: import.meta.env.PROD,
      });

      // Load group and user specific feature flags
      posthog.reloadFeatureFlags();
    } catch (error) {
      console.error('Error setting up PostHog user:', error);
    }
  }, [organizationId, organizationName, organizationDisplayName, userId]);

  return <PHProvider client={posthog}>{children}</PHProvider>;
}
