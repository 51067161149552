import { decode } from 'he';

import { UserMenu } from '@/components/Navbar/UserMenu';
import { SidebarMobile } from '@/components/Sidebar/SidebarMobile';

interface NavbarProps {
  title: string;
}

export function Navbar({ title }: NavbarProps) {
  return (
    <header className="sticky top-0 z-10 w-full border-b border-neutral-200 bg-white dark:border-neutral-800 dark:bg-neutral-950">
      <div className="mx-4 flex h-14 items-center sm:me-4 lg:ms-12">
        <div className="flex items-center space-x-2 lg:space-x-0">
          <SidebarMobile />
          <div className="text-sm text-muted-foreground">{title ? decode(title) : 'Untitled'}</div>
        </div>
        <div className="flex flex-1 items-center justify-end space-x-2">
          <UserMenu />
        </div>
      </div>
    </header>
  );
}
