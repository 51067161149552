import * as React from 'react';

import { baseUrl, primaryAssetPath } from '@/api/api';
import security from '@/api/security';
import {
  FetchPrimaryAssetParams,
  PrimaryAssetRecord,
  PrimaryAssetResponse,
  VersionStatus,
  VersionStatusType,
} from '@/api/types/node';

const isFinished = (status: VersionStatusType) =>
  status === VersionStatus.CONNECTED || status === VersionStatus.GENERATION_COMPLETE;

const isProcessing = (status: VersionStatusType) =>
  status === VersionStatus.CONNECTING || status === VersionStatus.GENERATING;

const POLL_INTERVAL = 10000;
const MAX_POLL_COUNT = 180; // 30 minutes
let pollCount = 0;

// we don't want to call unnecessary react hooks as they will cause rerenders to the table
// ie calling rtk hooks will cause rerenders to the table
const fetchPrimaryAssets = async (params: FetchPrimaryAssetParams, token: string): Promise<PrimaryAssetResponse> => {
  const stringParams: Record<string, string> = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      stringParams[key] = value.toString();
    }
  });
  const queryParams = new URLSearchParams(stringParams);
  const response = await fetch(`${baseUrl}${primaryAssetPath}?${queryParams.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to export');
  }
  return response.json();
};

// poll the status of the rows that are processing
// when a row is finished, update the cell status
// by dispatching a custom event
export function useLibraryStatusPoller(data: PrimaryAssetRecord[], updateCache: (row: PrimaryAssetRecord) => void) {
  const { getAccessTokenSilently } = security;
  const [processingRows, setProcessingRows] = React.useState<PrimaryAssetRecord[]>([]);

  React.useEffect(() => {
    if (processingRows.length === 0 || pollCount >= MAX_POLL_COUNT) {
      return;
    }
    let intervalId: ReturnType<typeof setInterval> | undefined;
    const fetchRows = async () => {
      if (processingRows.length === 0 || pollCount >= MAX_POLL_COUNT) {
        return;
      }
      try {
        const token = await getAccessTokenSilently()();
        const processingIds = processingRows.map((row) => row.id);
        const response = await fetchPrimaryAssets(
          {
            id__in: processingIds.join(','),
            limit: 50,
            offset: 0,
          },
          token
        );
        const newProcessingRows = response.results.filter((row: PrimaryAssetRecord) =>
          isProcessing(row.most_recent_version?.status as VersionStatusType)
        );
        const newFinishedRows = response.results.filter((row: PrimaryAssetRecord) =>
          isFinished(row.most_recent_version?.status as VersionStatusType)
        );
        // for each new finished row, update the RTK Query cache.
        newFinishedRows.forEach((row: PrimaryAssetRecord) => {
          // Update RTK Query cache for each finished row
          updateCache(row);
        });
        // only update the state if we have fewer processing rows
        if (newProcessingRows.length < processingRows.length) {
          setProcessingRows(newProcessingRows);
          // updating state will trigger a new interval
          // so we need to clear the old one
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
        pollCount += 1;
      } catch (error) {
        if (intervalId) {
          clearInterval(intervalId);
        }
      }
    };
    intervalId = setInterval(fetchRows, POLL_INTERVAL);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [processingRows, getAccessTokenSilently, updateCache]);

  React.useEffect(() => {
    if (data.length > 0) {
      const rows = data.filter((row: PrimaryAssetRecord) =>
        isProcessing(row.most_recent_version?.status as VersionStatusType)
      );
      setProcessingRows(rows);
    }
  }, [data]);
}
