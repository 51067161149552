import { useAuth0 } from '@auth0/auth0-react';
import { AlertCircle, Mail } from 'lucide-react';
import React, { useState } from 'react';

import {
  useCreateOrganizationInvitationsMutation,
  useDeleteInvitationMutation,
  useGetOrganizationInvitationsQuery,
  useGetOrganizationRolesQuery,
} from '@/api/api';
import { RoleName } from '@/api/types/organization';
import { Alert, AlertTitle } from '@/components/ui/alert';
import { useToast } from '@/components/ui/use-toast';

import { InvitationRow } from './InvitationRow';
import InviteUsersDialog from './Management/InviteUsersDialog';

const Invitations: React.FC = ({}) => {
  const { data: invitations, isLoading, isError } = useGetOrganizationInvitationsQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth0();
  const [createInvitations, { isLoading: isCreatingInvitations }] = useCreateOrganizationInvitationsMutation();
  const [deleteInvitation] = useDeleteInvitationMutation();
  const { data: rolesData } = useGetOrganizationRolesQuery({});

  const handleInvite = async (emails: string[], role: RoleName) => {
    try {
      if (!user?.sub) throw new Error('missing data');
      if (!rolesData) throw new Error('missing data');
      const roleId = rolesData?.roles?.find((r) => r.name === role)?.id;
      if (!roleId) throw new Error('missing data');
      await createInvitations({
        invitations: emails.map((email) => ({
          invitee: { email },
          roles: [roleId],
        })),
      }).unwrap();
      toast({
        title: 'Invitations sent',
        description: 'Invitations have been sent to the users',
      });
    } catch (error) {
      toast({
        title: 'Failed to invite users',
        variant: 'destructive',
        description: 'An error occurred while inviting users',
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleDeleteInvitation = async (invitationId: string) => {
    try {
      await deleteInvitation(invitationId).unwrap();
      toast({
        title: 'Invitation deleted',
        description: 'The invitation has been removed',
      });
    } catch (error) {
      toast({
        title: 'Failed to delete invitation',
        variant: 'destructive',
        description: 'An error occurred while deleting the invitation',
      });
    }
  };

  const handleCopyInvitationUrl = async (invitationId: string) => {
    await navigator.clipboard.writeText(invitationId);
    toast({
      title: 'Copied to clipboard',
      description: 'Invitation URL has been copied',
    });
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-row items-center space-x-2">
        <Mail className="h-4 w-4" />
        <h2 className="text-xl font-medium">Invitations</h2>
      </div>
      <div className="flex items-center justify-between pb-2 text-sm text-muted-foreground">
        <span>Invite people to your organization to collaborate on projects.</span>
        <InviteUsersDialog
          isOpen={isModalOpen}
          onOpenChange={setIsModalOpen}
          onInvite={handleInvite}
          isCreatingInvitations={isCreatingInvitations}
        />
      </div>

      <div className="relative w-full">
        <table className="w-full table-fixed caption-bottom text-sm">
          <thead className="border-b">
            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
              <th className="h-12 w-[25%] px-2 text-left align-middle font-medium text-muted-foreground">Email</th>
              <th className="h-12 w-[12%] px-2 text-left align-middle font-medium text-muted-foreground">Status</th>
              <th className="h-12 w-[15%] px-2 text-left align-middle font-medium text-muted-foreground">Created At</th>
              <th className="h-12 w-[15%] px-2 text-left align-middle font-medium text-muted-foreground">Expires At</th>
              <th className="h-12 w-[18%] px-2 text-left align-middle font-medium text-muted-foreground">Created By</th>
              <th className="h-12 w-[15%] px-2 text-left align-middle font-medium text-muted-foreground"></th>
            </tr>
          </thead>
          <tbody className="[&_tr:last-child]:border-0">
            {invitations?.map((invitation) => {
              const roleName = rolesData?.roles?.find((r) => r.id === invitation.roles?.[0])?.name;
              return (
                <InvitationRow
                  key={invitation.id}
                  invitation={invitation}
                  role={roleName as RoleName}
                  onCopyUrl={handleCopyInvitationUrl}
                  onDelete={handleDeleteInvitation}
                />
              );
            })}
          </tbody>
        </table>
        {isError && (
          <Alert variant="destructive">
            <AlertCircle className="-mt-1 h-4 w-4" />
            <AlertTitle>Error loading invitations</AlertTitle>
          </Alert>
        )}
        {isLoading && <div className="pt-4 text-sm text-muted-foreground">Loading...</div>}
        {!isLoading && !isError && invitations?.length === 0 && (
          <div className="text-md pt-4 text-muted-foreground">No invitations found</div>
        )}
      </div>
    </div>
  );
};

export default Invitations;
