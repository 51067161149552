import { Link } from '@tanstack/react-router';
import { decode } from 'he';
import { ExternalLink, MoreVertical, Trash2 } from 'lucide-react';
import React from 'react';

import PreviewCard from '@/components/DocumentPreview/PreviewCard';
import { UserAvatar } from '@/components/Settings/UserAvatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { getGravatarUrl } from '@/utils/avatar';
import { formatSmartRelativeDate } from '@/utils/dates';

interface DocumentPreviewProps {
  nodeId: string;
  title: string;
  lastEdited: string;
  link: string;
  className?: string;
  creator?: {
    full_name: string;
    email: string;
  };
  onDelete?: () => void;
}

export const PreviewDocs: React.FC<DocumentPreviewProps> = ({ nodeId, title, lastEdited, link, creator, onDelete }) => {
  const relativeTime = formatSmartRelativeDate(lastEdited);

  const handleOpenInNewTab = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    onDelete?.();
  };

  return (
    <div className="group relative block w-[256px] shrink-0">
      <Link to={link}>
        <div className="h-[160px] w-full overflow-hidden rounded-lg border">
          <PreviewCard nodeId={nodeId} height={160} />
        </div>
      </Link>
      <div className="mt-4 space-y-1">
        <div className="flex items-center justify-between gap-2">
          <span className="line-clamp-1 overflow-hidden text-sm font-medium text-foreground group-hover:underline">
            {decode(title)}
          </span>
          <DropdownMenu>
            <DropdownMenuTrigger
              className="flex h-6 w-6 items-center justify-center rounded-md text-muted-foreground hover:bg-accent hover:text-accent-foreground"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical className="h-4 w-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleOpenInNewTab}>
                <ExternalLink className="mr-2 h-4 w-4" />
                Open in new tab
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleDelete} className="text-destructive focus:text-destructive">
                <Trash2 className="mr-2 h-4 w-4" />
                Delete page
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex items-center gap-1 text-muted-foreground">
          <span className="text-xs">Last Edited</span>
          <div className="text-xs">{relativeTime}</div>
        </div>
        {creator && (
          <div className="flex items-center gap-2">
            <UserAvatar
              user={{
                email: creator.email,
                name: creator.full_name,
                picture: getGravatarUrl(creator.email, 24),
              }}
              size={24}
            />
            <span className="text-xs text-muted-foreground">{creator.full_name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewDocs;
