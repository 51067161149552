import { createFileRoute } from '@tanstack/react-router';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { useAddSourceToDocumentMutation, useGetContentQuery } from '@/api/api';
import { ContentSource } from '@/api/types/collection';
import { EDITOR_ACTIONS_CONTAINER_ID } from '@/components/BlockEditor/BlockEditor';
import RunAllInstructions from '@/components/BlockEditor/extensions/Instructions/RunAllInstructions';
import { ChatProvider } from '@/components/Chat/ChatContext';
import FlexiblePanelLayout from '@/components/FlexiblePanelLayout/FlexiblePanelLayout';
import DefaultLayout from '@/components/LayoutDefault';
import { PageContent } from '@/components/Pages/PageContent/PageContent';
import { PageSidebar } from '@/components/Pages/PageSidebar/PageSidebar';
import { SidebarTabType } from '@/components/Pages/SidebarTabType';
import FloatingTableOfContents from '@/components/TableOfContents/FloatingTableOfContents';
import UserDefinedScope from '@/components/UserDefinedScope/UserDefinedScope';
import { useToast } from '@/components/ui/use-toast';
import { TabProvider } from '@/contexts/TabContext';
import { TunerProvider, useTunerContext } from '@/contexts/TunerContext';
import { useDriverTemplate } from '@/hooks/useDriverTemplate';
import { useSidebarToggle } from '@/hooks/useSidebarToggle';
import { NotFound404 } from '@/routes/404';
import { transformLibraryContent } from '@/utils/formatEditorContentUtil';

export const Route = createFileRoute('/$organizationName/page/$pageId')({
  component: ProvidedPage,
});

function Page() {
  const { toast } = useToast();
  const { pageId } = Route.useParams();
  const { isDriverTemplate } = useDriverTemplate();
  const { isOpen: sidebarOpen } = useSidebarToggle();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
  const { contentId, documentSources, openUserDefinedScope, setOpenUserDefinedScope, tunerItem, setTunerItem } =
    useTunerContext();

  const [addSourceToDocument, { isLoading: _isAddingSourceToDocument, error: _addSourceToDocumentError }] =
    useAddSourceToDocumentMutation();

  const { data, isLoading: _loading, isError } = useGetContentQuery({ node_id: pageId }, { skip: isDriverTemplate });
  // FUTURE: stop parsing data?.results[0] multiple times.
  // See PageContent.tsx
  const parsedContent = transformLibraryContent(data?.results[0]);
  const pageTitle = parsedContent?.name || 'Page';

  useEffect(() => {
    document.title = pageTitle || 'Page';
  }, [pageTitle]);

  const handleTunerAccept = (checked: string[]) => {
    if (!tunerItem) return;
    if (!documentSources) return;

    setOpenUserDefinedScope(false);
    setTunerItem(null);
    const tunerSources: ContentSource[] = checked.map((sourceId) => ({
      source_node_id: sourceId,
      page_node_id: contentId,
    }));

    // also add other sources not in the tuner
    const otherSources = documentSources
      .filter((source) => source.id !== tunerItem.id)
      .flatMap((source) => {
        if (source.children?.length === 0) {
          return [{ source_node_id: source.node_id, page_node_id: contentId }];
        }
        if (!source.children) return [];
        return source.children.map((child) => ({
          source_node_id: child,
          page_node_id: contentId,
        }));
      });
    const allSources = uniqBy([...tunerSources, ...otherSources], 'source_node_id');

    addSourceToDocument(allSources)
      .then((r) => {
        if (r.error) throw new Error();
      })
      .catch((e) => {
        toast({
          title: 'Failed to add sources to document',
          description: e.message,
          variant: 'destructive',
        });
      });
  };

  if (isError) {
    return <NotFound404 />;
  }

  // if (loading) {
  //   return <div></div>;
  // }

  // if (error || !data?.applicationNote) {
  //   return <Custom404 />;
  // }

  const tocPositionClass = sidebarOpen ? 'left-0 lg:left-52 ml-3' : 'left-0 lg:left-10 ml-3';

  return (
    <DefaultLayout noContentPadding navbarTitle={parsedContent?.name}>
      <FlexiblePanelLayout
        isRightPanelOpen={rightSidebarOpen}
        onRightPanelOpenChange={setRightSidebarOpen}
        pageType="page"
        mainPanelContent={
          <div className="relative pt-8">
            <RunAllInstructions />
            <PageContent />
            <FloatingTableOfContents tocPositionClass={tocPositionClass} />
            <div id={EDITOR_ACTIONS_CONTAINER_ID} className="absolute right-[-10px] top-[-14px]" />
          </div>
        }
        rightPanelContent={
          <PageSidebar pageId={pageId} sidebarOpen={rightSidebarOpen} setSidebarOpen={setRightSidebarOpen} />
        }
        rightPanelWidth={300}
        showRightPanelHeaderDivider={false}
        rightPanelHeaderClassName="h-[48px]"
        rightPanelClassName="h-full"
      />
      {openUserDefinedScope && (
        <UserDefinedScope
          open={openUserDefinedScope}
          onOpen={setOpenUserDefinedScope}
          tunerItemRecord={tunerItem}
          isTunerOpen={tunerItem !== null}
          onTunerAccept={handleTunerAccept}
        />
      )}
    </DefaultLayout>
  );
}

function ProvidedPage() {
  const { pageId } = Route.useParams();

  return (
    <TunerProvider>
      <ChatProvider pageId={pageId}>
        <TabProvider<SidebarTabType> defaultTab="sources">
          <Page />
        </TabProvider>
      </ChatProvider>
    </TunerProvider>
  );
}
