import { ListEnd, Loader2 } from 'lucide-react';
import { useCallback } from 'react';

import { useGenerateCodebasesMutation, useGetUsageBalanceQuery } from '@/api/api';
import { PrimaryAssetRecord, VersionStatus } from '@/api/types/node';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { useToast } from '@/components/ui/use-toast';
import { libraryRouteApi } from '@/utils/route';

import { GenerationSidebarContent } from './GenerationSidebarContent';

export interface GenerationSidebarProps {
  data?: PrimaryAssetRecord[];
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function GenerationSidebar({ data = [], children, open: controlledOpen, onOpenChange }: GenerationSidebarProps) {
  const query = libraryRouteApi.useSearch();
  const navigate = libraryRouteApi.useNavigate();
  const generateRowIds = query.generateRowIds;
  const [generateDocs, { isLoading }] = useGenerateCodebasesMutation();
  const { toast } = useToast();

  const selectedRecords = data.filter((asset) => generateRowIds?.includes(asset.id));

  const open = controlledOpen ?? selectedRecords.length > 0;
  const skip = selectedRecords.length === 0;

  const {
    data: usageBalance,
    isLoading: isUsageBalanceLoading,
    error: usageBalanceError,
  } = useGetUsageBalanceQuery(undefined, { skip });

  const versionIds = selectedRecords
    .map((record) => record.most_recent_version?.id)
    .filter((id) => id !== undefined) as string[];

  const dismissSidebar = useCallback(() => {
    navigate({
      search: (prev) => ({ ...prev, generateRowIds: [] }),
    });
  }, [navigate]);

  const handleGenerateDocs = () => {
    generateDocs({ version_ids: versionIds })
      .unwrap()
      .then(() => {
        toast({
          title: 'Codebase generation started',
        });

        // Dismiss the sidebar after generation.
        dismissSidebar();
      })
      .catch((error) => {
        toast({
          title: 'Error generating codebases',
          // description: error.message,
          variant: 'destructive',
        });
      });
  };

  const used = Math.abs(usageBalance?.debits ?? 0);
  const toBeUsed = selectedRecords.reduce(
    (acc, curr) => acc + (curr.most_recent_version?.root_node?.misc_metadata?.analyzable_sloc ?? 0),
    0
  );
  const total = usageBalance?.credits ?? 0;
  const isOverage = toBeUsed + used > total;

  const isConnecting = selectedRecords.some(
    (record) => record.most_recent_version?.status === VersionStatus.CONNECTING
  );

  if (!open) {
    return null;
  }

  return (
    <Sheet
      open={open}
      onOpenChange={(isOpen) => {
        onOpenChange?.(isOpen);
        if (!isOpen) {
          dismissSidebar();
        }
      }}
    >
      {children}
      <SheetContent width="585px" className="overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Confirm Usage</SheetTitle>
          <SheetDescription className="sr-only">Please confirm the usage for the selected codebases.</SheetDescription>
        </SheetHeader>
        <div className="flex-grow py-4">
          {usageBalanceError ? (
            <div className="flex flex-col gap-4">
              <Alert variant="destructive">
                <AlertTitle>Error loading usage balance</AlertTitle>
                <AlertDescription>
                  Please try refreshing the page. If the problem persists, please contact support.
                </AlertDescription>
              </Alert>
            </div>
          ) : (
            <GenerationSidebarContent
              usageBalance={usageBalance}
              isUsageBalanceLoading={isUsageBalanceLoading}
              selectedRecords={selectedRecords}
            />
          )}
        </div>
        <SheetFooter>
          <div className="mt-4 flex w-full items-center justify-center">
            <Button
              type="submit"
              onClick={handleGenerateDocs}
              className="w-full"
              disabled={isLoading || !!usageBalanceError || isConnecting || isOverage}
            >
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <ListEnd className="ml-2 mr-2 h-4 w-4" />
              )}
              {isConnecting
                ? 'Connecting...'
                : isLoading
                  ? 'Generating...'
                  : selectedRecords.length > 1
                    ? 'Generate All Codebases'
                    : 'Generate'}
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
