import React from 'react';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

type BillingBarProps = {
  billingData: {
    percentage: number;
    color: string;
    count?: number;
    label?: string;
  }[];
};

const BillingBar = ({ billingData }: BillingBarProps) => {
  return (
    <div className="h-3 w-full overflow-hidden bg-gray-300">
      <div className="billing-bar flex h-full">
        {billingData.map((data, index) => {
          return (
            <div
              key={index}
              className={`h-full ${data.color} overflow-hidden bar-${index}`}
              style={{
                width: `${data.percentage}%`,
                minWidth: data.percentage !== 0 ? 6 : 0,
              }}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div className={`h-4 w-full tooltip-${index}`}></div>
                  </TooltipTrigger>
                  <TooltipContent>
                    {data.label}: {new Intl.NumberFormat().format(data.count ?? 0)} SLOC
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BillingBar;
