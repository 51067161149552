export const DocumentType = {
  PAGE: 'PAGE',
  PAGE_TEMPLATE: 'PAGE_TEMPLATE',
} as const;

export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType];

export const DocumentTableType = {
  PAGE: 'page',
  PAGE_TEMPLATE: 'page_template',
} as const;

export type DocumentTableType = (typeof DocumentTableType)[keyof typeof DocumentTableType];

export const DEFAULT_PAGES_COLUMN_VISIBILITY = {
  display_name: true,
  created_at: true,
  updated_at: true,
  creator: true,
  status: true,
  tags: true,
} as const;

export const DEFAULT_TEMPLATES_COLUMN_VISIBILITY = {
  display_name: true,
  created_at: true,
  updated_at: true,
  creator: true,
  status: true,
  tags: true,
} as const;
