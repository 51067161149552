import { ColumnDef } from '@tanstack/react-table';

import { PrimaryAssetRecord, VersionStatus, VersionStatusType } from '@/api/types/node';
import { CellContentActions } from '@/components/BaseTable/columns/CellContentActions';
import { CellContentIcon } from '@/components/BaseTable/columns/CellContentIcon';
import { CellContentName } from '@/components/BaseTable/columns/CellContentName';
import { CellContentTags } from '@/components/BaseTable/columns/CellContentTags';
import { EXCLUDED_FILTER_COLUMNS, EXCLUDED_STATUSES } from '@/components/Filters/filter-constants';
import { DataTableColumnHeader } from '@/components/LibraryTable/ColumnHeader';
import { Checkbox } from '@/components/ui/checkbox';

export const commonColumns: ColumnDef<PrimaryAssetRecord>[] = [
  {
    id: 'select',
    minSize: 10,
    size: 10,
    maxSize: 10,
    header: ({ table }) => {
      const rows = table.getRowModel().rows;
      const connectedRows = rows.filter((row) => row.original.most_recent_version?.status === VersionStatus.CONNECTED);
      const allConnectedSelected = connectedRows.length > 0 && connectedRows.every((row) => row.getIsSelected());
      return (
        <Checkbox
          className="ml-[5px]"
          {...{
            checked: allConnectedSelected,
            onCheckedChange: () => {
              connectedRows.forEach((row) => {
                row.toggleSelected(!allConnectedSelected);
              });
            },
            disabled: connectedRows.length === 0,
          }}
        />
      );
    },
    cell: ({ row }) => {
      const isConnected = row.original.most_recent_version?.status === VersionStatus.CONNECTED;
      if (isConnected) {
        return (
          <div className="px-1">
            <Checkbox
              className="ml-2"
              {...{
                checked: row.getIsSelected(),
                disabled: !isConnected,
                onCheckedChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      }
      return <></>;
    },
  },
  {
    accessorKey: 'display_name',
    enableSorting: true,
    size: 200,
    minSize: 180,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" className="pl-2 text-xs text-primary" />
    ),
    cell: ({ row }) => (
      <div className="flex gap-2 px-2">
        <CellContentIcon row={row} />
        <CellContentName row={row} />
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    minSize: 20,
    maxSize: 20,
    size: 20,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tags" className="text-xs text-primary" />,
    cell: (props) => <CellContentTags {...props} />,
  },
];

// Add actions column separately to ensure it's always at the end
export const actionsColumn: ColumnDef<PrimaryAssetRecord> = {
  id: 'actions',
  size: 40,
  minSize: 35,
  maxSize: 40,
  enableHiding: false,
  cell: ({ row }) => (
    <div className="flex justify-center">
      <CellContentActions
        id={row.original.id}
        primary_asset_type={row.original.kind}
        content_name={row.original.display_name}
      />
    </div>
  ),
};

export function filterExcludedColumns(columnIds: string[]): string[] {
  return columnIds.filter((id) => !EXCLUDED_FILTER_COLUMNS.includes(id as (typeof EXCLUDED_FILTER_COLUMNS)[number]));
}

export function getTopLanguage(row: PrimaryAssetRecord): string | null {
  const metadata = row.most_recent_version?.root_node?.misc_metadata as {
    analyzable_sloc_by_type?: Record<string, number>;
  };

  const slocByType = metadata?.analyzable_sloc_by_type;
  if (!slocByType || Object.keys(slocByType).length === 0) {
    return null;
  }

  const topLanguage = Object.entries(slocByType).reduce(
    (max, [lang, count]) => (count > max[1] ? [lang, count] : max),
    ['', 0]
  );
  return topLanguage[0];
}

export function getColumnValue(row: PrimaryAssetRecord, columnId: string): string | null {
  // Special cases for columns that need custom value access
  switch (columnId) {
    case 'file_count':
      return row.most_recent_version?.root_node?.misc_metadata?.total_files?.toString() ?? null;
    case 'pages':
      // For PDFs, pages are in the root node's misc_metadata
      if (row.kind === 'FILE') {
        return (row.most_recent_version?.root_node?.misc_metadata as any)?.total_pages?.toString() ?? null;
      }
      // For codebases, pages are in the root node's misc_metadata
      return (row.most_recent_version?.root_node?.misc_metadata as any)?.total_pages?.toString() ?? null;
    case 'created_at': {
      const date = new Date(row.created_at);
      if (isNaN(date.getTime())) return null;
      return date.toLocaleDateString();
    }
    case 'updated_at': {
      const date = new Date(row.updated_at);
      if (isNaN(date.getTime())) return null;
      return date.toLocaleDateString();
    }
    case 'top_language':
      return getTopLanguage(row);
    // Add more special cases here as needed
    default:
      return row[columnId as keyof PrimaryAssetRecord]?.toString() ?? null;
  }
}

export function filterExcludedStatuses(statuses: VersionStatusType[]): VersionStatusType[] {
  return statuses.filter((status) => !EXCLUDED_STATUSES.includes(status as any));
}
