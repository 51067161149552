import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'lucide-react';

import { PrimaryAssetRecord } from '@/api/types/node';
import { CellContentActions } from '@/components/BaseTable/columns/CellContentActions';
import { CellContentIcon } from '@/components/BaseTable/columns/CellContentIcon';
import { CellContentName } from '@/components/BaseTable/columns/CellContentName';
import { CellContentTags } from '@/components/BaseTable/columns/CellContentTags';
import { DataTableColumnHeader } from '@/components/LibraryTable/ColumnHeader';
import { UserAvatar } from '@/components/Settings/UserAvatar';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useToast } from '@/components/ui/use-toast';
import { useOrg } from '@/contexts/OrgContext';
import { getGravatarUrl } from '@/utils/avatar';
import { formatSmartRelativeDate } from '@/utils/dates';

function PageLinkCell({ row }: { row: { original: PrimaryAssetRecord } }) {
  const { organizationName } = useOrg();
  const { toast } = useToast();

  const handleCopyLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const baseUrl = window.location.origin;
    const itemType = row.original.kind === 'PAGE' ? 'page' : 'template';
    const path = `/${organizationName}/${itemType}/${row.original.id}`;
    const fullUrl = baseUrl + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      toast({
        title: 'Link copied',
        description: 'The link has been copied to your clipboard.',
        variant: 'default',
      });
    });
  };

  return (
    <div>
      <Button variant="secondary" size="sm" className="h-8 gap-2 px-3 text-xs" onClick={handleCopyLink}>
        <Link className="h-4 w-4" />
        Copy link
      </Button>
    </div>
  );
}

const commonDataColumns: ColumnDef<PrimaryAssetRecord>[] = [
  {
    accessorKey: 'created_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created Date" />,
    cell: ({ row }) => {
      const createdAt = row.original.created_at;
      return <div>{createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '-'}</div>;
    },
  },
  {
    id: 'creator',
    accessorFn: (originalRow) => originalRow.most_recent_version?.creator?.full_name,
    size: 150,
    minSize: 120,
    maxSize: 180,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Created By" className="text-xs text-primary" />
    ),
    cell: ({ row }) => {
      const creator = row.original.most_recent_version?.creator;
      if (!creator) return <div className="text-center">-</div>;

      return (
        <div className="flex max-w-[150px] items-center gap-1.5">
          <UserAvatar
            user={{
              email: creator.email,
              name: creator.full_name,
              picture: getGravatarUrl(creator.email, 32),
            }}
            size={24}
            className="flex-shrink-0"
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="flex-1 truncate text-sm">{creator.full_name}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>{creator.full_name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    },
  },
  {
    accessorKey: 'updated_at',
    size: 100,
    minSize: 80,
    maxSize: 120,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Edited" />,
    cell: ({ row }) => {
      const updatedAt = row.original.updated_at;
      return <div>{updatedAt ? formatSmartRelativeDate(updatedAt) : '-'}</div>;
    },
  },
  {
    id: 'page_link',
    size: 120,
    minSize: 100,
    maxSize: 140,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Page Link" />,
    cell: PageLinkCell,
  },
  {
    id: 'actions',
    size: 40,
    minSize: 35,
    maxSize: 40,
    header: ({ column }) => <DataTableColumnHeader column={column} title="" />,
    cell: ({ row }) => (
      <div>
        <CellContentActions
          id={row.original.id}
          primary_asset_type={row.original.kind}
          content_name={row.original.display_name}
        />
      </div>
    ),
  },
];

// Page specific columns
export const pageColumns: ColumnDef<PrimaryAssetRecord>[] = [
  // TODO: Will add this back in after product requirements are finalized

  // {
  //   id: 'select',
  //   minSize: 10,
  //   size: 10,
  //   maxSize: 10,
  //   header: ({ table }) => {
  //     const checked = table.getIsAllRowsSelected();
  //     const rows = table.getRowModel().rows;
  //     const isConnectedRows = rows.filter((row) => row.getCanSelect());
  //     return (
  //       <Checkbox
  //         className="ml-[5px]"
  //         {...{
  //           checked,
  //           onCheckedChange: () => table.toggleAllRowsSelected(),
  //           disabled: isConnectedRows.length === 0,
  //         }}
  //       />
  //     );
  //   },
  //   cell: ({ row }) => {
  //     if (row.getCanSelect()) {
  //       return (
  //         <div className="px-1">
  //           <Checkbox
  //             className="ml-2"
  //             {...{
  //               checked: row.getIsSelected(),
  //               disabled: !row.getCanSelect(),
  //               onCheckedChange: row.getToggleSelectedHandler(),
  //             }}
  //           />
  //         </div>
  //       );
  //     }
  //     return <></>;
  //   },
  // },
  {
    accessorKey: 'display_name',
    enableSorting: true,
    size: 200,
    minSize: 180,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Page Name" className="pl-2 text-xs text-primary" />
    ),
    cell: ({ row }) => (
      <div className="flex gap-2 px-2">
        <CellContentIcon row={row} />
        <CellContentName row={row} />
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    minSize: 20,
    maxSize: 20,
    size: 20,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tags" className="text-xs text-primary" />,
    cell: (props) => <CellContentTags {...props} />,
  },
  ...commonDataColumns,
];

// Template specific columns
export const templateColumns: ColumnDef<PrimaryAssetRecord>[] = [
  // TODO: Will add this back in after product requirements are finalized
  // {
  //   id: 'select',
  //   minSize: 10,
  //   size: 10,
  //   maxSize: 10,
  //   header: ({ table }) => {
  //     const checked = table.getIsAllRowsSelected();
  //     const rows = table.getRowModel().rows;
  //     const isConnectedRows = rows.filter((row) => row.getCanSelect());
  //     return (
  //       <Checkbox
  //         className="ml-[5px]"
  //         {...{
  //           checked,
  //           onCheckedChange: () => table.toggleAllRowsSelected(),
  //           disabled: isConnectedRows.length === 0,
  //         }}
  //       />
  //     );
  //   },
  //   cell: ({ row }) => {
  //     if (row.getCanSelect()) {
  //       return (
  //         <div className="px-1">
  //           <Checkbox
  //             className="ml-2"
  //             {...{
  //               checked: row.getIsSelected(),
  //               disabled: !row.getCanSelect(),
  //               onCheckedChange: row.getToggleSelectedHandler(),
  //             }}
  //           />
  //         </div>
  //       );
  //     }
  //     return <></>;
  //   },
  // },
  {
    accessorKey: 'display_name',
    enableSorting: true,
    size: 200,
    minSize: 180,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Template Name" className="pl-2 text-xs text-primary" />
    ),
    cell: ({ row }) => (
      <div className="flex gap-2 px-2">
        <CellContentIcon row={row} />
        <CellContentName row={row} />
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    minSize: 20,
    maxSize: 20,
    size: 20,
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tags" className="text-xs text-primary" />,
    cell: (props) => <CellContentTags {...props} />,
  },
  ...commonDataColumns,
];
